import { combineReducers } from 'redux';

import accountReducer from './account/AccountReducer';
import transferReducer from './transfer/TransferReducer';
import assetReducer from './asset/AssetReducer';

const rootReducer = combineReducers({
    account: accountReducer,
    transfer: transferReducer,
    asset: assetReducer,
});

export default rootReducer;
