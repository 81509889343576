import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import split from 'lodash/split';
import find from 'lodash/find';
import { useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../CreateAccountWizard/updateAction';
import { getPlaidCreateClient } from '../../reducers/account/AccountActions';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import BankAccounts from '../BankAccounts/BankAccounts';
import { getQueryVariable } from '../../utils/util';

import './bankAccountSelection.css';
import { useSensitiveData } from '../../contexts/sensitiveDataContext';

const nextStep = history =>
  history.push(`/create-account/step-3${get(history, 'location.search')}`);

const nextStepFromSummary = history =>
  history.push(`/create-account/step-4${get(history, 'location.search')}`);

const isPendingOrSubmitted = option => {
  switch (option) {
    case 'submitted':
      return true;
    case 'pending':
      return true;
    default:
      return false;
  }
};

const BankAccountsSelection = props => {
  const history = useHistory();
  const { sensitiveData } = useSensitiveData();
  const ssn = get(sensitiveData, 'SSN');
  const dob = get(sensitiveData, 'DOB');
  const { account, location } = props;
  const { state, action } = useStateMachine(updateAction);
  const bankAccounts = get(account, 'bankAccounts.bankAccounts.accounts');
  let hasVerifiedAccount;
  if (bankAccounts) {
    bankAccounts.forEach(account => {
      if (!account.pendingVerification) {
        hasVerifiedAccount = true;
      } else {
      }
    });
  }
  const lastSelected = find(
    bankAccounts,
    ['id', get(state, 'data.bankAccount.id')],
    undefined
  );
  const { register, watch, setValue, control } = useForm({
    defaultValues: { selection: get(state, 'data.bankAccount.id') },
  });
  const [loadingService, onSetLoadingService] = useState(false);
  const [cantEdit, onCantEdit] = useState(false);

  const isEmptyBankAccounts = size(bankAccounts) === 0;
  const isFetchingCreateClient = get(
    account,
    'createClient.isFetchingCreateClient'
  );
  const nextStepAfterPlaid = history => {
    const path = window.location.search.split('?') || [];
    if (path.length === 3) {
      const path = get(split(window.location.search, '?'), 1);
      const transferId = getQueryVariable(path, 'go_to_transfer') || '';
      history.push(
        `/create-account/step-4?go_to_transfer=${transferId}?disbursement=payment`
      );
    } else {
      return get(location, 'state.fromSummary')
        ? nextStepFromSummary(history)
        : nextStep(history);
    }
  };
  const isFetchingDeleteBankAccount = get(
    account,
    'deleteBankAccount.isFetchingDeleteBankAccount'
  );

  useEffect(
    () => {
      const disbursementPath = window.location.search.split('?')[2];
      const disbursement = getQueryVariable(disbursementPath, 'disbursement');
      if ((!ssn || !dob) && !disbursement) {
        const client = sessionStorage.getItem('client');
        history.push('/create-account/step-2', { client });
      }
      const path = get(split(window.location.search, '?'), 1);

      const transferId = getQueryVariable(path, 'go_to_transfer') || '';
      if (transferId) {
        const status = get(props.transfer, 'status');
        if (isPendingOrSubmitted(status)) {
          onCantEdit(true);
        }
      }
    },
    /* eslint-disable */ [props.history, props.transfer]
  );

  if (cantEdit) {
    return (
      <Redirect
        to={{
          pathname: '/transfer-history',
          search: '',
          state: { editError: true },
        }}
      />
    );
  }

  return (<>
   
   <BankAccounts
      action={action}
      state={state}
      register={register}
      watch={watch}
      setValue={setValue}
      control={control}
      withDoubleBorder
      withLogButton
      isSelectAccount
      widest
      showPlaidButton
      loadingService={loadingService}
      onSetLoadingService={onSetLoadingService}
      {...props}
    >
      <PaymintsButton
        noShadow
        className="bank-accounts__button"
        type={BUTTON_TYPE.INVERSE_BLUE}
        onClick={() => nextStepAfterPlaid(props.history)}
        disabled={
          isEmptyBankAccounts ||
          isFetchingCreateClient ||
          isNil(get(state, 'data.bankAccount.id')) ||
          isFetchingDeleteBankAccount ||
          loadingService ||
          !lastSelected ||
          !hasVerifiedAccount
        }
      >
        {'Continue'}
      </PaymintsButton>
      
    </BankAccounts>
  </>
    
  );
};

export default withRouter(
  connect(
    state => ({
      account: state.account,
    }),
    {
      onGetPlaidCreateClient: getPlaidCreateClient,
    }
  )(BankAccountsSelection)
);
