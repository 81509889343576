import React from "react";
import classNames from "classnames";
import {
  PaymintsButton,
  BUTTON_TYPE,
} from "paymints-address-autocomplete-field";
import { withRouter } from "react-router-dom";
import { getClientStore } from "../../utils/util";
import "./boxContainer.css";
import split from "lodash/split";
import get from "lodash/get";
import {
  useAllTenants,
  useAllBrandingsFromTenant,
  useDefaultBrandingFromTenant,
} from "../../utils/service/service_tenant_api";
import { getQueryVariable } from "../../utils/util";

const createLogButton = (user, brandLogo, history, showLogin) => (
  <div className="paymints__box-container__logo-login-flexbox">
    <Logo brandLogo={brandLogo} />
    <div className="paymints__box-container__log-buttons">
      {!user && !showLogin && (
        <PaymintsButton
          className="box-container__button-log"
          onClick={() => history.push("/login")}
          noShadow
          type={BUTTON_TYPE.INVERSE_BLUE}
        >
          {"Login"}
        </PaymintsButton>
      )}
    </div>
  </div>
);
const Logo = (props) => {
  const mobile = window.screen.width < 1200;
  return (
    <div className="paymints__box-container__logo-contaner">
      <img
        src={props.brandLogo}
        className="paymints__box-container__brand_logo"
        alt=""
        hidden={mobile}
      />
      <img
        src={props.brandLogo}
        className="paymints__box-container__brand_logo"
        alt=""
        hidden={!mobile}
      />
    </div>
  );
};

const BoxContainer = ({
  children,
  className = "",
  color = "default",
  withBorder = true,
  withDoubleBorder = false,
  withLogButton = false,
  widest = false,
  internalBox = false,
  history,
  showLogin = false,
}) => {
  const { user } = getClientStore();
  const tenantIdentifier = split(window.location.hostname, ".")[0];
  const { tenant } = useAllTenants(tenantIdentifier);
  const { branding } = useAllBrandingsFromTenant(get(tenant, "id", ""), true);
  const { defaultBranding } = useDefaultBrandingFromTenant();
  const brandLogo = branding
    ? get(branding, "logoURL")
    : get(defaultBranding, "logoURL");
  const path = window.location.search.split("?")[2];
  const selectedTab = getQueryVariable(path, "disbursement") || null;
  const classNameBox = classNames(
    `paymints__box-container paymints__box-container${
      internalBox ? "__internal" : ""
    }--${color} ${className}`,
    {
      "with-border": withBorder,
    }
  );
  const classNameContainer = classNames(
    `paymints__box-container__container paymints__box-container__box-shadow--default`,
    {
      widest: widest,
    }
  );

  if (withDoubleBorder) {
    return (
      <>
        <div className={classNameContainer}>
          <div className="paymints__box-container__steps" hidden={!selectedTab}>
            <div className="tabs active">Select a Bank Account</div>
            <div
              className={`tabs ${
                selectedTab === "payment" || selectedTab === "success"
                  ? "active"
                  : ""
              }`}
            >
              Accept Your Payment
            </div>
            <div
              className={`tabs ${selectedTab === "success" ? "active" : ""}`}
            >
              Success
            </div>
          </div>
          <div className="paymints__box-container__box-shadow">
            {withLogButton &&
              createLogButton(user, brandLogo, history, showLogin)}
            <div className={classNameBox}>{children}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={classNameBox}>
      <div>
        {withLogButton && createLogButton(user, brandLogo, history, showLogin)}
        {children}
      </div>
    </div>
  );
};

export default withRouter(BoxContainer);
