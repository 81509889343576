import React from 'react';
import get from 'lodash/get';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
    allowDecimal: true,
});

export default function AmountInput ({...props}) {
    const handleChange = (e) => props.onChange(`${get(e, 'target.value')}`.replace(/[$,_ ]/g, ''));

    return (
        <MaskedInput
            mask={numberMask}
            {...props}
            onChange={handleChange}
        />
    );
};
