import React, { useState } from "react";
import includes from "lodash/includes";
import { withRouter, Link } from "react-router-dom";
import userLogo from "../../styles/assets/user.svg";
import bankLogo from "../../styles/assets/bank.svg";
import amountLogo from "../../styles/assets/amount.svg";
import userLogoBlue from "../../styles/assets/user-blue.svg";
import bankLogoBlue from "../../styles/assets/bank-blue.svg";
import amountLogoBlue from "../../styles/assets/amount-blue.svg";
import contactSupport from "../../styles/assets/support.svg";
import LogOut from "../../styles/assets/logout.svg";
import { useAuth0 } from "../../react-auth0-spa.js";
import { clearStore } from "../../utils/util";
import messageBlue from "../../styles/assets/message-blue.svg";
import messageIcon from "../../styles/assets/message.svg";
import NewTransferInactive from "../../styles/assets/New-transfer-inactive.svg";
import NewTransferActive from "../../styles/assets/New-transfer-active.svg";

import "./menuNavBar.css";

import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "rgba(0, 0, 0, 0.7)"
    },
    color: "rgba(0, 0, 0, 0.7)"
  },
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    color: "white",
    marginLeft: "30px",
    fontSize: "1em",
  }
}));

const account = "/account";
const bankAccounts = "/bank-accounts";
const transfers = "/transfer-history";
const message = "/message-center";
const newTransferStart = ["/create-account/step-select-account", "/create-account/step-select-account", "/create-account/step-3"]  ;

const MenuNavBar = (props) => {
  const pathname = window.location.pathname;
  const isMobile = window.screen.width < 767;
  const [isOpen, openMenu] = useState(isMobile);
  const isSelectedAccount = includes(account, pathname);
  const isSelectedBank = includes(bankAccounts, pathname);
  const isSelectedTransfer = includes(transfers, pathname);
  const isMessageCenter = includes(message, pathname);
  const isNewTransfer = includes(newTransferStart, pathname);
  
  const hideSideNav = (mobile) => (mobile ? openMenu(false) : "");
  const { logout } = useAuth0();
  const userLogout = () => {
    logout();
    clearStore();
  };
  const classes = useStyles();

  if (props.hide) {
    return props.children;
  }

  return (
    <div id="outer-container">
      <main
        id="page-wrap"
        className={`menu-nav-bar__page-wrap ${isOpen ? "" : "menu-toggle-open"
          }`}
      >
        <div className="menu-nav-bar__grey-bar">
          <div className="menu-nav-bar__content">
            <div className="menu-toggle-btn-wrapper">
              <button
                className="menu-toggle-btn"
                onClick={() => openMenu(!isOpen)}
              >
                <i className="fa fa-angle-double-right"></i>
              </button>
            </div>
            <div className="menu-nav-bar-list">
              <div
                className={`menu-nav-items ${!isOpen ? "gray_icon" : ""}`}
                onClick={() => hideSideNav(props.mobile)}
              >
                <Link
                  className={`menu-link-item ${isSelectedAccount ? "selected" : ""
                    }`}
                  to="/account"
                >
                  <Tooltip
                    arrow
                    placement="right"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    title={isOpen ? "My Account" : ""}
                  >
                    <img
                      src={isSelectedAccount ? userLogoBlue : userLogo}
                      alt=""
                    />
                  </Tooltip>
                  <span className={isSelectedAccount ? "selected" : ""}>
                    My Account
                  </span>
                </Link>
              </div>


              <div
                className="menu-nav-items"
                onClick={() => hideSideNav(props.mobile)}
              >
                <Link
                  className={`menu-link-item ${isNewTransfer ? "selected" : ""
                    }`}
                  to="/create-account/step-select-account"
                >
                  <Tooltip
                    title={"New Transfer"}
                    arrow
                    placement="right"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  >
                    <img src={isNewTransfer ? NewTransferActive : NewTransferInactive} alt="" />
                  </Tooltip>
                  <span className={isNewTransfer ? "selected" : ""}>
                    New Transfer
                  </span>
                </Link>
              </div>


              <div
                className="menu-nav-items"
                onClick={() => hideSideNav(props.mobile)}
              >
                <Link
                  className={`menu-link-item ${isSelectedBank ? "selected" : ""
                    }`}
                  to="/bank-accounts"
                >
                  <Tooltip
                    title={isOpen ? "Bank Account" : ""}
                    arrow
                    placement="right"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  >
                    <img src={isSelectedBank ? bankLogoBlue : bankLogo} alt="" />
                  </Tooltip>
                  <span className={isSelectedBank ? "selected" : ""}>
                    Bank Account
                  </span>
                </Link>
              </div>
              <div
                className="menu-nav-items"
                onClick={() => hideSideNav(props.mobile)}
              >
                <Link
                  className={`menu-link-item ${isSelectedTransfer ? "selected" : ""
                    }`}
                  to="/transfer-history"
                >
                  <Tooltip
                    title={isOpen ? "Transfer History" : ""}
                    arrow
                    placement="right"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  >
                    <img
                      src={isSelectedTransfer ? amountLogoBlue : amountLogo}
                      alt=""
                    />
                  </Tooltip>
                  <span className={isSelectedTransfer ? "selected" : ""}>
                    Transfer History
                  </span>
                </Link>
              </div>
              <div
                className="menu-nav-items"
                onClick={() => hideSideNav(props.mobile)}
              >
                <Link
                  className={`menu-link-item ${isMessageCenter ? "selected" : ""
                    }`}
                  to="/message-center"
                >
                  <Tooltip
                    title={isOpen ? "Message Center" : ""}
                    arrow
                    placement="right"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  >
                    <img
                      src={isMessageCenter ? messageBlue : messageIcon}
                      alt=""
                    />
                  </Tooltip>
                  <span className={isMessageCenter ? "selected" : ""}>
                    Message Center
                  </span>
                </Link>
              </div>
              <div className="menu-nav-items" onClick={() => userLogout()}>
                <div className="menu-link-item">
                  <Tooltip
                    title={isOpen ? "Log Out" : ""}
                    arrow
                    placement="right"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  >
                    <img src={LogOut} alt="" />
                  </Tooltip>
                  <span>Log Out</span>
                </div>
              </div>
              <div className="menu-nav-bar__bar-line"></div>
              <div className="menu-nav-items" onClick={() => openMenu(!isOpen)}>
                <div className="menu-link-item">
                  <Tooltip
                    title={isOpen ? "Contact Support" : ""}
                    arrow
                    placement="right"
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  >
                    <img src={contactSupport} alt="" />
                  </Tooltip>
                  <span>Contact support</span>
                </div>
                <div className="menu-link-item no_padding">
                  <span className="support_links">
                    <a href="mailto:support@paymints.io">support@paymints.io</a>
                    <br />
                    <a href="tel:+1.888.921.1090">1-888-921-1090</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.children}
      </main>
    </div>
  );
};

export default withRouter(MenuNavBar);
