import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import "./errorMessageInput.css";

class ErrorMessageInput extends Component {
    render() {
        const { className, children } = this.props;
        const classNameContainer = classNames("error-message-input__container", className, {})
        
        return (
            <div className={classNameContainer}>
                {children}
            </div>
        );
    }
}

ErrorMessageInput.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

ErrorMessageInput.defaultProps = {
    className: '',
};

export default ErrorMessageInput;