import React from 'react';
import { formatAddressStreetApartment, formatAddressCityStateZipCode } from '../../utils/util';
import "./formatAddress.css";

const FormatAddress = ({streetNumberName, apartmentSuiteNumber, city, state, zipcode}) => {
    return (
        <>
            <div>
                {formatAddressStreetApartment(streetNumberName, apartmentSuiteNumber)}
            </div>
            <div>
                {formatAddressCityStateZipCode(city, state, zipcode)}
            </div>
        </>
    );
};

export default FormatAddress;