import React from "react";
import { Modal, Button } from "react-bootstrap";
import get from "lodash/get";
import "./disconnectModal.scss";

const DisconnectModal = ({
  showDisconnectModal,
  setShowDisconnectModal,
  onReloadBankAccounts,
  id,
  onDisconnectBankAccount,
  onSetError,
}) => {
  return (
    <>
      <Modal
        show={showDisconnectModal}
        backdrop="static"
        centered={true}
        keyboard={false}
        style={{ opacity: 1 }}
        onHide={() => setShowDisconnectModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Disconnect Bank Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to disconnect this bank account?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowDisconnectModal(false);
            }}
          >
            No
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onDisconnectBankAccount({ bankAccountId: id }).then(
                (response) => {
                  if (!get(response, "error")) {
                    onReloadBankAccounts(true);
                    onSetError({ active: false, messageError: "" });
                  } else {
                    onSetError({
                      active: true,
                      messageError: get(response, "message"),
                    });
                  }
                }
              );
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DisconnectModal;
