import React, { useState } from 'react';
import BoxContainer from '../BoxContainer/BoxContainer';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import { withRouter, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import get from 'lodash/get';
import * as APIUtil from '../../utils/service/service_api';
import { setClientStore } from '../../utils/util';

const CreateAccountVerificationStep = props => {
  const history = useHistory();
  const tenantId = get(props, 'tenant.id');
  const { client } = props.history.location.state;
  const { handleSubmit, errors, register } = useForm();
  const [isError, setError] = useState(false);

  const onSubmit = verificationCode => {
    setError(false);
    const { code } = verificationCode;
    APIUtil.validateSMSCode(tenantId, client.id, code).then(res => {
      setError(res['error'] === true);
      if (!res['error']) {
        setClientStore(client);
        if (client.email) {
          history.push('/transfer-history');
        } else {
          history.push('/create-account/step-2');
        }
      }
    });
  };
  const reSendCode = () => {
    getCode(tenantId, client.id);
  };
  const getCode = (tenantId, clientid) => {
    APIUtil.getSMSCode(tenantId, clientid).then(res => {
      return res;
    });
  };

  return (
    <BoxContainer widest withLogButton withDoubleBorder showLogin={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="create-account__line-container">
          <div className="create-account__title">
            Now all you need to do is check your phone.
          </div>
        </div>
        <div className="create-account__subtitle-information">
          A text message with a 6-digit verification code has been sent to your
          mobile phone.
        </div>
        <BoxContainer withBorder internalBox>
          <div className="form-group">
            <label>Enter Code</label>
            <div className="form-group create-account__name-middle">
              <input
                type="number"
                autoComplete="no"
                name="code"
                placeholder=" "
                onKeyUp={() => setError(false)}
                className="create-account__input form-control"
                ref={register({
                  required: true,
                  validate: value => value.length > 5,
                })}
              />
              {errors.code && errors.code.type === 'required' && (
                <ErrorMessageInput>This is required</ErrorMessageInput>
              )}
              {errors.code && errors.code.type === 'validate' && (
                <ErrorMessageInput>Code must be 6 digits</ErrorMessageInput>
              )}
              {isError && <ErrorMessageInput>Invalid Code</ErrorMessageInput>}
            </div>
          </div>
        </BoxContainer>
        <div className="create-account__footer-buttons create-account__mobile-transfer-footer-buttons">
          <PaymintsButton
            className="mr-4 create-account__transfer-action-button mobile__transfer-action-button"
            noShadow
            type={BUTTON_TYPE.INVERSE_BLUE}
            htmlType="submit"
          >
            Verify
          </PaymintsButton>
          <PaymintsButton
            className="mr-4 create-account__transfer-action-button mobile__transfer-action-button"
            noShadow
            type={BUTTON_TYPE.LINK}
            onClick={reSendCode}
          >
            Resend Code
          </PaymintsButton>
        </div>
      </form>
    </BoxContainer>
  );
};
export default withRouter(CreateAccountVerificationStep);
