export default function storeService() {
  const setData = (key, user) => {
    return sessionStorage.setItem(key, JSON.stringify(user))
  }
  const getUser = () => {
    const user = sessionStorage.getItem('user')
    return user ? { user: JSON.parse(user) } : { user: undefined }
  }
  const getAuthToken = () => {
    const authToken = sessionStorage.getItem('authToken')
    return authToken
  }
  const getbykey = key => {
    const data = sessionStorage.getItem(key)
    return data ? JSON.parse(data) : undefined
  }
  const clearStore = () => {
    return sessionStorage.clear()
  }
  const removeStoreByKey = key => {
    return sessionStorage.removeItem(key)
  }
  return {
    setData,
    getUser,
    getAuthToken,
    getbykey,
    clearStore,
    removeStoreByKey,
  }
}
