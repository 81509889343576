import * as APIUtil from "../../utils/service/service_api";
import * as actionType from "./AssetActionTypes";
import get from "lodash/get";

export const uploadDocumentFetch = () => ({
    type: actionType.UPLOAD_DOCUMENT_FETCH
});

export const uploadDocumentSuccess = response => ({
    type: actionType.UPLOAD_DOCUMENT_SUCCESS,
    payload: { response }
});

export const uploadDocumentError = response => ({
    type: actionType.UPLOAD_DOCUMENT_ERROR,
    payload: { response }
});


export const uploadDocument = (formData) => dispatch => {
    dispatch(uploadDocumentFetch())
    return APIUtil.uploadDocument(formData)
        .then(response => {
            dispatch(uploadDocumentSuccess(response))
            return response;
        })
        .catch(error => {
            const errorElement = get(error, 'response.data');
            const message = get(errorElement, 'error.body.message') || get(errorElement, 'message') || get(errorElement, 'error_message') || get(errorElement, 'error') || "Something went wrong... please try again in a few minutes";
            dispatch(uploadDocumentError(errorElement))
            return { error: true, message: message };
        })
};
