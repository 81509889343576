import React from 'react'
import { useDropzone } from 'react-dropzone'
import './fileUpload.css'

export default function FileUpload({ onDropFiles, selected, invalidFormat }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropFiles })

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {selected ? (
          <div className="paymints__file-upload__files">
            <p>{`Selected file: ${selected}, click if you want to change it`}</p>
          </div>
        ) :
          isDragActive ? (
            <div className="paymints__file-upload__files">
              <p>Drop the files here ...</p>
            </div>
          ) : (
              <div className="paymints__file-upload__files">
                <p>Drag and drop some files here, or click to select files</p>
              </div>
            )}
      </div>
      <div className="text text-danger" hidden={!invalidFormat}>File upload must be in jpg or png format</div>
    </>
  )
}