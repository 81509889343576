/*
 * src/components/TransferHistory/TransferHistoryListing.jsx
 * Description: This wrapper component determines inbound (disbursement) or outbound (client -> tenant) transfer
 */
import React from 'react';

import InboundTransfer from './InboundTransfer';
import OutboundTransfer from './OutboundTransfer';

const TransferHistoryListing = ({ transfers, clientDocuments, display }) => {
  if (display) {
    return (
      <>
        {transfers.map(t => {
          return t?.disbursement ? (
            <InboundTransfer
              key={t.id}
              transfer={t}
              clientDocuments={clientDocuments}
            />
          ) : (
            <OutboundTransfer
              key={t.id}
              transfer={t}
              clientDocuments={clientDocuments}
            />
          );
        })}
      </>
    );
  } else {
    return null;
  }
};

export default TransferHistoryListing;
