import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import split from 'lodash/split';
import { withRouter, Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { StateMachineProvider, createStore } from 'little-state-machine';
import CreateAccountFirstStep from '../CreatePartnerAccountFirstStep/CreateAccountFirstStep';
import CreateAccountVerificationStep from '../CreateAccountVerificationStep/CreateAccountVerificationStep';
import CreateAccountSecondStep from '../CreateAccountSecondStep/CreateAccountSecondStep';
import TransferDetailsPartner from '../TransferDetailsPartner/TransferDetailsPartner';
import TransferSummary from '../TransferSummary/TransferSummary';
import TransferSuccess from '../TransferSuccess/TransferSuccess';
import AccountError from '../AccountError/AccountError';
import { useClientByPhone } from '../../utils/service/service_user_api';
import { useTransfer } from '../../utils/service/service_transfer_api';
import { getQueryVariable } from '../../utils/util';
import BankAccountSelection from '../BankAccountSelection/BankAccountSelection';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { getClientStore, setRealtorStore } from '../../utils/util';
import { getItem, removeItem } from '../../utils/util';
import DocumentVerification from '../DocumentVerification/DocumentVerification';
import { useSensitiveData } from '../../contexts/sensitiveDataContext';
import { getTenantIdentifier } from '../../utils/util';
import useAllTransferFeeConfigs from '../../hooks/useAllTransferFeeConfigs';
import useAllRealtors from '../../hooks/useAllRealtors';


createStore({
  data: {},
});

const CreateAccountWizard = props => {
  const history = useHistory();
  const { user } = getClientStore();
  const path = get(split(window.location.search, '?'), 1);
  //const clientId = getQueryVariable(path, "client_id") || "";

  const landingPageURI = props.history.location.pathname.split('/');
  const partnerURI = landingPageURI.pop() || landingPageURI.pop();

  const { realtors } = useAllRealtors({
    // landingPageURI: landingPageURI[2],
    landingPageURI: partnerURI,
    active: true
  });
  const realtor = realtors?.[0];

  // TODO:  If no realtor, forward to home page -> /create-account
  setRealtorStore(realtor);

  props = {
    ...props,
    realtor: realtor,
    partnerURI: partnerURI
  }
  
  const transferId = getQueryVariable(path, 'go_to_transfer') || '';
  const mobile = user ? user.mobilePhone : null;
  const { client, refetch, refetch: refetchNewClient } = useClientByPhone(
    mobile
  );
  
  const { loadingSearchTransfer, transfer, refetchTransfer } = useTransfer(
    transferId
  );

  const { value } = getItem('login_user');
  const { sensitiveData } = useSensitiveData();

  // here's where we retrieve the transfer fee configuration for the
  // tenant in question
  const { data: transferFeeConfigData } = useAllTransferFeeConfigs(
    getTenantIdentifier(window.location.hostname)
  );

  useEffect(
    () => {
      if (user) {

        // if (get(sensitiveData, 'SSN') && get(sensitiveData, 'DOB')) {
        //   console.log('value', value);
        //   if (!value) {
        //     props.history.push(
        //       `/create-account/step-select-account${get(
        //         props.history,
        //         'location.search'
        //       )}`
        //     );
        //   } else {
        //     removeItem('login_user');
        //     props.history.push(
        //       `/create-account/step-select-account${get(
        //         props.history,
        //         'location.search'
        //       )}`
        //     );
        //   }
        // }
      }
    },
    /* eslint-disable */ [client, realtor, sensitiveData, props.history, refetch]
  );

  if (loadingSearchTransfer) {
    return <SpinnerLoader />;
  }

  if (transferId && !transfer) {
    refetchTransfer({
      id: transferId,
    });
  }

  return (
    <StateMachineProvider>
      <Switch>
        <Route
          path="/partner"
          render={() => (
            <CreateAccountFirstStep
              user={user}
              client={user ? client : Object.assign({})}
              beforeContinue={refetch}
              refetchNewClient={refetchNewClient}
              realtor={realtor}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/account-verification"
          render={() => (                         
            <CreateAccountVerificationStep
              user={user}
              client={user ? client : Object.assign({})}
              beforeContinue={refetch}
              refetchNewClient={refetchNewClient}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/step-2"
          render={() => (
            <CreateAccountSecondStep
              user={user}
              client={client}
              refetch={refetch}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/step-select-account"
          render={() => (
            <BankAccountSelection
              user={user}
              client={client}
              transfer={transfer}
              refetchTransfer={refetchTransfer}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/step-3"
          render={() => (
            <TransferDetailsPartner
              user={user}
              client={client}
              transfer={transferId ? transfer : null}
              refetchTransfer={refetchTransfer}
              refetch={refetch}
              transferFeeConfigData={transferFeeConfigData}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/step-4"
          render={() => (
            <TransferSummary
              user={user}
              transfer={transfer}
              client={client}
              refetchTransfer={refetchTransfer}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/success"
          render={() => (
            <TransferSuccess
              user={user}
              client={client}
              transfer={transfer}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/error"
          render={() => (
            <AccountError
              user={user}
              client={client}
              transfer={transfer}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/create-account/verification"
          render={() => (
            <DocumentVerification
              user={user}
              client={client}
              transfer={transfer}
              {...props}
            />
          )}
        />
      </Switch>
    </StateMachineProvider>
  );
};

export default withRouter(CreateAccountWizard);
