import React, { useEffect } from 'react';
import BoxContainer from '../BoxContainer/BoxContainer';
import { get, split } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useUpdateClient } from '../../utils/service/service_user_api';
import { withRouter } from 'react-router-dom';
import { useClientByPhone } from '../../utils/service/service_user_api';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import { Select } from '../Select/Select';
import { states } from '../../utils/constants';
import SsnToggleVisibilityField from '../SsnInput/SsnToggleVisibilityInput';
import { validSSN, validAge } from '../../utils/validations';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import PhoneField from '../PhoneField/PhoneField';
import { getClientStore } from '../../utils/util';
import { useSensitiveData } from '../../contexts/sensitiveDataContext';
import { encrypt, decrypt } from '../../utils/encryption';

import './account.css';
import { saveSensitiveData } from '../../utils/service/service_api';
import { useAllTenants } from '../../utils/service/service_tenant_api';

const Title = () => (
  <div className="create-account__line-container">
    <div className="create-account__title">Your Account</div>
  </div>
);

const FieldsPersonalInformation = ({
  register,
  watch,
  setValue,
  control,
  errors,
  client,
}) => {
  const nav = document.querySelector('.menu-nav-bar__grey-bar');
  if (!nav) window.location.reload();
  return (
    <BoxContainer internalBox withBorder>
      <div className="form-group">
        <label>First Name</label>
        <input
          type="text"
          placeholder=" "
          id="firstName"
          name="firstName"
          className="create-account__input form-control"
          ref={register({ required: true, maxLength: 30 })}
        />
        {errors.firstName && errors.firstName.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
        {errors.firstName && errors.firstName.type === 'maxLength' && (
          <ErrorMessageInput>Max length exceeded</ErrorMessageInput>
        )}
      </div>
      <div className="form-group">
        <label>Middle Name or Initial</label>
        <input
          type="text"
          id="middleName"
          placeholder=" "
          name="middleName"
          className="create-account__input form-control account__control_width"
          ref={register}
        />
      </div>
      <div className="form-group">
        <label>Last Name</label>
        <input
          type="text"
          id="lastName"
          placeholder=" "
          name="lastName"
          className="create-account__input form-control"
          ref={register({ required: true, maxLength: 30 })}
        />
        {errors.lastName && errors.lastName.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
        {errors.lastName && errors.lastName.type === 'maxLength' && (
          <ErrorMessageInput>Max length exceeded</ErrorMessageInput>
        )}
      </div>
      <div className="form-group">
        <label>Last 4 Digits of Social Security Number</label>
        <Controller
          as={SsnToggleVisibilityField}
          control={control}
          name="ssn"
          placeholder="Social Security Number"
          className="create-account__input form-control emd-landing-page__form-field--password account__control_width"
          rules={{ required: false, validate: validSSN }}
          disabled={client.ssn}
        />
        {errors.ssn && errors.ssn.type === 'validate' && (
          <ErrorMessageInput>
            Must be a valid social security number
          </ErrorMessageInput>
        )}
      </div>
      <div className="form-group">
        <label>Date of Birth</label>
        <input
          type="date"
          id="dateOfBirth"
          name="dateOfBirth"
          className="create-account__input form-control account__control_width"
          placeholder="yyyy-mm-dd"
          ref={register({ required: false, validate: validAge })}
        />
        {errors.dateOfBirth && errors.dateOfBirth.type === 'validate' && (
          <ErrorMessageInput>Must be at least 18 years old</ErrorMessageInput>
        )}
      </div>
      <div className="form-group">
        <label>Email Address</label>
        <input
          type="email"
          id="email"
          name="email"
          className="create-account__input form-control padding-bottom-10"
          placeholder="Email Address"
          ref={register({ maxLength: 50 })}
        />
        {errors.email && errors.email.type === 'maxLength' && (
          <span>Max length exceeded</span>
        )}
      </div>
      <div className="form-group">
        <label>Address Associated with Bank Account</label>
        <input
          type="text"
          name="address1"
          placeholder="Address 1"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register({ required: false })}
        />
        <input
          type="text"
          name="address2"
          placeholder="Address 2"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register}
        />
        <input
          type="text"
          name="city"
          placeholder="City"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register({ required: false })}
        />
        <Controller
          as={Select}
          control={control}
          name="state"
          rules={{ required: false }}
          options={states}
          placeholderText="State"
          className="create-account__input form-control create-account__address-info"
        />
        <input
          type="text"
          name="zipcode"
          placeholder="Zip code"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register({ required: false })}
        />
        <br />
      </div>
    </BoxContainer>
  );
};

const PersonalInformation = ({
  register,
  watch,
  setValue,
  control,
  errors,
  client,
}) => (
  <div className="create-account__information-container">
    <div className="create-account__title-information">
      Personal Information
    </div>
    <FieldsPersonalInformation
      register={register}
      errors={errors}
      control={control}
      setValue={setValue}
      watch={watch}
      client={client}
    />
  </div>
);

const FieldsContactInformation = ({ register, errors, control }) => {
  return (
    <BoxContainer internalBox withBorder>
      <div className="form-group">
        <label>Mobile Number</label>
        <Controller
          as={PhoneField}
          control={control}
          name="mobilePhone"
          placeholder=" "
          className="create-account__input form-control"
          disabled
        />
        {errors.mobilePhone && errors.mobilePhone.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
      </div>
    </BoxContainer>
  );
};

const ContactInformation = ({ register, errors, control }) => (
  <div className="create-account__information-container">
    <div className="create-account__title-information">Contact Information</div>
    <FieldsContactInformation
      register={register}
      errors={errors}
      control={control}
    />
  </div>
);

const Account = props => {
  const { user } = getClientStore();
  const { loadingSearch, client, refetch } = useClientByPhone(user.mobilePhone);
  const clientID = get(client, 'id');
  const { loading, updateClient } = useUpdateClient();
  const tenantIdentifier = split(window.location.hostname, '.')[0];
  const { loading: loadingTenant, tenant } = useAllTenants(tenantIdentifier);
  const tenantID = get(tenant, 'id');
  const { register, handleSubmit, watch, errors, setValue, control } = useForm({
    defaultValues: client,
  });
  const { sensitiveData, refetch: refetchSensitiveData } = useSensitiveData();

  const { SSN, DOB } = sensitiveData || {};

  useEffect(() => {
    if (client && sensitiveData) {
      setValue([
        { firstName: client.firstName },
        { middleName: client.middleName },
        { lastName: client.lastName },
        { address1: client.address1 },
        { address2: client.address2 },
        { state: client.state },
        { city: client.city },
        { zipcode: client.zipcode },
        { mobilePhone: validateNumber(client.mobilePhone) },
        { email: client.email },
        { ssn: ssnCutter(decrypt(SSN)) },
        { dateOfBirth: decrypt(DOB) },
      ]);
    }
  }, [client, SSN, DOB]);

  

  const ssnCutter = (ssn) => {
    if (ssn.length > 4) {
      return ssn.slice(ssn?.length - 4)
    } else return ssn
  };

  const validateNumber = phone => {
    return phone ? phone.slice(2, 12) : phone;
  };

  const onSubmit = async newUser => {
    delete newUser.mobilePhone;

    const sensitiveData = {
      SSN: encrypt(newUser.ssn),
      DOB: encrypt(newUser.dateOfBirth),
    };
    //removing sensititve data from query
    const { ssn, dateOfBirth, ...rest } = newUser;

    try {
      await saveSensitiveData(clientID, tenantID, sensitiveData);

      await updateClient({
        variables: {
          id: client.id,
          client: rest,
        },
      });

      await refetch();
      await refetchSensitiveData();
    } catch (err) {
      console.log(err);
    }
  };

  if (loading || loadingSearch || loadingTenant) {
    return <SpinnerLoader />;
  }

  return (
    <BoxContainer withDoubleBorder withLogButton>
      <Title />
      <form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          watch={watch}
          client={client}
        />
        <ContactInformation
          register={register}
          errors={errors}
          control={control}
        />
        <div className="account__footer-button">
          <PaymintsButton
            htmlType="submit"
            className="create-account__button"
            noShadow
            type={BUTTON_TYPE.INVERSE_BLUE}
          >
            <div className="create-account__button-text">Save</div>
          </PaymintsButton>
        </div>
      </form>
    </BoxContainer>
  );
};

export default withRouter(Account);
