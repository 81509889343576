import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const GET_TRANSACTIONS_SORTED_QUERY = gql`
  query allTransfersSorted($where: TransferWhereInput!) {
    allTransfers(where: $where, sortBy: createdAt_DESC) {
      id
      firstName
      middleName
      lastName
      otherIndividuals
      email
      mobilePhone
      propertyAddress1
      propertyAddress2
      propertyCity
      propertyState
      propertyZipcode
      amount
      fee
      purpose
      expectedClosingDate
      sellerName
      notes
      emailMessage
      status
      disbursement
      active
      createdAt
      tenant {
        id
        name
        address1
        address2
        city
        state
        zipcode
      }
      client {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        zipcode
        propertyAddress1
        propertyAddress2
        propertyCity
        propertyState
        propertyZipcode
      }
      transferType {
        id
        name
        isDebit
        isCredit
      }
      sourceBankAccount {
        id
        bankName
        description
        accountNumber
        accountLastFourDigits
        bankDisplayName
        active
        client {
          id
        }
        institutionCode
        updatedAt
      }
      destBankAccount {
        id
        bankName
        description
        accountNumber
        accountLastFourDigits
        bankDisplayName
        active
        client {
          id
        }
        institutionCode
        updatedAt
      }
      agent {
        id
        firstName
        lastName
        email
      }
      realtor {
        id
        firstName
        lastName
        email
      }
      updatedAt
    }
  }
`;

// PAYAPP-906: Exclude vendor disbursements from the Client UI
export function useAllTransfersSorted(clientID) {
  const id = isNil(clientID) || isEmpty(clientID) ? null : clientID;
  const { loading, error, data, refetch } = useQuery(
    GET_TRANSACTIONS_SORTED_QUERY,
    {
      variables: {
        where: {
          client: { id },
          status_not_in: ['created', 'rejected', 'awaiting approval'],
          OR: [
            { transferType: { name: 'Client Disbursement' } },
            { transferType: { name: 'Client Transfer' } },
            { transferType_is_null: true },
          ],
        },
      },
    }
  );

  // the filter is a hack because name_not_i excludes BOTH "Vendor Disbursement" and null (no bueno)
  return {
    loadingSearchTransfer: loading,
    error,
    refetchTransfers: refetch,
    transfers: data ? data.allTransfers : undefined,
  };
}
