import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GET_ALL_REALTORS_QUERY = gql`
query AllRealtors($where: RealtorWhereInput) {
  allRealtors(where: $where) {
    id
    email
    mobilePhone
    active
    firstName
    lastName
    landingPageURI
    headshotImageURL
    displayName
    displayDescription
    licenses
    qrCodeURL
    brokerage {
      name
      logoURL
      websiteURL
      officePhone
    }
  }
}
`;

export default function useAllRealtors(where) {
  const { loading, error, refetch, data } = useQuery(GET_ALL_REALTORS_QUERY, {
    variables: { where },
    fetchPolicy: 'no-cache',
  });
  return {
    loading,
    error,
    refetch,
    realtors: data ? data.allRealtors : undefined,
  };
}
