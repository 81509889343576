import React from 'react';
import get from 'lodash/get';
import FormatAddress from '../../FormatAddress/FormatAddress';
import { PaymintsNumberFormat } from 'paymints-address-autocomplete-field';

/**
 * Taking the purpose string and possible adjustment
 * @param {*} purpose
 */
const transformPurpose = purpose => {
  if (!purpose) {
    return '--';
  }
  return purpose;
};

function TransferSummary({ transfer }) {
  return (
    <div className="p-4 rounded bg-white mb-2 border">
      <div className="mb-4">
        <h6 className="create-account__item-title">Purpose</h6>
        <span style={{ textTransform: 'capitalize' }}>
          {transformPurpose(transfer?.purpose)}
        </span>
      </div>

      <div className="mb-4">
        <h6 className="create-account__item-title">Subject Property</h6>
        <FormatAddress
          streetNumberName={get(transfer, 'propertyAddress1')}
          apartmentSuiteNumber={get(transfer, 'propertyAddress2')}
          city={get(transfer, 'propertyCity')}
          state={get(transfer, 'propertyState')}
          zipcode={get(transfer, 'propertyZipcode')}
        />
      </div>

      <div className="mb-4">
        <h6 className="create-account__item-title">Transfer Amount</h6>
        <div className="create-account__item-amount">
          <PaymintsNumberFormat
            number={get(transfer, 'amount')}
            decimals={2}
            format="currency"
          />
        </div>
      </div>
      {transfer?.fee ? (
        <div className="mb-4">
          <h6 className="create-account__item-title">Fee</h6>
          <div className="create-account__item-amount">
            <PaymintsNumberFormat
              number={transfer?.fee || 0}
              decimals={2}
              format="currency"
            />
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="mb-4">
        <h6 className="create-account__item-title">Total</h6>
        <div className="create-account__item-amount">
          <PaymintsNumberFormat
            number={+transfer?.amount + (+transfer?.fee || 0)}
            decimals={2}
            format="currency"
          />
        </div>
      </div>
    </div>
  );
}

export default function MobileTransferSummary({
  transfer,
  bankAccount,
  bankAccountLogo,
  disbursement,
}) {
  return (
    <div className="d-block d-lg-none d-xl-none">
      <TransferSummary
        key={get(transfer, 'id')}
        transfer={transfer}
        bankAccount={bankAccount}
        bankAccountLogo={bankAccountLogo}
        disbursement={disbursement}
      />
    </div>
  );
}
