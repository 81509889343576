import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';
import invoke from 'lodash/invoke';
import isNull from 'lodash/isNull';
import filter from 'lodash/filter';
import join from 'lodash/join';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YY hh:mm A';

export const asyncWrapper = async promise => {
  return promise.then(data => [null, data]).catch(err => [err, null]);
};

/**
 * Takes a transfer object and determines the "From" display
 * dangerouslySetInnerHTML
 * @param {*} transfer
 */
export const formatFromDisplay = (transfer, format = 'html') => {
  let primary = [];
  let secondary = [];

  // FUNDS IN, from = Tenant
  if (transfer?.transferType?.isDebit) {
    primary = transfer?.tenant?.name ?? '';
    secondary = '';
  }
  // FUNDS OUT, to = Client
  else {
    primary = [
      transfer?.client?.firstName ?? '',
      transfer?.client?.lastName ?? '',
    ]
      .filter(f => f)
      .join(' ');

    if (
      transfer?.sourceBankAccount?.bankName ||
      transfer?.sourceBankAccount?.accountLastFourDigits
    ) {
      secondary = [
        '(',
        `${transfer?.sourceBankAccount?.bankName ?? ''} `,
        `X${transfer?.sourceBankAccount?.accountLastFourDigits || '----'}`,
        ')',
      ].join('');
    } else {
      secondary = '';
    }
  }

  return format === 'html'
    ? `${primary}<br>${secondary}`
    : `${primary} ${secondary}`;
};

/**
 * Takes a transfer object and determines the "To" value
 * dangerouslySetInnerHTML
 * @param {*} transfer
 */
export const formatToDisplay = (transfer, format = 'html') => {
  let primary = [];
  let secondary = [];

  // FUNDS IN, to = Client
  if (!transfer?.transferType?.isDebit) {
    primary = [transfer?.tenant?.name ?? ''].filter(f => f).join(' ');
    secondary = '';
  }
  // FUNDS OUT, to = Tenant
  else {
    primary = [
      transfer?.client?.firstName ?? '',
      transfer?.client?.lastName ?? '',
    ]
      .filter(f => f)
      .join(' ');

    if (
      transfer?.destBankAccount?.bankName ||
      transfer?.destBankAccount?.accountLastFourDigits
    ) {
      secondary = [
        '(',
        `${transfer?.destBankAccount?.bankName ?? ''} `,
        `X${transfer?.destBankAccount?.accountLastFourDigits || '----'}`,
        ')',
      ].join('');
    } else {
      secondary = '';
    }
  }

  return format === 'html'
    ? `${primary}<br>${secondary}`
    : `${primary} ${secondary}`;
};

/**
 * The notes property in transfers MIGHT BE stringified JSON and we need to extract the note property from it
 * e.g., [{"createdAt":"12/01/20 11:31 AM","createdBy":"someone@somedomain.com","note":"foo bar baz note"}]
 * @param {*} json
 */
export const extractNotesFromJson = possibleJsonString => {
  try {
    const obj = JSON.parse(possibleJsonString);
    if (obj[0] && obj[0]?.note) {
      return obj[0]?.note;
    }
    return '';
  } catch (e) {
    return possibleJsonString ? possibleJsonString : '';
  }
};

export const getQueryVariable = (query, variable, splitSymbol = '&') => {
  if (!isEmpty(query)) {
    const vars = split(query, splitSymbol);
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
  }
  return null;
};

const getAddressComponentByType = (components = [], type, path = 'long_name') =>
  (components.find(component => component.types.includes(type)) || {})[path] ||
  '';

export function getAddressFromPlaceDetails({
  address_components: addressComponents = {},
  geometry = {},
} = {}) {
  const getComponent = (type, path) =>
    getAddressComponentByType(addressComponents, type, path);

  const streetNumber = getComponent('street_number');
  const streetName = getComponent('route');
  const neighborhood = getComponent('neighborhood');

  return {
    address: `${streetNumber} ${streetName}`.trim(),
    streetNumber,
    streetName,
    neighborhood,
    zipcode: getComponent('postal_code'),
    city:
      getComponent('locality') ||
      getComponent('sublocality') ||
      neighborhood ||
      getComponent('administrative_area_level_3') ||
      getComponent('administrative_area_level_2'),
    state: `${getComponent('administrative_area_level_1', 'short_name')}`,
    country: getComponent('country'),
    lat: invoke(geometry, 'location.lat'),
    lng: invoke(geometry, 'location.lng'),
  };
}

export const filterNotNull = list =>
  filter(list, item => (!isNull(item) ? item !== '' : false));

export const formatAddressStreetApartment = (
  streetNumberName,
  apartmentSuiteNumber,
  withSymbol = true
) => {
  const formatAddress = filterNotNull([streetNumberName, apartmentSuiteNumber]);
  if (withSymbol) {
    return join(formatAddress, ', #');
  }
  return join(formatAddress, ', ');
};

export const formatAddressCityStateZipCode = (city, state, zipcode) => {
  const formatAddress = filterNotNull([city, state]);
  const formatZipCode = !isNull(zipcode) ? ` ${zipcode}` : '';
  return `${join(formatAddress, ', ')}${formatZipCode}`;
};

export const formatDayFromUTC = date => {
  const newDate = split(date, /[-,T]/);
  const day = newDate[2];
  const month = newDate[1];
  const year = newDate[0];
  return `${year}-${month}-${day}`;
};
export const setClientStore = client => {
  return sessionStorage.setItem('client', JSON.stringify(client));
};
export const getClientStore = () => {
  const client = sessionStorage.getItem('client');
  return client ? { user: JSON.parse(client) } : { user: undefined };
};
export const setRealtorStore = realtor => {
  return sessionStorage.setItem('realtor', JSON.stringify(realtor));
};
export const getRealtorStore = () => {
  const realtor = sessionStorage.getItem('realtor');
  return realtor ? { realtor: JSON.parse(realtor) } : { realtor: undefined };
};

export const clearStore = () => {
  return sessionStorage.clear();
};
export const setItem = (key, value) => {
  sessionStorage.setItem(key, value);
};
export const getItem = key => {
  return { value: sessionStorage.getItem(key) };
};
export const removeItem = key => {
  sessionStorage.removeItem(key);
};

export const getTenantIdentifier = hostname => {
  const [identifier] = hostname.split('.');
  return identifier ? identifier : null;
};

export const paymintsCurrencyFormatter = (
  value,
  decimals = 2,
  style = 'currency',
  fallback = '$0.00'
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: style,
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  const numeric = +value;
  if (numeric) {
    if (numeric < 0) {
      return ['(', formatter.format(numeric), ')'].join('');
    }
    return formatter.format(numeric);
  }
  return fallback;
};
