import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import BoxContainer from "../BoxContainer/BoxContainer";
import { useForm, Controller } from "react-hook-form";
import PhoneField from "../PhoneField/PhoneField";
import { setItem } from "../../utils/util";
import ErrorMessageInput from "../ErrorMessajeInput/ErrorMessageInput";
import {
  PaymintsButton,
  BUTTON_TYPE,
} from "paymints-address-autocomplete-field";
import * as APIUtil from "../../utils/service/service_api";
import { useClientByPhone } from "../../utils/service/service_user_api";
import Phone from "../../styles/assets/phone.png";

import get from "lodash/get";

const Login = (props) => {
  const [error, onSetError] = useState({ active: false, messageError: "" });
  const tenantId = get(props, "tenant.id");
  const { errors, control, handleSubmit } = useForm();
  const { refetch: refetchNewClient } = useClientByPhone();

  const onSubmit = (newUser) => {
    const { phone_number } = newUser;
    refetchNewClient({ where: { mobilePhone: "+1" + phone_number } }).then(
      (response) => {
        const client = get(response, "data.allClients")[0];
        if (client) {
          setItem("login_user", "existing_user");
          getCode(tenantId, client.id, client);
        } else {
          onSetError({ active: true, messageError: "" });
        }
      }
    );
  };
  const getCode = (tenantId, clientid, client) => {
    APIUtil.getSMSCode(tenantId, clientid).then(() => {
      props.history.push("/create-account/account-verification", { client });
    });
  };
  return (
    <BoxContainer
      widest
      withLogButton
      withDoubleBorder
      brandLogo
      showLogin={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="create-account__line-container">
          <div className="create-account__title">
            Please enter your mobile number.
          </div>
        </div>
        <div className="create-account__information-container">
          <div className="create-account__subtitle-information">
            We will send a security code to your mobile phone for account
            verification.
          </div>
          <BoxContainer withBorder internalBox>
            <div className="form-group">
              <label>Mobile Phone</label>
              <div className="form-group create-account__name">
                <Controller
                  as={PhoneField}
                  control={control}
                  name="phone_number"
                  onKeyUp={() =>
                    onSetError({ active: false, messageError: "" })
                  }
                  placeholder=" "
                  className="create-account__input form-control"
                  rules={{ required: true }}
                />
                {errors.phone_number &&
                  errors.phone_number.type === "required" && (
                    <ErrorMessageInput>This is required</ErrorMessageInput>
                  )}
              </div>
              <div className="create-account__warning-conatiner">
                <img
                  src={Phone}
                  className="create-account__warning-icon"
                  alt=""
                />
                <div className="create-account__warning-text">
                  Standard text messaging and provider rates may apply.
                </div>
              </div>
            </div>
          </BoxContainer>
          <div
            className="create-account__subtitle-information"
            hidden={!error.active}
            style={{color: "red", fontWeight: "600", fontSize: "20px"}}
          >
            The phone number entered is not registered. <br />Please{" "}
            <span
              className="paymints__link create-account__cursor"
              onClick={() => props.history.push("/create-account")}
            >
              Create Account{" "} 
            </span>
            to register phone number.
          </div>
          <div className="create-account__footer-buttons center">
            <PaymintsButton
              htmlType="submit"
              className="paymints__register-button create-account__button"
              type={BUTTON_TYPE.INVERSE_BLUE}
            >
              Get Security Code
            </PaymintsButton>
            {error.active && (
              <ErrorMessageInput>{error.messageError}</ErrorMessageInput>
            )}
          </div>
        </div>
      </form>
    </BoxContainer>
  );
};

export default withRouter(Login);
