import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

const CREATE_BANK_ACCOUNT_QUERY = gql`
  mutation createBankAccount($bankAccount: BankAccountCreateInput!) {
    createBankAccount(data: $bankAccount) {
      id
    }
  }
`;

export function useCreateBankAccount() {
  const [createBankAccount, { loading, error, data }] = useMutation(
    CREATE_BANK_ACCOUNT_QUERY
  );
  return {
    createBankAccount,
    loading,
    error,
    bank: data ? data.createBankAccount : undefined,
  };
}

const GET_BANK_ACCOUNTS_QUERY = gql`
  query allBankAccounts($where: BankAccountWhereInput!) {
    allBankAccounts(where: $where) {
      id
      bankName
      description
      institutionCode
      accountLastFourDigits
      bankDisplayName
      accountNumber
      active
      updatedAt
      createdAt
    }
  }
`;

export function useAllBankAccountsByClient(clientID) {
  const id = isNil(clientID) || isEmpty(clientID) ? null : clientID;
  const { loading, error, data, refetch } = useQuery(GET_BANK_ACCOUNTS_QUERY, {
    variables: { where: { client: { id }, active: true } },
  });
  return {
    loading,
    error,
    refetch,
    bankAccounts: data ? data.allBankAccounts : undefined,
  };
}

const GET_BANK_ACCOUNT_QUERY = gql`
  query BankAccount($bankAccount: ID!) {
    BankAccount(where: { id: $bankAccount }) {
      id
      bankName
      description
      institutionCode
      accountNumber
      accountLastFourDigits
      bankDisplayName
      active
      updatedAt
      createdAt
    }
  }
`;

export function useBankAccount(id) {
  const { loading, error, data, refetch } = useQuery(GET_BANK_ACCOUNT_QUERY, {
    variables: { bankAccount: id },
  });
  return {
    loading,
    error,
    refetch,
    bankAccount: data ? data.BankAccount : undefined,
  };
}

const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount($id: ID!, $bankAccount: BankAccountUpdateInput!) {
    updateBankAccount(id: $id, data: $bankAccount) {
      id
    }
  }
`;

export function useUpdateBankAccount() {
  const [updateBankAccount, { loading, error, data }] = useMutation(
    UPDATE_BANK_ACCOUNT
  );
  return {
    updateBankAccount,
    loadingDelete: loading,
    error,
    bankAccount: data ? data.updateBankAccount : undefined,
  };
}

const GET_ESCROW_BANK_ACCOUNT_QUERY = gql`
  query EscrowAccount($destBankAccount: ID!) {
    EscrowAccount(where: { id: $destBankAccount }) {
      id
      bankName
      institutionCode
      description
      accountNumber
      accountLastFourDigits
      bankDisplayName
      active
      updatedAt
      createdAt
    }
  }
`;

export function useEscrowAccount(id) {
  const { loading, error, data, refetch } = useQuery(
    GET_ESCROW_BANK_ACCOUNT_QUERY,
    {
      variables: { destBankAccount: id },
    }
  );
  return {
    loading,
    error,
    refetch,
    destBankAccount: data ? data.destBankAccount : undefined,
  };
}
