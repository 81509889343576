import React from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuth0 } from './react-auth0-spa';
import get from 'lodash/get';
import size from 'lodash/size';
import isNull from 'lodash/isNull';
import split from 'lodash/split';
import find from 'lodash/find';
import { useClientByPhone } from './utils/service/service_user_api';
import {
  useAllTransfers,
  useSaveTransfer,
} from './utils/service/service_transfer_api';
import {
  useAllTenants,
  useAllBrandingsFromTenant,
  useDefaultBrandingFromTenant,
} from './utils/service/service_tenant_api';
import HomePage from './components/HomePage/HomePage';
import { styleSingleton } from 'react-style-singleton';
import WebFont from 'webfontloader';
import { getClientStore } from './utils/util';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/react-boostrap/bootstrapv3.css';
import { ToastProvider } from 'react-toast-notifications';
import SpinnerLoader from './components/SpinnerLoader/SpinnerLoader';
import { Helmet } from 'react-helmet';
import { SensitiveDataProvider } from './contexts/sensitiveDataContext';

const App = props => {
  const { loading } = useAuth0();
  const { user } = getClientStore();
  const tenantIdentifier = split(window.location.hostname, '.')[0];
  const { loading: loadingTenant, tenant } = useAllTenants(tenantIdentifier);

  const { loadingSearch, client } = useClientByPhone(
    user ? user.mobilePhone : ''
  );
  const { loadingSearchTransfer, transfers } = useAllTransfers(
    get(client, 'id', '')
  );
  const { loadingBranding, branding } = useAllBrandingsFromTenant(
    get(tenant, 'id', ''),
    true
  );
  const {
    loadingDefaultBranding,
    defaultBranding,
  } = useDefaultBrandingFromTenant();
  const { saveTransfer } = useSaveTransfer();
  const Style = styleSingleton();

  const hasTransfer = size(transfers) !== 0;
  const hasInitiatedTransfer = find(transfers, ['status', 'Initiated']);

  let brandLogo,
    brandBackgroundImage,
    brandBackgroundColor,
    brandName,
    introductoryText,
    registerButtonText,
    behindFieldsColor,
    useStyle,
    fontFamily,
    customCSS,
    linkColor,
    buttonColor,
    fontColor;

  if (!loadingBranding && !loadingDefaultBranding) {
    brandLogo = branding
      ? get(branding, 'logoURL')
      : get(defaultBranding, 'logoURL');
    brandBackgroundImage = branding
      ? get(branding, 'backgroundImageURL')
      : get(defaultBranding, 'backgroundImageURL');
    brandBackgroundColor = branding
      ? get(branding, 'backgroundColor')
      : get(defaultBranding, 'backgroundColor');
    brandName = get(tenant, 'identifier');
    introductoryText = branding
      ? get(branding, 'registerText')
      : get(defaultBranding, 'registerText');
    registerButtonText = branding
      ? get(branding, 'registerButtonText')
      : get(defaultBranding, 'registerButtonText');
    behindFieldsColor = branding
      ? get(branding, 'accentColor')
      : get(defaultBranding, 'accentColor');
    fontFamily = branding
      ? get(branding, 'fontFamily')
      : get(defaultBranding, 'fontFamily');
    customCSS = branding
      ? get(branding, 'customCSS')
      : get(defaultBranding, 'customCSS');
    linkColor = branding
      ? get(branding, 'linkColor')
      : get(defaultBranding, 'linkColor');
    buttonColor = branding
      ? get(branding, 'buttonColor')
      : get(defaultBranding, 'buttonColor');
    fontColor = branding
      ? get(branding, 'fontColor')
      : get(defaultBranding, 'fontColor');
    fontFamily = fontFamily ? fontFamily : 'Poppins';
    const mobile = window.screen.width < 1200;
    const isHide = !user;

    //Menu width - grey menu width
    const widthWithMenu = isHide || mobile ? '100%' : 'calc(100vw - 60px)';

    useStyle = `
                .paymints__box-container__box-shadow--default {
                    background-color: ${brandBackgroundColor};
                }

                .paymints__box-container__internal--default {
                    background-color: ${behindFieldsColor};
                }

                .App {
                    font-family: ${fontFamily} !important;
                    background-image: url(${brandBackgroundImage});
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-attachment: fixed;
                    width: ${widthWithMenu};
                    color: ${fontColor} !important;
                }

                body {
                    font-family: ${fontFamily} !important;
                    color: ${fontColor} !important;
                }

                label {
                    color: black !important;
                }

                .table>tbody>tr>td {
                    color: ${fontColor};
                }

                .form-control {
                    color: ${fontColor} !important;
                }

                .ant-btn {
                    font-family: ${fontFamily} !important;
                    border-radius: 10px;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    height: 45px !important;
                    text-transform: capitalize !important;
                    font-weight: 600;
                }

                .ant-btn.box-container__button-log {
                  border-radius: 4px !important;
                  border-color: ${buttonColor} !important;
                }

                .accept-button {
                    font-family: ${fontFamily} !important;
                    background-color: ${buttonColor} !important;
                    border: none !important;
                }

                #sent {
                    text-decoration: none;
                }

                .paymints__register-button.ant-btn {
                    font-family: ${fontFamily} !important;
                    background-color: ${buttonColor} !important;
                    border: none !important;
                }

                .paymints__register-button.ant-btn:hover {
                    background-color: white !important;
                    color: ${fontColor} !important;
                    border: solid 1px white !important;
                }

                .content-modal__button.ant-btn {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }

                .paymints__link {
                    color: ${linkColor};
                }

                .transfer-history__row-item {
                    background-color: ${behindFieldsColor};
                }

                .box-container__button-log {
                    background-color: ${buttonColor} !important;
                    border-radius: 4px !important;
                    border-color: ${buttonColor}
                }

                .box-container__button-log:hover {
                    border-radius: 4px !important;
                    color: ${buttonColor} !important;
                }

                .create-account__button {
                    background-color: ${buttonColor} !important;
                    border-color: ${buttonColor} !important;
                    border-radius: 4px !important;
                }

                .create-account__button:hover {
                    background-color: white !important;
                    border-radius: 4px !important;
                    color: ${buttonColor} !important;
                }

                .create-account__save-come-back-button {
                    color: #1e5fe3;
                    border-radius: 4px !important;
                }

                .modal-backdrop {
                    z-index: 1500;
                }

                ${
                  mobile
                    ? `.menu-nav-bar__icon-angle-left {
                        color: #4162fa;
                    }

                    .menu-nav-bar__icon-angle-right {
                        color: #4162fa;
                    }`
                    : ``
                }

                ${customCSS ? customCSS : ''}
            `;

    const fonts = fontFamily
      ? [`${fontFamily}:300,400,700`, 'Poppins:300,400,700', 'sans-serif']
      : ['Poppins:300,400,700', 'sans-serif'];

    WebFont.load({
      google: {
        families: fonts,
      },
    });
  }

  const tenantName = get(tenant, 'name', 'paymints.io');
  const tenantFavicon = get(branding, 'faviconImgURL');

  if (loadingBranding || loadingDefaultBranding) {
    return <SpinnerLoader />;
  }

  return (
    <>
      <Helmet>
        <title>{tenantName}</title>
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="16x16 24x24 32x32 48x48 64x64"
          href={tenantFavicon}
        />
      </Helmet>
      <Style styles={useStyle} />
      <ToastProvider autoDismiss autoDismissTimeout={6000}>
        <Provider store={props.store}>
          <SensitiveDataProvider
            clientID={get(client, 'id')}
            tenantID={get(tenant, 'id')}
          >
            <Router>
              <HomePage
                {...props}
                hasTransfer={hasTransfer && !isNull(get(client, 'id'))}
                user={user}
                loading={loading}
                loadingSearch={loadingSearch}
                loadingSearchTransfer={loadingSearchTransfer}
                loadingBranding={loadingBranding || loadingDefaultBranding}
                brandLogo={brandLogo}
                brandName={brandName}
                introductoryText={introductoryText}
                registerButtonText={registerButtonText}
                loadingTenant={loadingTenant}
                tenant={tenant}
                hasInitiatedTransfer={hasInitiatedTransfer}
                saveTransfer={saveTransfer}
                client={client}
                branding={branding}
                defaultBranding={defaultBranding}
                loadingDefaultBranding={loadingDefaultBranding}
              />
            </Router>
          </SensitiveDataProvider>
        </Provider>
      </ToastProvider>
    </>
  );
};

export default connect(state => ({}), {})(App);
