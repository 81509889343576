import React, { useState } from 'react'
import uploadImage from '../../styles/assets/test-document-upload-success.png';
import BoxContainer from '../BoxContainer/BoxContainer';
import InputFile from '../FileUpload/InputFile';
import { useForm, Controller } from "react-hook-form";
import get from "lodash/get";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { uploadDocument } from '../../reducers/asset/AssetActions';
import { saveTransferDoc } from '../../reducers/transfer/TransferActions';
import { PaymintsButton, BUTTON_TYPE } from 'paymints-address-autocomplete-field';
import updateAction from "../CreateAccountWizard/updateAction";
import { useStateMachine } from "little-state-machine";
import ContentModal from '../ContentModal/ContentModal';
import { useToasts } from 'react-toast-notifications'
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";


const buildLoaderModal = () => (
    <div className="create-account__modal">
        <div className="create-account__modal-loading">
            <SpinnerLoader withoutMargin />
        </div>
        <div className="create-account__modal-info">
            Loading please wait...
        </div>
    </div>
)
const uploadIdDocument = ({ props, action, transferId, selectedDocument, selectedDocumentType, onSetError }) => {
    const tenantId = get(props, 'tenant.id');
    const clientId = get(props, 'client.id');
    const type = selectedDocumentType.toLowerCase();

    const form = new FormData();
    form.append('TENANT_GUID', tenantId);
    form.append('CLIENT_GUID', clientId);
    // form.append('TRANSFER_GUID', transferId);
    form.append(type, selectedDocument);

    return props.onUploadDocument(form)
        .then((responseUploadDocument) => {
            if (!get(responseUploadDocument, 'error')) {
                const data = {
                    documentType: type,
                    assetId: get(responseUploadDocument, `s3-assets.${type}.assetID`)
                }
                return props.onSaveTransferDoc(transferId, data)
                    .then((responseTransferDoc) => {
                        if (!get(responseTransferDoc, 'error')) {
                            action({ asset: { id: get(responseUploadDocument, `s3-assets.${type}.assetID`) } });
                            return { success: true }
                        } else {
                            return { success: false }
                        }
                    })
            } else {
                return { success: false }
            }
        })
}
const DocumentVerification = (props) => {
    const [isLoading, onSetLoadingService] = useState(false);
    const { register: regDocument, control: controlDocument, watch: watchDocument, handleSubmit } = useForm();
    const { action } = useStateMachine(updateAction);
    const transferId = get(props, 'client.id');
    const selectedDocument = watchDocument('selectedDocument');
    const selectedDocumentType = watchDocument('selectedDocumentType');
    const { addToast } = useToasts();
    const onSubmit = () => {
        onSetLoadingService(true);
        uploadIdDocument({ props, action, transferId, selectedDocument, selectedDocumentType }).then(response => {
            if (response.success) {
                onSetLoadingService(false);
                props.history.push(`/bank-accounts`);
                addToast('Your Document has successfully uploaded.', { appearance: 'success' });
            }
        });
    }
    return (
        <>
            <ContentModal
                isVisible={isLoading}
                content={buildLoaderModal()}
                small
                withoutButtons
                hideCloseButton
            />
            <BoxContainer widest withLogButton withDoubleBorder>
                <form onSubmit={handleSubmit(() => onSubmit())}>
                    <div className="create-account__line-container">
                        <div className="create-account__title">
                            More Data is Required
            </div>
                    </div>
                    <div className="create-account__line-container">
                        <div className="create-account__subtitle with-margin">
                            Your transfer is not complete.
            </div>
                    </div>
                    <div className="create-account__information-container create-account__no-margine">
                        <div className="create-account__subtitle-information">
                            The bank is requesting identification verification.
            </div>
                    </div>
                    <div className="create-account__information-id">
                        <ul className="create-account__information-list-id">
                            <li>Full name and ID number should be clearly visible.</li>
                            <li>ID cannot be cut off from any side.</li>
                            <li>Image must be horizontal and legible.</li>
                            <li>Acceptable file types are .jpg, .jpeg, and .png.</li>
                            <li>File size must be less than 10MB.</li>
                        </ul>
                    </div>
                    <div className="create-account__information-container">
                        <BoxContainer internalBox withBorder>
                            <div className="create-account__image-id">
                                <img className="create-account__image-size-id" src={uploadImage} alt="" />
                            </div>

                            <div className="create-account__type-document-id">
                                Type of document
                <select className="create-account__type-document-options-id form-control" name="selectedDocumentType" ref={regDocument}>
                                    <option>Passport</option>
                                    <option>License</option>
                                </select>
                            </div>

                            <div className="create-account__upload-document form-group">
                                <label htmlFor="selectedDocument">Upload photo</label>
                                <Controller as={InputFile} name="selectedDocument" control={controlDocument} selected={get(watchDocument('selectedDocument'), 'name')} />
                            </div>
                        </BoxContainer>
                        <div className="create-account__footer-buttons create-account__mobile-transfer-footer-buttons">
                            <PaymintsButton
                                className="mr-4 create-account__transfer-action-button mobile__transfer-action-button"
                                noShadow
                                type={BUTTON_TYPE.INVERSE_BLUE}
                                htmlType="submit"

                            >
                                Save and Continue
                    </PaymintsButton>
                        </div>
                    </div>
                </form>
            </BoxContainer>
        </>
    )
}
export default withRouter(connect(
    state =>
        ({}), {
        onUploadDocument: uploadDocument,
        onSaveTransferDoc: saveTransferDoc,
    }
)(DocumentVerification));