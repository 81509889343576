import React from 'react';
import MaskedInput from 'react-text-mask';

const SsnMask = [/\d/, /\d/, /\d/, /\d/];

export default ({...props}) => {
    const handleChange = (e) => props.onChange(`${e.target.value}`.replace(/_/g, ''));

    return (
        <MaskedInput
            mask={SsnMask}
            {...props}
            onChange={handleChange}
        />
    );
};
