import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
import 'normalize.css';
import './index.css';
import './styles/fonts/index.css';
import ConfigureStore from './store/Store';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from '../src/utils/client';

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

let preloadedState;
if (window.currentUser) {
    preloadedState = { session: { currentUser: window.currentUser } };
}
const store = ConfigureStore(preloadedState);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <App store={store} />
    </Auth0Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();