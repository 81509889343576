import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { useCreateClient } from '../../utils/service/service_user_api';
import { setItem, removeItem } from '../../utils/util';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../CreateAccountWizard/updateAction';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import { updateAuth0User } from '../../reducers/account/AccountActions';
import PhoneField from '../PhoneField/PhoneField';
import Warning from '../../styles/assets/warning.png';
import Phone from '../../styles/assets/phone.png';
import linkIcon from '../../styles/assets/external-link-icon.svg';
import * as APIUtil from '../../utils/service/service_api';
import phoneFormatter from '../../utils/service/phoneFormatter';

import '../CreateAccountWizard/createAccount.css';

const mobile = window.screen.width < 1200;

const Logo = ({ props }) => {
  return (
    <div className="paymints__box-container__logo-container">
      <img
        src={props.brandLogo}
        className="paymints__box-container__brand_logo"
        alt=""
        hidden={mobile}
      />
      <img
        src={props.brandLogo}
        className="paymints__box-container__brand_logo"
        alt=""
        hidden={!mobile}
      />
    </div>
  );
};

/**
const Title = () => {
  return (
    <div>
      <div style={{ fontSize: '24px', fontWeight: '600' }}>Welcome</div>
    </div>
  );
};
 */

const RealtorIntroText = ({ props }) => {

  const introDefault = props?.realtor?.firstName + '  ' + props?.realtor?.lastName +  ' has partnered with ' +
    props.tenant.name + ' to securely transfer funds';
  const introText = props?.realtor?.displayDescription || introDefault;

  return (
    <div className="">
      <div
        style={{ textAlign: 'center', margin: '0 1em' }}
        className="create-account__subtitle"
      >       
        {introText}
      </div>
      <div style={{ textAlign: 'center', margin: '1em' }}>
        To get started please create an account by entering your name and mobile phone number below.
      </div>
    </div>
  );
};

const FieldsPersonalInformation = ({ register, errors }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '1em',       
      }}
    >
      <div style={{ width: !mobile && '40%', marginTop: '1em' }}>
        <label style={{ display: 'flex' }}>First Name</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          className="create-account__input form-control"
          placeholder=" "
          autoComplete="no"
          ref={register({ required: true, maxLength: 50 })}
        />
        {errors.firstName && errors.firstName.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
        {errors.firstName && errors.firstName.type === 'maxLength' && (
          <ErrorMessageInput>Max length exceeded</ErrorMessageInput>
        )}
      </div>
      <div style={{ width: !mobile && '40%', marginTop: '1em' }}>
        <label style={{ display: 'flex' }}>Middle Name</label>
        <input
          type="text"
          id="middleName"
          name="middleName"
          className="create-account__input form-control"
          placeholder=" "
          autoComplete="no"
          ref={register}
        />
      </div>
      <div style={{ width: !mobile && '40%', marginTop: '1em' }}>
        <label style={{ display: 'flex' }}>Last Name</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          className="create-account__input form-control"
          placeholder=" "
          autoComplete="no"
          ref={register({ required: true, maxLength: 50 })}
        />
        {errors.lastName && errors.lastName.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
        {errors.lastName && errors.lastName.type === 'maxLength' && (
          <ErrorMessageInput>Max length exceeded</ErrorMessageInput>
        )}
      </div>
      <div
        style={{ width: !mobile && '40%', margin: '1em' }}
        className="create-account__warning-conatiner"
      >
        <img src={Warning} className="create-account__warning-icon" alt="" />
        <div className="create-account__warning-text">
        Must match your driver's license or government-issued ID.</div>
      </div>
    </div>
  );
};

const PersonalInformation = ({
  client,
  watch,
  register,
  errors,
  setValue,
  control,
}) => (
  <div className="create-account__information-container-partner">
    <FieldsPersonalInformation
      client={client}
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
    />
  </div>
);

const FieldsContactInformation = ({ errors, control }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '1em',
    }}
  >
    <div style={{ width: !mobile && '40%', margin: '1em' }}>
      <label style={{ display: 'flex', marginLeft: mobile && '20%' }}>
        Mobile Phone
      </label>
      <Controller
        as={PhoneField}
        control={control}
        className="create-account__input form-control"
        name="phone_number"
        placeholder=" "
        rules={{ required: true }}
        style={{
          width: mobile ? '14em' : '100%',
          height: '32px',
          margin: mobile && '0 auto',
        }}
      />
      {errors.phone_number && errors.phone_number.type === 'required' && (
        <ErrorMessageInput>This is required</ErrorMessageInput>
      )}
      <div
        style={{ marginTop: '1em' }}
        className="create-account__warning-conatiner"
      >
        <img src={Phone} className="create-account__warning-icon" alt="" />
        <div className="create-account__warning-text">
          We will send a security code to your mobile phone for account verification.
        </div>
      </div>
    </div>
  </div>
);

const ContactInformation = ({ client, errors, control }) => {
  return <FieldsContactInformation errors={errors} control={control} />;
};

const FooterInformation = ({ setDisabled }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '1em',
    }}
  >
    <div style={{ width: '60%', marginTop: '1em' }}>
      <span>
        <input
          type="checkbox"
          className="form-group"
          onChange={e => setDisabled(!e.target.checked)}
        />{' '}
      </span>{' '}
      {'By clicking Create Account, you agree to paymints.io '}
      <a
        href="https://www.paymints.io/terms-of-use/"
        rel="noopener noreferrer"
        className="paymints__link"
      >
        Terms of Service{' '}
        <img src={linkIcon} className="create-account__link-icon" alt="" />{' '}
      </a>
      {' and '}
      <a
        href="https://www.paymints.io/privacy-policy/"
        rel="noopener noreferrer"
        className="paymints__link nowrap"
      >
        Privacy Policy{' '}
        <img src={linkIcon} className="create-account__link-icon" alt="" />
      </a>
      {' as well as our partner'} Dwolla's
      <a
        href="https://www.dwolla.com/legal/dwolla-account-terms-of-service/"
        rel="noopener noreferrer"
        className="paymints__link"
      >
        {' '}
        Terms of Service{' '}
        <img src={linkIcon} className="create-account__link-icon" alt="" />
      </a>{' '}
      and{' '}
      <a
        href="https://www.dwolla.com/legal/privacy/"
        rel="noopener noreferrer"
        className="paymints__link"
      >
        Privacy Policy.
        <img src={linkIcon} className="create-account__link-icon" alt="" />
      </a>
    </div>
  </div>
);

const nextStep = history => {
  history.push(`/create-account/step-2${get(history, 'location.search')}`);
};

const FirstStep = props => {
  const {
    beforeContinue,
    onUpdateAuth0User,
    refetchNewClient,
    realtor,
  } = props;

  const { register, handleSubmit, errors, watch, setValue, control } = useForm({
    defaultValues: props.client,
  });
  const { createClient } = useCreateClient();
  const { action } = useStateMachine(updateAction);
  const [error, onSetError] = useState({
    active: false,
    type: '',
    messageError: '',
  });
  const [disabled, setDisabled] = useState(true);
  const [licenseArray, setLicenseArray] = useState([]);

  const onSubmit = newUser => {
    removeItem('login_user');
    onSetError({ active: false, type: '', messageError: '' });
    const email = get(props.user, 'email')
      ? get(props.user, 'email')
      : `${newUser.phone_number}@paymints.io`;
    const tenantId = get(props, 'tenant.id');
    if (isEmpty(tenantId))
      onSetError({
        active: true,
        messageError: 'Tenant not found, please check url',
      });
    if (email && !isEmpty(tenantId)) {
      const { phone_number } = newUser;
      // Search for all clients with that email
      refetchNewClient({ where: { mobilePhone: '+1' + phone_number } }).then(
        response => {
          const client = get(response, 'data.allClients')[0];
          delete newUser.phone_number;
          if (!client) {
            let clientData = {};
            // Create a new client
            createClient({
              variables: {
                client: {
                  ...newUser,
                  email: '',
                  mobilePhone: '+1' + phone_number,
                  tenant: {
                    connect: {
                      id: tenantId,
                    },
                  },
                },
              },
            })
              .then(res => {
                clientData = res.data.createClient;
                beforeContinue();
              })
              .then(() => {
                // Create Auth0 client
                const userEmail = get(props.user, 'email');
                if (!userEmail) {
                  const auth0User = {
                    email: email,
                    phone_number: `+1${phone_number}`,
                    name: `${newUser.firstName} ${newUser.lastName}`,
                    connection: 'sms',
                  };
                  const auth0Payload = {
                    create_user: auth0User,
                    role: 'paymints_admin',
                    new_user_role: 'paymints_client',
                  };

                  onUpdateAuth0User(tenantId, auth0Payload)
                    .then(response => {
                      if (response === 'error') {
                        onSetError({
                          active: true,
                          messageError:
                            'Account exists for phone number.  Please login in.',
                        });
                      } else {
                        getCode(tenantId, clientData.id, clientData);
                      }
                    })
                    .then(() => {
                      action(newUser);
                    });
                } else {
                  action(newUser);
                  nextStep(props.history);
                }
              });
          } else {
            onSetError({ active: true, type: 'sms' });
            setItem('login_user', 'existing_user');
          }
        }
      );
    }
  };

  const getCode = (tenantId, clientid, client) => {
    APIUtil.getSMSCode(tenantId, clientid).then(
      () => {
        props.history.push('/create-account/account-verification', { client });
      },
      error => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (props?.realtor) {
      setLicenseArray(JSON.parse(props.realtor.licenses));
    }
  }, [props.realtor]);

  const nameDefault = props?.realtor?.firstName + '  ' + props?.realtor?.lastName;
  const nameText = props?.realtor?.displayName || nameDefault;

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '100vh',
        marginTop: mobile && '-20px', 
      }}
    >     
      <div style={{ display: 'flex', flexDirection: mobile && 'column' }}>
        <div
          style={{
            backgroundColor: !mobile && '#ecf0ff',
            width: mobile ? '100%' : '25em',
            height: !mobile && '100vh',
          }}
        >
          <div style={{ marginTop: '5em' }}>
            {realtor?.headshotImageURL ? (
              <img
                style={{ borderRadius: '50% ' }}
                src={realtor?.headshotImageURL}
                alt={nameText}
                width='200px'
              />
            ) : (
              <img
                style={{ borderRadius: '50% ' }}
                src={`https://ui-avatars.com/api/?name=${realtor?.firstName}+${realtor?.lastName}&size=200`}
                alt={nameText}
              />
            )}
          </div>
          <div style={{ marginTop: '0.5em', fontSize: '2em' }}>
            {nameText}
          </div>
          <div style={{ marginTop: '0.5em', fontSize: '1em' }}>
            <div>{`Agent with ${realtor?.brokerage?.name}`} </div>
            <div>
              {licenseArray && licenseArray.length? (
                <span>License<br/></span>
              ):""}
              {licenseArray.map(license => (
                <span>{`${license.state} #${license.license}`}<br/></span>
              ))}
            </div>
          </div>
          <div
            style={{ marginTop: '1em', marginLeft: '1.5em' }}
            hidden={!realtor?.email}
          >
          </div>
          <div style={{ marginTop: '2em' }}>
            {realtor?.brokerage?.logoURL ? (
              <img
                style={{ width: '10em' }}
                src={realtor?.brokerage?.logoURL}
                alt={`${realtor?.brokerage?.name}`}
              />
            ) : (
              <div style={{ fontSize: '1.5em' }}>
                {`${realtor?.brokerage?.name}`}{' '}
              </div>
            )}
          </div>          
          <div
            style={{
              marginTop: '1em',
              fontSize: '1em',
              marginBottom: mobile && '1em',
            }}
          >
            <div><a href={`${realtor?.brokerage?.websiteURL}`} target="_blank">{`${realtor?.brokerage?.websiteURL}`}</a></div>
            <div>
              {phoneFormatter(
                '(XXX) XXX-XXXX',
                realtor?.brokerage?.officePhone?.replace('+1', '')
              )}{' '}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '3em',
          }}
        >
          <Logo props={props} />
          <RealtorIntroText props={props} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <PersonalInformation
              client={props.client}
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              control={control}
            />
            <ContactInformation
              client={props.client}
              errors={errors}
              control={control}
            />
            <FooterInformation setDisabled={setDisabled} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '1em',
                marginBottom: '2em',
                width: mobile && '100%',
              }}
            >
              <PaymintsButton
                htmlType="submit"
                className="create-account__button"
                type={BUTTON_TYPE.INVERSE_BLUE}
                disabled={disabled}
                style={{ marginLeft: '1.5em' }}
              >
                <div className="create-account__button-text">
                  {props.registerButtonText
                    ? props.registerButtonText === 'Register!'
                      ? 'Create Account'
                      : props.registerButtonText
                    : 'Create Account'}
                </div>
              </PaymintsButton>
              {error.active && (
                <ErrorMessageInput>{error.messageError}</ErrorMessageInput>
              )}
              {error.active && error.type === 'sms' && (
                <ErrorMessageInput>
                  Account exists for phone number. Please{' '}
                  <Link to="/login" params={{ partnerURI: props.partnerURI} }>login</Link>
                </ErrorMessageInput>
              )}
              <div>
                {' '}
                Already registered? Click to{' '}
                <span
                  className="create-account__paymints-link"
                  onClick={() => props.history.push(`/login?partnerURI=${props.partnerURI}`)}
                >
                  log in
                </span>{' '}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(state => ({}), {
    onUpdateAuth0User: updateAuth0User,
  })(FirstStep)
);
