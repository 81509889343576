import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_CLIENTS_MESSAGE = gql`
  query AllClientMessages($where: ClientMessageWhereInput) {
    allClientMessages(where: $where) {
      id
      messageURL
      updatedAt
      createdAt
      _label_
      type
      tenant {
        name
      }
      transfer {
        id
        propertyAddress1
        propertyAddress2
        propertyCity
        propertyZipcode
        propertyState
        sellerName
      }
    }
  }
`;
export function useClientMessages(whereClause = {}) {
  const { loading, error, data, refetch } = useQuery(GET_CLIENTS_MESSAGE, {
    variables: { where: whereClause },
  });
  return {
    loading,
    error,
    refetch,
    allClientMessages: data ? data.allClientMessages : [],
  };
}
