import * as APIUtil from '../../utils/service/service_api';
import * as actionType from './AccountActionTypes';
import get from 'lodash/get';
import { messageKey } from '../../components/MessageCenter/mock-data';

export const getPlaidCreateClientFetch = () => ({
  type: actionType.PLAID_CREATE_CUSTOMER_FETCH,
});

export const getPlaidCreateClientSuccess = response => ({
  type: actionType.PLAID_CREATE_CUSTOMER_SUCCESS,
  payload: { response },
});

export const getPlaidCreateClientError = response => ({
  type: actionType.PLAID_CREATE_CUSTOMER_ERROR,
  payload: { response },
});

export const getPlaidCreateClient = data => dispatch => {
  dispatch(getPlaidCreateClientFetch());
  return APIUtil.plaidCreateClient(data)
    .then(response => {
      dispatch(getPlaidCreateClientSuccess(response));
      return response;
    })
    .catch(error => {
      const errorElement = get(error, 'response.data');
      const message =
        get(errorElement, 'error.body.message') ||
        get(errorElement, 'message') ||
        get(errorElement, 'error_message') ||
        'Something went wrong... please try again in a few minutes';
      dispatch(getPlaidCreateClientError(message));
      return { error: true, message: message };
    });
};

export const getPlaidStartMicrodepoits = (data, tenantID) => dispatch => {
  return APIUtil.plaidStartMicrodepoits(data, tenantID)
    .then(response => {
      return response;
    })
    .catch(error => {
      const errorElement = get(
        error,
        'response.data getPlaidStartMicrodepoits'
      );
      const message =
        get(errorElement, 'error.body.message') ||
        get(errorElement, 'message') ||
        get(errorElement, 'error_message') ||
        'Something went wrong... please try again in a few minutes';
      return { error: true, message: message };
    });
};

export const getPlaidVerifyMicrodepoits = data => dispatch => {
  return APIUtil.plaidStartMicrodepoits(data)
    .then(response => {
      return response;
    })
    .catch(error => {
      const errorElement = get(
        error,
        'response.data getPlaidVerifyMicrodepoits'
      );
      const message =
        get(errorElement, 'error.body.message') ||
        get(errorElement, 'message') ||
        get(errorElement, 'error_message') ||
        'Something went wrong... please try again in a few minutes';

      return { error: true, message: message };
    });
};

export const getBankAccountsFetch = () => ({
  type: actionType.GET_BANK_ACCOUNTS_FETCH,
});

export const getBankAccountsSuccess = response => ({
  type: actionType.GET_BANK_ACCOUNTS_SUCCESS,
  payload: { response },
});

export const getBankAccountsError = response => ({
  type: actionType.GET_BANK_ACCOUNTS_ERROR,
  payload: { response },
});

export const getClientAccounts = id => dispatch => {
  if (id !== undefined) {
    dispatch(getBankAccountsFetch());
    return APIUtil.getBankAccounts(id)
      .then(response => {
        dispatch(getBankAccountsSuccess(response));
        return response;
      })
      .catch(error => {
        const errorElement = get(error, 'response.data');
        const message =
          get(errorElement, 'error.body.message') ||
          get(errorElement, 'message') ||
          get(errorElement, 'error_message') ||
          'Something went wrong... please try again in a few minutes';
        dispatch(getBankAccountsError(message));
        return { error: true, message: message };
      });
  } else {
    return null;
  }
};

export const disconnectBankAccountFetch = () => ({
  type: actionType.DELETE_BANK_ACCOUNT_FETCH,
});

export const disconnectBankAccountSuccess = response => ({
  type: actionType.DELETE_BANK_ACCOUNT_SUCCESS,
  payload: { response },
});

export const disconnectBankAccountError = () => ({
  type: actionType.DELETE_BANK_ACCOUNT_ERROR,
});

export const disconnectBankAccount = id => dispatch => {
  dispatch(disconnectBankAccountFetch());
  return APIUtil.disconnectBankAccount(id)
    .then(response => {
      dispatch(disconnectBankAccountSuccess(response));
      return response;
    })
    .catch(error => {
      const errorElement = get(error, 'response.data');
      const message =
        get(errorElement, 'error.body.message') ||
        get(errorElement, 'message') ||
        get(errorElement, 'error_message') ||
        'Something went wrong... please try again in a few minutes';
      dispatch(disconnectBankAccountError());
      return { error: true, message: message };
    });
};

export const updateAuth0UserFetch = () => ({
  type: actionType.UPDATE_AUTH0_FETCH,
});

export const updateAuth0UserSuccess = response => ({
  type: actionType.UPDATE_AUTH0_SUCCESS,
  payload: { response },
});

export const updateAuth0User = (tenantId, data) => dispatch => {
  dispatch(updateAuth0UserFetch());
  return APIUtil.updateAuth0User(tenantId, data).then(response => {
    dispatch(updateAuth0UserSuccess(response));
    if (get(response, 'error')) {
      return 'error';
    }
    return response;
  });
};
