import React, { useState } from "react";
import classnames from "classnames";
import SsnField from "./SsnInput";
import "./ssn-toggle-visibility-input.css";

export default function SsnToggleVisibilityField(props) {
    const [visible, setVisible] = useState(false)

    const toggleVisibility = () => {
        setVisible(!visible)
    }

    const buildVisibilityButton = () => (
        <button
            type="button"
            className="btn btn-link ssn-toggle-visibility-field__button"
            onClick={toggleVisibility}
        >
            <i className={buildCssForIcon()} />
        </button>
    );

    const buildCssForIcon = () =>
        classnames("ssn-toggle-visibility-field__icon", "fa", {
            "fa-eye-slash": visible,
            "fa-eye": !visible
        });

    return (
        <div className="ssn-toggle-visibility-field" onFocus={() => setVisible(true)} onBlur={() => setVisible(false)}>
            <SsnField
                {...props}
                type={visible ? "text" : "password"}
            />
            {buildVisibilityButton()}
        </div>
    );
}