import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import format from 'dateformat';
import Table from 'react-bootstrap/Table';
import { Controller } from 'react-hook-form';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';

import {
  getClientAccounts,
  disconnectBankAccount,
} from '../../reducers/account/AccountActions';
import BoxContainer from '../BoxContainer/BoxContainer';
import { useClientByPhone } from '../../utils/service/service_user_api';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { useInstitutionLogoOR } from '../../utils/service/service_institution_logo';
import PlaidButton from '../PlaidButton/PlaidButton';
import PlaidAddBankAccountButton from '../PlaidButton/PlaidAddBankAccountButton';
import PlaidVerifyButton from '../PlaidButton/PlaidVerifyButton';
import usePlaidTokenForMicroDepositsStart from '../../hooks/plaid/usePlaidTokenForMicrodepositStart';
import SelectedBankAccounts from '../PlaidButton/SelectedBankAccount';
import {
  getPlaidCreateClient,
  getPlaidStartMicrodepoits,
  getPlaidVerifyMicrodepoits,
} from '../../reducers/account/AccountActions';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import FieldRadioButton from '../FieldRadioButton/FieldRadioButton';
import DisconnectModal from '../DisconnectModal/disconnectModal';
import { getClientStore } from '../../utils/util';
import { getQueryVariable } from '../../utils/util';
import AddAccountTooltip from './AddAccountTooltip';
import './bankAccounts.css';

///move after testing

// axios.defaults.headers.common = {
//   Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
// };
const Title = ({ isSelectAccount }) => (
  <div className="bank-accounts__line-container">
    <div className="bank-accounts__title">
      {isSelectAccount ? 'Select an Account' : 'Linked Bank Accounts'}
    </div>
  </div>
);

const Subtitle = ({ disbursement }) => (
  <div className="create-account__line-container">
    {disbursement ? (
      <div className="create-account__info-account">
        Please select the account for depositing your funds:
      </div>
    ) : (
      <div className="create-account__info-account">
        Please select the account you want to transfer money from:
      </div>
    )}
  </div>
);

const mobile = window.screen.width < 450;

/**
 * @function
 * Handle potential null balance for account
 */
const nullBalanceHandler = balance => {
  if (balance) {
    return '$' + balance.toFixed(2);
  }
  return '$__.__';
};

const BuildRow = ({
  Controller,
  bankAccount,
  control,
  setValue,
  isSelectAccount,
  onDisconnectBankAccount,
  onGetPlaidVerifyMicrodepoits,
  onSetError,
  action,
  register,
  onReloadBankAccounts,
  institutionsLogo,
  state,
  bankAccounts,
  client,
  tenant,
  reloadingBankAccounts
}) => {
  const clientId = get(client, 'id');
  const tenantId = get(tenant, 'id');
  const id = get(bankAccount, 'id');
  let balanceDateTime = get(bankAccount, 'balanceDateTime');
  const timeFormat = 'h:MM TT Z';
  const dateFormat = 'mm/dd/yyyy';
  const date = format(balanceDateTime, dateFormat);
  const time = format(balanceDateTime, timeFormat);
  const institution = get(bankAccount, 'bankName');
  const balance = get(bankAccount, 'balance');
  const accountLastFourDigits = get(bankAccount, 'accountNumber')
    ? get(bankAccount, 'accountNumber')
    : '';
  const institutionCode = get(bankAccount, 'institutionCode');
  const institutionLogo = get(
    find(institutionsLogo, ['institutionCode', institutionCode]),
    'logoURL'
  );
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let [linkToken, setlinkToken] = useState(false);
  let [bankAccountId, setBankAccountId] = useState(false);

  const NavyFederalCreditUnionSavingsAccount =
    bankAccount.bankName === 'Navy Federal Credit Union' &&
    bankAccount.name.includes('Saving');

    

  const onSuccess1 = data => {
    let body = {
      id: clientId,
      tenantId: tenantId,
      bankAccountId: bankAccountId,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_SERVICE_URL}/account/microdeposit/verify`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
          },
        }
      )
      .then(() => {
        onReloadBankAccounts(true);
      });
  };

  const getTokenResponse = async () => {
    let tokenResponse = await axios({
      url: `${process.env.REACT_APP_BASE_SERVICE_URL}/account/microdeposit/link-token`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      data: {
        bankAccountId: bankAccountId,
        id: clientId,
        tenantId: tenantId,
      },
    });
    return tokenResponse.data.linkToken;
  };

  const verificationStart = bankID => {
    setBankAccountId(bankID);
    setIsOpen(true);
  };

  useEffect(() => {
    if (bankAccountId) {
      getTokenResponse().then(token => {
        setlinkToken(token);
      });
    }
  }, [bankAccountId]);

  return (
    <React.Fragment key={id}>
      <DisconnectModal
        showDisconnectModal={showDisconnectModal}
        setShowDisconnectModal={setShowDisconnectModal}
        onReloadBankAccounts={onReloadBankAccounts}
        id={id}
        onDisconnectBankAccount={onDisconnectBankAccount}
        onSetError={onSetError}
      />
      <tr
        style={{
          backgroundColor: NavyFederalCreditUnionSavingsAccount
            ? '#C0C0C0'
            : null,
        }}
      >
        <td className="bank-accounts__item-text">
          <div className="bank-accounts__item-bank">
            {isSelectAccount && !NavyFederalCreditUnionSavingsAccount ? (
              <Controller
                as={FieldRadioButton}
                control={control}
                name="selection"
                disabled={bankAccount.pendingVerification}
                className="bank-accounts__checkbox"
                value={id}
                handleChange={() => {
                  setValue([{ selection: id }]);
                  action({ bankAccount: { id: id, balance: balance } });
                }}
                checked={get(state, 'data.bankAccount.id') === id}
              />
            ) : null}
            <img
              className="bank-accounts__bank-icon"
              src={institutionLogo}
              alt=""
            />
            <div className="bank-accounts__bank-institution" alt="">
              {institution}
            </div>
          </div>
        </td>
        <td className="bank-accounts__item-text">X{accountLastFourDigits}</td>
        <td className="bank-accounts__item-balance">
          {!balance ? (
            <div className="bank-accounts__item-text amountRow">--</div>
          ) : (
            <span>
              <div className="bank-accounts__item-text">
                {nullBalanceHandler(balance)}
              </div>
              <div className="bank-accounts__item-date">{`as of ${date}`}</div>
              <div className="bank-accounts__item-time">{`${time}`}</div>
              {!balance && (
                <p className="bank-accounts__null-balance-error">
                  We're having trouble retrieving your current balance.
                </p>
              )}
            </span>
          )}
        </td>
        {!isSelectAccount ? (
          !balance ? (
            <td className="bank-accounts__item-text center">--</td>
          ) : (
            <td className="bank-accounts__item-text center">
              <i
                className="fas fa-sync-alt bank-accounts__update-icon"
                onClick={() => reloadingBankAccounts()}
              />
            </td>
          )
        ) : null}
        {!isSelectAccount ? (
          <td className="bank-accounts__item-text center">
            <i
              className="fas fa-minus-circle bank-accounts__disconnect-icon"
              onClick={() => {
                setShowDisconnectModal(true);
              }}
            />
          </td>
        ) : null}
        {
          <td className="bank-accounts__item-text center">
            {bankAccount.pendingVerification ? (
              <div>
                <PlaidVerifyButton
                  config={{ token: linkToken }}
                  onClick={() => verificationStart(bankAccount.id)}
                  isOpen={isOpen}
                  onSuccess={onSuccess1}
                >
                  VERIFY
                </PlaidVerifyButton>
              </div>
            ) : !NavyFederalCreditUnionSavingsAccount ? (
              'Verified'
            ) : (
              'Unsupported'
            )}
          </td>
        }
      </tr>
      {/*  Navy Federal Credit Union saving account error message  */}
      {NavyFederalCreditUnionSavingsAccount && (
        <td colspan="6" style={{ color: 'red' }}>
          Navy Federal Credit Union savings accounts are non-transactional.
          Please move funds to Navy Federal checking account and link the
          checking account. Alternatively, please link another bank account.
          Thank you.
        </td>
      )}
    </React.Fragment>
  );
};
const SmallTable = ({
  key,
  bankAccount,
  onDisconnectBankAccount,
  isSelectAccount,
  action,
  register,
  onReloadBankAccounts,
  institutionsLogo,
  state,
  onSetError,
  setValue,
  control,
  client,
  tenant,
}) => {
  const clientId = get(client, 'id');
  const tenantId = get(tenant, 'id');
  const id = get(bankAccount, 'id');

  let balanceDateTime = get(bankAccount, 'balanceDateTime');
  const timeFormat = 'h:MM TT Z';
  const dateFormat = 'mm/dd/yyyy';
  const date = format(balanceDateTime, dateFormat);
  const time = format(balanceDateTime, timeFormat);
  const institution = get(bankAccount, 'bankName');
  const balance = get(bankAccount, 'balance');
  const accountLastFourDigits = get(bankAccount, 'accountNumber')
    ? get(bankAccount, 'accountNumber')
    : '';
  const institutionCode = get(bankAccount, 'institutionCode');
  const institutionLogo = get(
    find(institutionsLogo, ['institutionCode', institutionCode]),
    'logoURL'
  );
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let [linkToken, setlinkToken] = useState(false);
  let [bankAccountId, setBankAccountId] = useState(false);

  const NavyFederalCreditUnionSavingsAccount =
    bankAccount.bankName === 'Navy Federal Credit Union' &&
    bankAccount.name.includes('Saving');

  const onSuccess1 = data => {
    let body = {
      id: clientId,
      tenantId: tenantId,
      bankAccountId: bankAccountId,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_SERVICE_URL}/account/microdeposit/verify`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
          },
        }
      )
      .then(res => {
        window.location.reload();
      });
    // setTimeout(() => window.location.reload(), 3000);
  };

  const getTokenResponse = async () => {
    let tokenResponse = await axios({
      url: `${process.env.REACT_APP_BASE_SERVICE_URL}/account/microdeposit/link-token`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      data: {
        bankAccountId: bankAccountId,
        id: clientId,
        tenantId: tenantId,
      },
    });
    return tokenResponse.data.linkToken;
  };

  const verificationStart = bankID => {
    setBankAccountId(bankID);
    setIsOpen(true);
  };

  useEffect(() => {
    if (bankAccountId) {
      getTokenResponse().then(token => {
        setlinkToken(token);
      });
    }
  }, [bankAccountId]);
  return (
    <div
      className={
        isSelectAccount && NavyFederalCreditUnionSavingsAccount
          ? 'bank-accounts__mobile-border-navy-federal-credit-union-unsupported-account-warning'
          : isSelectAccount && !NavyFederalCreditUnionSavingsAccount
          ? `bank-accounts__mobile-border`
          : !isSelectAccount && NavyFederalCreditUnionSavingsAccount
          ? 'bank-accounts__mobile-navy-federal-credit-union-unsupported-account-warning'
          : `bank-accounts__mobile`
      }
      key={key}
    >
      {isSelectAccount && !NavyFederalCreditUnionSavingsAccount ? (
        <div className="f-left">
          <Controller
            as={FieldRadioButton}
            control={control}
            name="selection"
            disabled={bankAccount.pendingVerification}
            className="bank-accounts__checkbox"
            value={id}
            handleChange={() => {
              setValue([{ selection: id }]);
              action({ bankAccount: { id: id, balance: balance } });
            }}
            checked={get(state, 'data.bankAccount.id') === id}
          />
          <img
            className="bank-accounts__bank-icon"
            src={institutionLogo}
            alt=""
          />
        </div>
      ) : (
        <div className="f-left">
          <img
            className="bank-accounts__bank-icon"
            src={institutionLogo}
            alt=""
          />
        </div>
      )}
      <div
        className={
          isSelectAccount
            ? `contentSec f-left`
            : `contentSec f-left contentSec_width`
        }
      >
        <p>
          {institution} X{accountLastFourDigits}
        </p>
        {!balance ? (
          <div className="bank-accounts__item-text amountRow">--</div>
        ) : (
          <span>
            <div className="bank-accounts__item-text">
              {nullBalanceHandler(balance)}
            </div>
            <div className="bank-accounts__item-date">{`as of ${date}`}</div>
            <div className="bank-accounts__item-time">{`${time}`}</div>
            {!balance && (
              <p className="bank-accounts__null-balance-error">
                We're having trouble retrieving your current balance.
              </p>
            )}
          </span>
        )}

        {!isSelectAccount ? (
          <div className="iconSec">
            <p className="f-left">
              <i
                className="fas fa-minus-circle bank-accounts__disconnect-icon"
                onClick={() => {
                  onDisconnectBankAccount({ bankAccountId: id }).then(
                    response => {
                      if (!get(response, 'error')) {
                        onReloadBankAccounts(true);
                        onSetError({ active: false, messageError: '' });
                      } else {
                        onSetError({
                          active: true,
                          messageError: get(response, 'message'),
                        });
                      }
                    }
                  );
                }}
              />
              Disconnect
            </p>
            {!balance ? null : (
              <p className="f-left">
                <i
                  className="fas fa-sync-alt bank-accounts__update-icon"
                  onClick={() => onReloadBankAccounts(true)}
                />
                Refresh
              </p>
            )}
          </div>
        ) : null}

        <p className="f-left">
          <div className="bank-accounts__item-text">
            {/* DK I will need to make it prettier but in the meantime it works */}
            {bankAccount.pendingVerification ? (
              <div style={!isSelectAccount ? { marginTop: '-15px' } : null}>
                <PlaidVerifyButton
                  config={{ token: linkToken }}
                  onClick={() => verificationStart(bankAccount.id)}
                  isOpen={isOpen}
                  onSuccess={onSuccess1}
                >
                  VMOBILE
                </PlaidVerifyButton>
              </div>
            ) : !NavyFederalCreditUnionSavingsAccount ? (
              <div className="iconSec">
                <p className="f-left" style={{ marginTop: '-10px' }}>
                  <i className="fas fa-check bank-accounts__update-icon" />
                  Verified
                </p>
              </div>
            ) : (
              <div className="iconSec">
                <p className="f-left" style={{ marginTop: '-10px' }}>
                  <i className="fas fa-minus-circle bank-accounts__bank-unsupported-red-icon" />
                  Unsupported
                </p>
                <p className="f-left" style={{ marginTop: '-10px' }}>
                  <div colspan="6" style={{ color: 'red', marginTop: '8px' }}>
                    Navy Federal Credit Union savings accounts are
                    non-transactional. Please move funds to Navy Federal
                    checking account and link the checking account.
                    Alternatively, please link another bank account. Thank you.
                  </div>
                </p>
              </div>
            )}
          </div>
        </p>
      </div>
    </div>
  );
};

const BankAccountsList = ({
  isEmptyBankAccounts,
  watch,
  control,
  setValue,
  bankAccounts,
  onDisconnectBankAccount,
  isSelectAccount,
  action,
  register,
  onReloadBankAccounts,
  institutionsLogo,
  state,
  onSetError,
  tenant,
  client,
  reloadingBankAccounts
}) => {
  if (isEmptyBankAccounts && !isSelectAccount)
    return (
      <p className="text text-danger transfer-history__no-data">
        You have no linked bank accounts
      </p>
    );
  if (isEmptyBankAccounts) return null;

  return (
    <>
      {mobile ? (
        <>
          {bankAccounts.map((item, index) => (
            <SmallTable
              bankAccount={item}
              onDisconnectBankAccount={onDisconnectBankAccount}
              isSelectAccount={isSelectAccount}
              action={action}
              state={state}
              register={register}
              onReloadBankAccounts={onReloadBankAccounts}
              institutionsLogo={institutionsLogo}
              onSetError={onSetError}
              control={control}
              setValue={setValue}
              client={client}
              tenant={tenant}
            />
          ))}
        </>
      ) : (
        <TableData
          bankAccounts={bankAccounts}
          onDisconnectBankAccount={onDisconnectBankAccount}
          isSelectAccount={isSelectAccount}
          action={action}
          state={state}
          register={register}
          onReloadBankAccounts={onReloadBankAccounts}
          institutionsLogo={institutionsLogo}
          onSetError={onSetError}
          control={control}
          setValue={setValue}
          client={client}
          tenant={tenant}
          reloadingBankAccounts={reloadingBankAccounts}
        />
      )}
    </>
  );
};
const TableData = ({
  control,
  setValue,
  bankAccounts,
  onDisconnectBankAccount,
  isSelectAccount,
  action,
  register,
  onReloadBankAccounts,
  institutionsLogo,
  state,
  onSetError,
  client,
  tenant,
  reloadingBankAccounts
}) => {
  return (
    <Table responsive className="bank-accounts__table">
      <thead>
        <tr>
          <th className="bank-accounts__item-title">Financial Institution</th>
          <th className="bank-accounts__item-title">Account</th>
          <th className="bank-accounts__item-title">Balance</th>
          {!isSelectAccount ? (
            <th className="bank-accounts__item-title center">Update</th>
          ) : null}
          {!isSelectAccount ? (
            <th className="bank-accounts__item-title center">Disconnect</th>
          ) : null}
          {!isSelectAccount ? (
            <th className="bank-accounts__item-title center">Status</th>
          ) : (
            <th className="bank-accounts__item-title">Status</th>
          )}
        </tr>
      </thead>
      <tbody>
        {map(bankAccounts, (item, key) => (
          <BuildRow
            Controller={Controller}
            bankAccount={item}
            key={key}
            onDisconnectBankAccount={onDisconnectBankAccount}
            isSelectAccount={isSelectAccount}
            action={action}
            state={state}
            register={register}
            onReloadBankAccounts={onReloadBankAccounts}
            institutionsLogo={institutionsLogo}
            onSetError={onSetError}
            control={control}
            setValue={setValue}
            bankAccounts={bankAccounts}
            client={client}
            tenant={tenant}
            reloadingBankAccounts={reloadingBankAccounts}
          />
        ))}
      </tbody>
    </Table>
  );
};
const nextStep = history =>
  history.push(`/create-account/step-3${get(history, 'location.search')}`);

const nextStepFromSummary = history =>
  history.push(`/create-account/step-4${get(history, 'location.search')}`);

const BankAccounts = props => {
  const {
    account,
    onGetBankAccounts,
    onDisconnectBankAccount,
    action,
    state,
    register,
    children,
    isSelectAccount,
    withDoubleBorder,
    withLogButton,
    widest,
    history,
    onGetPlaidCreateClient,
    onGetPlaidVerifyMicrodepoits,
    onGetPlaidStartMicrodepoits,
    location,
    // showPlaidButton,
    loadingService,
    onSetLoadingService,
    watch,
    control,
    setValue,
    tenant,
    client,
  } = props;
  const { user } = getClientStore();
  const { loadingSearch, clientPhone } = useClientByPhone(
    user ? user.mobilePhone : ''
  );
  const [reloadBankAccounts, onReloadBankAccounts] = useState(false);
  const clientId = get(client, 'id');
  const tenantId = get(tenant, 'id');
  const [errorClient, onSetErrorClient] = useState({
    active: false,
    messageError: '',
  });
  const [error, onSetError] = useState({ active: false, messageError: '' });
  const [isVerifyingAndLinking, setIsVerifyingAndLinking] = useState(false);
  const [pncTooltipVisible, setPncTooltipVisible] = useState(false);
  const [startToken, setStartToken] = useState(false);
  const handleClose = () => setIsVerifyingAndLinking(false);
  const handleShow = () => setIsVerifyingAndLinking(true);
  let hasVerifiedAccount;
  const isFetchingBankAccounts = get(
    account,
    'bankAccounts.isFetchingBankAccounts'
  );
  const isFetchingCreateClient = get(
    account,
    'createClient.isFetchingCreateClient'
  );
  const isFetchedCreateClient = get(
    account,
    'createClient.isFetchedCreateClient'
  );
  const isFetchingDeleteBankAccount = get(
    account,
    'deleteBankAccount.isFetchingDeleteBankAccount'
  );
  const path = window.location.search.split('?')[2];
  const disbursement = getQueryVariable(path, 'disbursement');

  const reloadingBankAccounts = () => {
    onSetLoadingService(true);
      onGetBankAccounts(clientId).then(response => {
        onSetLoadingService(false);
        // onReloadBankAccounts(false);
        if (get(response, 'error')) {
          onSetError({ active: true, messageError: get(response, 'message') });
        } else {
          onSetError({ active: false, messageError: '' });
        }
      });
  } 

  

  //Microdeposit flow hooks

  const {
    PlaidTokenForMicroDepositsStart,
  } = usePlaidTokenForMicroDepositsStart();

  //Requesting token if it was not received yet, for Microdeposit flow start to pass it to the plaid button

  useEffect(() => {
    if (!startToken) {
      PlaidTokenForMicroDepositsStart().then(response =>
        setStartToken(response?.data.token)
      );
    }
  }, []);

  useEffect(() => {
    onGetBankAccounts(clientId).then(response => {
      if (get(response, 'error')) {
        onSetError({ active: true, messageError: get(response, 'message') });
      } else {
        onSetError({ active: false, messageError: '' });
      }
    });
    if (reloadBankAccounts) {
      
      
    }
    if (isFetchingCreateClient) {
      handleShow();
      onSetLoadingService(true);
    }
  }, [
    onGetBankAccounts,
    clientId,
    reloadBankAccounts,
    isFetchingCreateClient,
    onSetLoadingService
  ]);

  const bankAccounts = get(account, 'bankAccounts.bankAccounts.accounts', []);
  if (bankAccounts) {
    bankAccounts.forEach(account => {
      if (!account.pendingVerification) {
        hasVerifiedAccount = true;
      } else {
      }
    });
  }

  const isEmptyBankAccounts = size(bankAccounts) === 0;
  const codes = map(bankAccounts, it => ({
    institutionCode: get(it, 'institutionCode'),
  }));
  const { institutionsLogo } = useInstitutionLogoOR(
    size(codes) > 0 ? codes : []
  );
  const nextStepAfterPlaid = get(location, 'state.fromSummary')
    ? nextStepFromSummary
    : nextStep;
  const onClickMakeTransfer = history =>
    history.push('/create-account/step-select-account');
  if (loadingSearch) {
    return <SpinnerLoader />;
  }

  const togglePncTooltip = bool => {
    setPncTooltipVisible(bool);
  };

  const plaidButton = (
    <>
      <PlaidButton
        isOpen={
          isEmptyBankAccounts &&
          !isFetchingBankAccounts &&
          !isFetchedCreateClient &&
          !isFetchingCreateClient &&
          !error.active &&
          !errorClient.active &&
          startToken
        }
        token={startToken}
        className="create-account__button"
        onSuccess={plaidResponse => {
          SelectedBankAccounts({
            plaidResponse,
            history,
            client,
            tenant,
            action,
            onGetPlaidCreateClient,
            onGetPlaidStartMicrodepoits,
            onGetPlaidVerifyMicrodepoits,
            onGetBankAccounts,
            nextStep: nextStepAfterPlaid,
            onSetError,
            onSetErrorClient,
            onSetLoadingService,
            handleClose,
            setValue,
          });
        }}
      >
        Find My Bank
      </PlaidButton>
    </>
  );

  return (
    <>
      <BoxContainer
        widest={widest}
        withLogButton={withLogButton}
        withDoubleBorder={withDoubleBorder}
      >
        <Title isSelectAccount={isSelectAccount} />
        {isSelectAccount ? <Subtitle disbursement={disbursement} /> : null}
        {isFetchingCreateClient ||
        loadingService ||
        isFetchingBankAccounts ||
        isFetchingDeleteBankAccount ||
        isVerifyingAndLinking ? (
          <>
            <SpinnerLoader />

            {isVerifyingAndLinking && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '16px',
                  color: '#333',
                  marginBottom: '10px',
                  marginTop: '-20px',
                }}
              >
                Linking and Verifying Account...
              </div>
            )}
          </>
        ) : (
          <BankAccountsList
            bankAccounts={bankAccounts}
            isEmptyBankAccounts={isEmptyBankAccounts}
            onDisconnectBankAccount={onDisconnectBankAccount}
            isSelectAccount={isSelectAccount}
            action={action}
            state={state}
            register={register}
            onReloadBankAccounts={onReloadBankAccounts}
            institutionsLogo={institutionsLogo}
            onSetError={onSetError}
            watch={watch}
            control={control}
            setValue={setValue}
            client={client}
            tenant={tenant}
            reloadingBankAccounts={reloadingBankAccounts}
          />
        )}
        <div
          className={!mobile ? 'pnc' : null}
          style={{ display: 'row !important' }}
        >
          <PaymintsButton
            onClick={() => togglePncTooltip(true)}
            noShadow
            className={`bank-accounts__button bank-accounts__link`}
            type={BUTTON_TYPE.INVERSE_BLUE}
            disabled={
              isFetchingCreateClient || loadingService || isVerifyingAndLinking
            }
          >
            + Add Account
          </PaymintsButton>

          <div style={{ position: 'relative' }}>
            <AddAccountTooltip
              dismissHandler={togglePncTooltip}
              isVisible={pncTooltipVisible}
              plaidButton={plaidButton}
            />
          </div>
          <div hidden={isEmptyBankAccounts}>
            <PaymintsButton
              hidden={isSelectAccount}
              className="create-account__button"
              noShadow
              disabled={isVerifyingAndLinking || !hasVerifiedAccount}
              type={BUTTON_TYPE.INVERSE_BLUE}
              onClick={() => onClickMakeTransfer(props.history)}
            >
              {'Make Transfer'}
            </PaymintsButton>
          </div>
          {children}
          {error.active && (
            <ErrorMessageInput>{error.messageError}</ErrorMessageInput>
          )}
          {errorClient.active && (
            <ErrorMessageInput>{errorClient.messageError}</ErrorMessageInput>
          )}
        </div>
      </BoxContainer>
    </>
  );
};
export default withRouter(
  connect(
    state => ({
      account: state.account,
    }),
    {
      onGetBankAccounts: getClientAccounts,
      onDisconnectBankAccount: disconnectBankAccount,
      onGetPlaidCreateClient: getPlaidCreateClient,
      onGetPlaidStartMicrodepoits: getPlaidStartMicrodepoits,
    }
  )(BankAccounts)
);
