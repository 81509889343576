import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GET_REALTORS_FROM_TENANT_QUERY = gql`
  query AllRealtors($tenantId: ID!) {
    allRealtors(where: { tenant: { id: $tenantId }, active: true }) {
      id
      email
      mobilePhone
      active
      firstName
      lastName
    }
  }
`;

export default function useAllRealtorsFromTenant(tenantId) {
  const { loading, error, refetch, data } = useQuery(
    GET_REALTORS_FROM_TENANT_QUERY,
    {
      variables: { tenantId },
      fetchPolicy: 'no-cache',
    }
  );
  return {
    loading,
    error,
    refetch,
    realtors: data ? data.allRealtors : undefined,
  };
}
