import { createStore, applyMiddleware, compose  } from 'redux';
import rootReducer from '../reducers/RootReducer';
import thunk from 'redux-thunk';

const ConfigureStore = (preLoadedState = {}) => {
    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(thunk),
    );

    const store = createStore(
        rootReducer,
        preLoadedState,
        enhancer
    );

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept('../reducers/RootReducer', () => {
                store.replaceReducer(rootReducer)
            })
        }
    }

    return store
};

export default ConfigureStore;
