import ApolloClient from "apollo-boost";
import axios from "axios";
import APIDomain from "./apiSettings";

export const client = new ApolloClient({
  uri: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: process.env.REACT_APP_AUTH_TOKEN
      ? `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`
      : "",
  },
});

axios.defaults.baseURL = APIDomain;

export const getBaseClient = () =>
  axios.create({
    baseURL: APIDomain,
  });
