import React from 'react';
import BoxContainer from '../BoxContainer/BoxContainer';
import { useForm, Controller } from 'react-hook-form';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../CreateAccountWizard/updateAction';
import SsnToggleVisibilityField from '../SsnInput/SsnToggleVisibilityInput';
import { useUpdateClient } from '../../utils/service/service_user_api';
import {
  saveSensitiveData,
  getSensitiveData,
} from '../../utils/service/service_api';
import { useSensitiveData } from '../../contexts/sensitiveDataContext';

import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import { validSSN, validAge } from '../../utils/validations';
import { Select } from '../Select/Select';
import { states } from '../../utils/constants';
import '../CreateAccountWizard/createAccount.css';
import { encrypt } from '../../utils/encryption';

const Title = ({ client }) => (
  <div className="create-account__line-container">
    <div className="create-account__title">
      Your account has been verified, {get(client, 'firstName')}.
    </div>
  </div>
);

const InfoSSN = () => (
  <div className="create-account__line-container">
    <div className="create-account__info-account">
      Now we need to verify your social security number and date of birth, and
      then we'll be ready to connect to your bank.
    </div>
  </div>
);

const FieldSSN = ({ register, errors, control }) => {
  return (
    <div className="create-account__information-container">
      <BoxContainer withBorder internalBox>
        <div className="form-group">
          <label>Last 4 Digits of Social Security Number</label>
          <Controller
            as={SsnToggleVisibilityField}
            control={control}
            name="ssn"
            placeholder="Social Security Number"
            className="create-account__input form-control emd-landing-page__form-field--password account__control_width"
            rules={{ required: true, validate: validSSN }}
          />
          {errors.ssn && errors.ssn.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
          {errors.ssn && errors.ssn.type === 'validate' && (
            <ErrorMessageInput>
              Must be a valid social security number
            </ErrorMessageInput>
          )}
        </div>
      </BoxContainer>
    </div>
  );
};

const FieldBirthday = ({ register, errors }) => {
  return (
    <div className="create-account__information-container">
      <BoxContainer withBorder internalBox>
        <div className="form-group">
          <label>Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            className="create-account__input form-control account__control_width"
            placeholder="yyyy-mm-dd"
            ref={register({ required: true, validate: validAge })}
          />
          {errors.dateOfBirth && errors.dateOfBirth.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
          {errors.dateOfBirth && errors.dateOfBirth.type === 'validate' && (
            <ErrorMessageInput>Must be at least 18 years old</ErrorMessageInput>
          )}
        </div>
      </BoxContainer>
    </div>
  );
};

const FieldEmail = ({ register, errors }) => {
  return (
    <div className="create-account__information-container">
      <BoxContainer withBorder internalBox>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            className="create-account__input form-control account__control_width"
            placeholder="Email Address"
            ref={register({ maxLength: 50, required: true })}
          />
          {errors.email && errors.email.type === 'maxLength' && (
            <ErrorMessageInput>Max length exceeded</ErrorMessageInput>
          )}
          {errors.email && errors.email.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
        </div>
      </BoxContainer>
    </div>
  );
};

const FieldsPersonalInformation = ({ register, errors, control }) => {
  return (
    <div className="create-account__information-container">
      <BoxContainer withBorder internalBox>
        <div className="form-group">
          <label>Address Associated with Bank Account</label>
          <input
            type="text"
            name="address1"
            placeholder="Address 1"
            className="create-account__input form-control create-account__address-info"
            autoComplete="no"
            ref={register({ required: true })}
          />
          {errors.address1 && errors.address1.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
          <input
            type="text"
            name="address2"
            placeholder="Address 2"
            className="create-account__input form-control create-account__address-info"
            autoComplete="no"
            ref={register}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            className="create-account__input form-control create-account__address-info"
            autoComplete="no"
            ref={register({ required: true })}
          />
          {errors.city && errors.city.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
          <Controller
            as={Select}
            control={control}
            name="state"
            rules={{ required: true }}
            options={states}
            placeholderText="State"
            className="create-account__input form-control create-account__address-info"
          />
          {errors.state && errors.state.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
          <input
            type="text"
            name="zipcode"
            placeholder="Zip code"
            className="create-account__input form-control create-account__address-info"
            autoComplete="no"
            ref={register({ required: true })}
          />
          {errors.zipcode && errors.zipcode.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
        </div>
      </BoxContainer>
    </div>
  );
};

const FooterInformation = props => {
  return (
    <div className="create-account__footer create-account__information-container-footer">
      <div className="create-account__security-icon">
        <i className="fa fa-lock create-account__icon" />
      </div>
      <div className="create-account__information-text">
        {`By clicking Verify you authorize ${props.props.tenant.name}, by electronic signature, to securely connect to your financial institution.`}
      </div>
    </div>
  );
};

const nextStep = history => {
  history.push(`/bank-accounts`);
  window.location.reload();
};

const SecondStep = props => {
  const clientId = get(props, 'client.id');
  const tenantId = get(props, 'tenant.id');

  const intialform = { ...props.client, email: null };
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: intialform,
  });
  const { action } = useStateMachine(updateAction);
  const { updateClient } = useUpdateClient();
  const { sensitiveData, refetch: refetchSensitiveData } = useSensitiveData();
  const onSubmit = async newUser => {
    const newUserWithoutSsnDash = {
      ...newUser,
      ssn: newUser.ssn.replace(/-/g, ''),
    };

    const { ssn, dateOfBirth, ...userValues } = newUserWithoutSsnDash;

    const sensitiveData = {
      SSN: encrypt(ssn),
      DOB: encrypt(dateOfBirth),
    };
    try {
      await saveSensitiveData(clientId, tenantId, sensitiveData);
      const dataSENS = await getSensitiveData(clientId, tenantId);
      await updateClient({
        variables: {
          id: clientId,
          client: userValues,
        },
      });

      await props.refetch();
      await refetchSensitiveData();
      await action({ client: newUser });
      nextStep(props.history);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BoxContainer withDoubleBorder withLogButton>
      <Title client={props.client} />
      <InfoSSN />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldSSN register={register} errors={errors} control={control} />
        <FieldBirthday register={register} errors={errors} />
        <FieldEmail register={register} errors={errors} />
        <FieldsPersonalInformation
          register={register}
          errors={errors}
          control={control}
        />
        <FooterInformation props={props} />
        <PaymintsButton
          htmlType="submit"
          className="create-account__button create-account__verify"
          noShadow
          type={BUTTON_TYPE.INVERSE_BLUE}
        >
          {'Verify'}
        </PaymintsButton>
      </form>
    </BoxContainer>
  );
};

export default withRouter(connect(state => ({}), {})(SecondStep));
