import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import BoxContainer from '../BoxContainer/BoxContainer';
import { useForm, Controller } from 'react-hook-form';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import { useUpdateClient } from '../../utils/service/service_user_api';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import { useClientByPhone } from '../../utils/service/service_user_api';
import SelectedBankAccounts from '../PlaidButton/SelectedBankAccount';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../CreateAccountWizard/updateAction';
import { getPlaidCreateClient } from '../../reducers/account/AccountActions';
import { getClientAccounts } from '../../reducers/account/AccountActions';
import { Select } from '../Select/Select';
import { states } from '../../utils/constants';
import { connect } from 'react-redux';
import { validateAccountinfo } from '../../utils/validations';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { getClientStore } from '../../utils/util';
import ContentModal from '../ContentModal/ContentModal';

const buildLoaderModal = () => (
  <div className="create-account__modal">
    <div className="create-account__modal-loading">
      <SpinnerLoader withoutMargin />
    </div>
    <div className="create-account__modal-info">Loading please wait...</div>
  </div>
);

const AccountError = props => {
  const { action } = useStateMachine(updateAction);
  const [isLoading, onSetLoadingService] = useState(false);
  const initialSate = {
    plaidInfoToVerify: {
      address1: '',
      city: '',
      firstName: '',
      lastName: '',
      zipcode: '',
      state: '',
    },
    accessToken: '',
  };
  const { state } = props.history.location;
  let { plaidInfoToVerify } = state || initialSate;
  const { plaidResponse, accessToken } = state || initialSate;
  const accountRequest = {
    accessToken,
    plaidAccountId: plaidResponse.metadata.account.id,
    verified: 'true',
    manual: 'true',
  };
  //plaidResponse.metadata.account.id
  const { updateClient } = useUpdateClient();
  const { user } = getClientStore();
  const { client } = useClientByPhone(user.mobilePhone);
  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: client,
  });
  const onSubmit = newUser => {
    updateClient({
      variables: {
        id: client.id,
        client: newUser,
      },
    }).then(() => {
      delete accountRequest.manual;
      onSetLoadingService(true);
      SelectedBankAccounts({
        plaidResponse,
        history: props.history,
        client,
        action,
        setValue,
        onGetPlaidCreateClient: props.onGetPlaidCreateClient,
        onGetBankAccounts: props.onGetBankAccounts,
        verified: 'true',
        manual: 'true',
        onSetLoadingService,
        accountRequest,
        navigateAccounts: true,
      });
    });
  };
  return (
    <>
      <ContentModal
        isVisible={isLoading}
        content={buildLoaderModal()}
        small
        withoutButtons
        hideCloseButton
      />
      <BoxContainer widest withLogButton withDoubleBorder>
        <div className="create-account__line-container">
          <div className="create-account__title">Error</div>
        </div>
        <div className="create-account__line-container">
          <div className="create-account__subtitle with-margin">
            Your transfer is not complete.
          </div>
        </div>
        <div className="create-account__information-container create-account__no-margine">
          <div className="create-account__subtitle-information">
            The information you entered{' '}
            <span className="text text-danger"></span> does not match your bank
            account records. Please choose how you would like to proceed:
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="create-account__information-container">
            <BoxContainer internalBox withBorder>
              <div
                className="form-group"
                hidden={plaidInfoToVerify.firstName === client.firstName}
              >
                <label>You Entered First Name</label>
                <input
                  type="text"
                  placeholder=" "
                  id="firstName"
                  name="firstName"
                  className="create-account__input form-control account__control_width"
                  ref={register({
                    required: true,
                    maxLength: 30,
                    validate: value =>
                      validateAccountinfo(value, plaidInfoToVerify.firstName),
                  })}
                />
                {errors.firstName && errors.firstName.type === 'required' && (
                  <ErrorMessageInput>This is required</ErrorMessageInput>
                )}
                {errors.firstName && errors.firstName.type === 'validate' && (
                  <ErrorMessageInput>
                    Your Account details are not matching with bank details
                  </ErrorMessageInput>
                )}
                <div className="create-account__message-container">
                  <div className="create-account__bank-label">
                    Your bank account shows
                  </div>
                  <div className="create-account__user-label">
                    {plaidInfoToVerify ? plaidInfoToVerify.firstName : ''}
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                hidden={plaidInfoToVerify.lastName === client.lastName}
              >
                <label>You Entered Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  placeholder=" "
                  name="lastName"
                  className="create-account__input form-control account__control_width"
                  ref={register({
                    required: true,
                    maxLength: 30,
                    validate: value =>
                      validateAccountinfo(value, plaidInfoToVerify.lastName),
                  })}
                />
                {errors.lastName && errors.lastName.type === 'required' && (
                  <ErrorMessageInput>This is required</ErrorMessageInput>
                )}
                {errors.lastName && errors.lastName.type === 'validate' && (
                  <ErrorMessageInput>
                    Your Account details are not matching with bank details
                  </ErrorMessageInput>
                )}
                <div className="create-account__message-container">
                  <div className="create-account__bank-label">
                    Your bank account shows:
                  </div>
                  <div className="create-account__user-label">
                    {plaidInfoToVerify ? plaidInfoToVerify.lastName : ''}
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                hidden={plaidInfoToVerify.address1 === client.address1}
              >
                <label>You Entered Address1</label>
                <input
                  type="text"
                  name="address1"
                  placeholder=" "
                  className="create-account__input form-control account__control_width"
                  autoComplete="no"
                  ref={register({
                    required: true,
                    validate: value =>
                      validateAccountinfo(value, plaidInfoToVerify.address1),
                  })}
                />
                {errors.address1 && errors.address1.type === 'required' && (
                  <ErrorMessageInput>This is required</ErrorMessageInput>
                )}
                {errors.address1 && errors.address1.type === 'validate' && (
                  <ErrorMessageInput>
                    Your Account details are not matching with bank details
                  </ErrorMessageInput>
                )}
                <div className="create-account__message-container">
                  <div className="create-account__bank-label">
                    Your bank account shows:
                  </div>
                  <div className="create-account__user-label">
                    {plaidInfoToVerify ? plaidInfoToVerify.address1 : ''}
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                hidden={plaidInfoToVerify.city === client.city}
              >
                <label>You Entered city</label>
                <input
                  type="text"
                  name="city"
                  placeholder=" "
                  className="create-account__input form-control account__control_width"
                  autoComplete="no"
                  ref={register({
                    required: true,
                    validate: value =>
                      validateAccountinfo(value, plaidInfoToVerify.city),
                  })}
                />
                {errors.city && errors.city.type === 'required' && (
                  <ErrorMessageInput>This is required</ErrorMessageInput>
                )}
                {errors.city && errors.city.type === 'validate' && (
                  <ErrorMessageInput>
                    Your Account details are not matching with bank details
                  </ErrorMessageInput>
                )}
                <div className="create-account__message-container">
                  <div className="create-account__bank-label">
                    Your bank account shows:
                  </div>
                  <div className="create-account__user-label">
                    {plaidInfoToVerify ? plaidInfoToVerify.city : ''}
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                hidden={plaidInfoToVerify.state === client.state}
              >
                <label>You Entered state</label>
                <Controller
                  as={Select}
                  control={control}
                  name="state"
                  rules={{ required: true }}
                  options={states}
                  className="create-account__input form-control account__control_width"
                />
                <div className="create-account__message-container">
                  <div className="create-account__bank-label">
                    Your bank account shows:
                  </div>
                  <div className="create-account__user-label">
                    {plaidInfoToVerify ? plaidInfoToVerify.state : ''}
                  </div>
                </div>
                {errors.state && errors.state.type === 'required' && (
                  <ErrorMessageInput>This is required</ErrorMessageInput>
                )}
              </div>
              <div
                className="form-group"
                hidden={plaidInfoToVerify.zipcode === client.zipcode}
              >
                <label>You Entered zipcode</label>
                <input
                  type="text"
                  name="zipcode"
                  placeholder=" "
                  className="create-account__input form-control account__control_width"
                  autoComplete="no"
                  ref={register({
                    required: true,
                    validate: value =>
                      validateAccountinfo(value, plaidInfoToVerify.zipcode),
                  })}
                />
                <div className="create-account__message-container">
                  <div className="create-account__bank-label">
                    Your bank account shows:
                  </div>
                  <div className="create-account__user-label">
                    {plaidInfoToVerify ? plaidInfoToVerify.zipcode : ''}
                  </div>
                </div>
                {errors.zipcode && errors.zipcode.type === 'required' && (
                  <ErrorMessageInput>This is required</ErrorMessageInput>
                )}
                {errors.zipcode && errors.zipcode.type === 'validate' && (
                  <ErrorMessageInput>
                    Your Account details are not matching with bank details
                  </ErrorMessageInput>
                )}
              </div>
              <div className="create-account__footer-buttons create-account__mobile-transfer-footer-buttons">
                <PaymintsButton
                  className="mr-4 create-account__transfer-action-button mobile__transfer-action-button"
                  noShadow
                  type={BUTTON_TYPE.INVERSE_BLUE}
                  htmlType="submit"
                >
                  Save and Continue
                </PaymintsButton>
                <PaymintsButton
                  className="mr-4 create-account__transfer-action-button mobile__transfer-action-button"
                  noShadow
                  type={BUTTON_TYPE.LIGHT_BLUE}
                  onClick={() => {
                    onSetLoadingService(true);
                    SelectedBankAccounts({
                      plaidResponse,
                      history: props.history,
                      client,
                      action,
                      setValue,
                      onGetPlaidCreateClient: props.onGetPlaidCreateClient,
                      onGetBankAccounts: props.onGetBankAccounts,
                      verified: 'true',
                      manual: 'true',
                      onSetLoadingService,
                      accountRequest,
                      navigateAccounts: true,
                    });
                  }}
                >
                  Override
                </PaymintsButton>
              </div>
            </BoxContainer>
          </div>
        </form>
      </BoxContainer>
    </>
  );
};

export default withRouter(
  connect(
    state => ({
      account: state.account,
    }),
    {
      onGetBankAccounts: getClientAccounts,
      onGetPlaidCreateClient: getPlaidCreateClient,
    }
  )(AccountError)
);
