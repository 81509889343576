import React from 'react'

export function Input({ register, name, ...props }) {
  return <input name={name} ref={register} {...props} />
}

export function Select({ register, options, placeholderText, name, ...props }) {
  return (
    <select name={name} ref={register} {...props}>
      {placeholderText ? (
        <option value=''>
          {placeholderText}
        </option>
      ) : null}
      {options.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  )
}
