import React, { useState, useEffect } from 'react';
import BoxContainer from '../BoxContainer/BoxContainer';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import format from 'dateformat';
import { useStateMachine } from 'little-state-machine';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Select as AntSelect } from 'antd';
import updateAction from '../CreateAccountWizard/updateAction';
import {
  PaymintsButton,
  BUTTON_TYPE,
  PaymintsNumberFormat,
} from 'paymints-address-autocomplete-field';
import DestinationAccount from '../DestinationAccount/DestinationAccount';

import {
  useCreateTransfer,
  useSaveTransfer,
} from '../../utils/service/service_transfer_api';
import { useInstitutionLogo } from '../../utils/service/service_institution_logo';
import { saveTransferDoc } from '../../reducers/transfer/TransferActions';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import { validAmount } from '../../utils/validations';
import { extractNotesFromJson, formatDayFromUTC, getRealtorStore } from '../../utils/util';
import { useUpdateClient } from '../../utils/service/service_user_api';
import useEscrowAccountFromTenant from '../../utils/service/service_tenant_api';
import { Select } from '../Select/Select';
import { states } from '../../utils/constants';
import HeaderLogo from '../../styles/assets/lemon.png';
import AmountInput from '../AmountInput/AmountInput';
import useAllCreditTransferTypes from '../../hooks/useAllCreditTransferTypes';
import useAllActiveOfficesSorted from '../../hooks/useAllActiveOfficesSorted';
import useAllRealtorsFromTenant from '../../hooks/useAllRealtorsFromTenant';

import PhoneField from '../PhoneField/PhoneField';

import '../CreateAccountWizard/createAccount.css';

/**
 * [{amount: Number | null, clientPays: boolean, id: String, tenant: { name: String}}]
 * @param {*} data
 */
const extractTranferFeeFromData = data => {
  if (data && data.length > 0) {
    const [first] = data;
    if (first.clientPays) {
      return +first.amount || 0;
    }
    return 0;
  }
  return 0;
};

/**
 * @TODO extract out to component
 */
const Title = () => (
  <div className="create-account__line-container">
    <div className="create-account__title">Transfer Details</div>
  </div>
);

/**
 * @TODO extract out to component
 */
const InfoTransfer = () => (
  <div className="create-account__line-container">
    <div className="create-account__subtitle">
      What kind of transfer would you like to make?
    </div>
  </div>
);

/**
 * @TODO extract out to component
 */
const FieldsEMD = ({
  register,
  errors,
  control,
  realtorData,
  setRealtorData,
  showREAInfoFill,
}) => {
  return (
    <div
      className="create-account__emd-info"
      style={{ display: showREAInfoFill ? 'block' : 'none', }}
    >
      <div style={{ padding: "10px" }}>
        <div className="form-group" style={{ padding: "-28px" }}>
          <label>Real Estate Agent's Full Name (Optional)</label>
          <br />
          <input
            type="text"
            // id="otherIndividuals"
            // name="otherIndividuals"
            className="create-account__input form-control"
            placeholder=" "
            // ref={register}
            onChange={e =>
              setRealtorData({
                ...realtorData,
                fullName: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <label>Real Estate Agent's Email Address (Optional)</label>
          <br />
          <input
            type="text"
            id="realtorsEmail"
            name="otherIndividuals"
            className="create-account__input form-control"
            placeholder=" "
            // ref={register}
            onChange={e =>
              setRealtorData({
                ...realtorData,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <label>Real Estate Agent's Phone Number (Optional)</label>

          <div className="form-group create-account__name">
            <Controller
              as={PhoneField}
              control={control}
              name="otherIndividuals"
              placeholder=" "
              className="create-account__input form-control"
              rules={{ required: false }}
              onChange={e =>
                setRealtorData({
                  ...realtorData,
                  phoneNumber: e.toString(),
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * @TODO extract out to component
 */
const FieldsInfoTransfer = ({
  client,
  register,
  errors,
  control,
  setValue,
  purposes,
  emdVisible,
  setFieldsEMDVisible,
  realtorData,
  setRealtorData,
  showREAInfoFill,
}) => {
  const purposesOptions = purposes.map(purpose => ({
    value: purpose,
    label: purpose
  }))
  return (<>
    <div className="bank-accounts__item-title py-4">PURPOSE</div>
    <BoxContainer withBorder internalBox >
      <label>Select a  Purpose</label>
      <div
        className="form-group"
        style={{
          backgroundColor: '#fff',
          borderRadius: '4px',
          padding: '10px',
          marginTop: '10px',
        }}
      >
        <Controller
          as={AntSelect}
          control={control}
          name="purpose"
          rules={{ required: true }}
          options={purposesOptions}
          defaultValue="Earnest Money Deposit for Purchase"
          style={{
            boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
            border: '2px solid #c9d1f2',
            borderRadius: '4px',
            width: '100%',
          }}
          onSelect={value => value === "Earnest Money Deposit for Purchase" ? setFieldsEMDVisible(true) : setFieldsEMDVisible(false)}


        />
        {errors.purpose && errors.purpose.type === 'required' && (
          <div className="form-check create-account__error-message">

            <ErrorMessageInput>This is required</ErrorMessageInput>

          </div>)}
        {emdVisible ? (
          <FieldsEMD
            register={register}
            errors={errors}
            control={control}
            realtorData={realtorData}
            setRealtorData={setRealtorData}
            showREAInfoFill={showREAInfoFill}
          />
        ) : null}
      </div>

    </BoxContainer>
  </>
  );
};

/**
 * @TODO extract out to component
 */
const InfoPropertyAddress = () => (
  <div className="bank-accounts__item-title py-4">PROPERTY ADDRESS AND ADDITIONAL NOTES</div>
);

/**
 * @TODO extract out to component
 */
const FieldsInfoPropertyAddress = ({
  transfer,
  register,
  errors,
  setValue,
  control,
}) => {
  return (
    <BoxContainer withBorder internalBox>
      <div className="form-group">
        <label>Property Address Related To The Transfer</label>
        <input
          type="text"
          name="propertyAddress1"
          placeholder="Address 1"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register({ required: true })}
        />
        {errors.propertyAddress1 &&
          errors.propertyAddress1.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
        <input
          type="text"
          name="propertyAddress2"
          placeholder="Address 2"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register({ required: false })}
        />
        <input
          type="text"
          name="propertyCity"
          placeholder="City"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register({ required: true })}
        />
        {errors.propertyCity && errors.propertyCity.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
        <Controller
          as={Select}
          control={control}
          name="propertyState"
          placeholderText="State"
          rules={{ required: true }}
          options={states}
          className="create-account__input form-control create-account__address-info"
        />
        {errors.propertyState && errors.propertyState.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
        <input
          type="text"
          name="propertyZipcode"
          placeholder="Zip code"
          className="create-account__input form-control create-account__address-info"
          autoComplete="no"
          ref={register({ required: true })}
        />
        {errors.propertyZipcode &&
          errors.propertyZipcode.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
        <br />
        {((errors.propertyAddress1 &&
          errors.propertyAddress1.type === 'required') ||
          (errors.propertyCity && errors.propertyCity.type === 'required') ||
          (errors.propertyState && errors.propertyState.type === 'required') ||
          (errors.propertyZipcode &&
            errors.propertyZipcode.type === 'required')) && (
            <ErrorMessageInput>Please select a valid address</ErrorMessageInput>
          )}
        <br />
        <label>Notes (Optional)</label>
        <textarea
          name="notes"
          ref={register}
          placeholder=" "
          className="create-account__text-area form-control"
        />
      </div>
    </BoxContainer>
  );
};

/**
 * @TODO extract out to component
 */
const InfoAmount = () => (
  <div className="bank-accounts__item-title py-4">AMOUNT</div>
);

/**
 * @TODO extract out to component
 */
const FieldInfoAmount = ({
  register,
  errors,
  control,
  watch,
  watchDocument,
  regDocument,
  controlDocument,
  bankBalance,
  setMaxAmountValidate,
  maxAmountValidate,
  MAXIMUM_AMOUNT,
  transferFeeConfigData,
  getValues
}) => {
  return (
    <BoxContainer withBorder internalBox>
      <>

        <div className="account-blance">
          <div>Account Balance</div>
          {!bankBalance ? (
            '--'
          ) : (
            <div>
              <PaymintsNumberFormat
                number={bankBalance}
                decimals={2}
                format="currency"
              />
            </div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="form-group" style={{ width: "48%" }}>
            <label>Amount</label>
            {!bankBalance ? (
              <Controller
                as={AmountInput}
                control={control}
                name="amount"
                placeholder="$0.00"
                onKeyUp={() => setMaxAmountValidate(false)}
                className="create-account__input form-control"
                rules={{
                  required: true,
                }}
                autoComplete="no"
              />
            ) : (
              <Controller
                as={AmountInput}
                control={control}
                name="amount"
                placeholder="$0.00"
                onKeyUp={() => setMaxAmountValidate(false)}
                className="create-account__input form-control"
                rules={{
                  required: true,
                  validate: value =>
                    validAmount(
                      value,
                      bankBalance,
                      extractTranferFeeFromData(transferFeeConfigData)
                    ),
                }}
                autoComplete="no"
              />
            )}


            {errors.amount && errors.amount.type === 'required' && (
              <ErrorMessageInput>This is required</ErrorMessageInput>
            )}
            {errors.amount && errors.amount.type === 'validate' && (
              <ErrorMessageInput>
                Amount entered exceeds account balance and any applicable
                convenience fees. Please enter an amount lesser than or equal to
                this combined amount.
              </ErrorMessageInput>
            )}
            {maxAmountValidate && (
              <ErrorMessageInput>
                Transfer amount cannot exceed{' '}
                <PaymintsNumberFormat number={MAXIMUM_AMOUNT} format="currency" />{' '}
              </ErrorMessageInput>
            )}
          </div>
          <div className="form-group" style={{ width: "48%" }}>
            <label>Re-Enter Amount</label>
            <Controller
              as={AmountInput}
              control={control}
              name="amountCompare"
              placeholder="$0.00"
              className="create-account__input form-control"
              rules={{
                required: true,
                validate: {
                  matchesAmount: value => value === getValues('amount'),
                },
              }}
              onPaste={e => {
                e.preventDefault()
                return false
              }}
            />
            {errors.amount2 && errors.amount2.type === 'required' && (
              <ErrorMessageInput>Amount is required </ErrorMessageInput>
            )}
            {errors.amount2 && errors.amount2.type === 'matchesAmount' && (
              <ErrorMessageInput>Amounts do not match</ErrorMessageInput>
            )}


            {errors.amount && errors.amount.type === 'required' && (
              <ErrorMessageInput>This is required</ErrorMessageInput>
            )}
            {errors.amount && errors.amount.type === 'validate' && (
              <ErrorMessageInput>
                Amount entered exceeds account balance and any applicable
                convenience fees. Please enter an amount lesser than or equal to
                this combined amount.
              </ErrorMessageInput>
            )}
            {maxAmountValidate && (
              <ErrorMessageInput>
                Transfer amount cannot exceed{' '}
                <PaymintsNumberFormat number={MAXIMUM_AMOUNT} format="currency" />{' '}
              </ErrorMessageInput>
            )}
          </div>
        </div>
      </>
    </BoxContainer>

  );
};

const FieldOfficeSelect = ({ errors, control, tenantId, setValue }) => {
  const { activeOffices } = useAllActiveOfficesSorted(tenantId);
  const officeOptions = activeOffices.map(office => ({
    value: office.id,
    label: office.title + ' - ' + office.city + ', ' + office.state,
  }));

  return (
    <BoxContainer withBorder internalBox>
      <div>
        <div>
          <label>Select an Office to make a payment to</label>
        </div>
      </div>
      <Controller
        as={AntSelect}
        control={control}
        name="office"
        rules={{ required: true }}
        options={officeOptions}
        labelInValue={true}
        placeholder="Select an Office:"
        style={{
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
          border: '2px solid #c9d1f2',
          borderRadius: '4px',
          width: '100%',
        }}
      />
      {errors?.office && errors?.office?.type === 'required' && (
        <ErrorMessageInput>This is required</ErrorMessageInput>
      )}
    </BoxContainer>
  );
};

const RealtorSelect = ({ errors, setRealtorID, realtors, transfer }) => {
  const realtorsOptions = realtors?.map(realtor => ({

    value: `${realtor.firstName} ${realtor.lastName}`,
    label: `${realtor.firstName} ${realtor.lastName}`,
    key: realtor.id,
  }));

  const filteringArrayToGetID = fullName => {
    const splittedFirstAndLast = fullName.split(' ');
    const D = realtors?.filter(obj => {
      return (
        obj.firstName === splittedFirstAndLast[0] &&
        obj.lastName === splittedFirstAndLast[1]
      );
    });
    setRealtorID(D[0].id);
  };

  return (
    <BoxContainer withBorder internalBox>
      <div>
        <div>
          <label>Please select your real estate agent</label>
        </div>
      </div>

      <AntSelect
        placeholder="Select your Real Estate Agent"
        defaultValue={transfer.transfer ? `${transfer.transfer.realtor.firstName} ${transfer.transfer.realtor.lastName}` : null}
        onChange={filteringArrayToGetID}
        on
        rules={{ required: true }}
        options={realtorsOptions}
        style={{
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
          border: '2px solid #c9d1f2',
          borderRadius: '4px',
          width: '100%',
        }}
        showSearch
      />
      {errors?.realtor && errors?.realtor?.type === 'required' && (
        <ErrorMessageInput>This is required</ErrorMessageInput>
      )}
    </BoxContainer>
  );
};

const onClickBackButton = history =>
  history.push(
    `/create-account/step-select-account${get(history, 'location.search')}`
  );

const onClickGoToTransferHistory = history => history.push('/transfer-history');

const createDraftTransfer = ({
  props,
  destAccount,
  state,
  newTransfer,
  creditTransferTypes,
  transferFeeConfigData, // array
  realtorData,
}) => {
  // here's where we deal with the fee (this works)
  let fee = null;
  const [tfg] = transferFeeConfigData;
  if (tfg && tfg.clientPays) {
    fee = tfg.amount;
  }

  return {
    //...newTransfer,
    ...Object.assign(newTransfer, { fee }),
    expectedClosingDate: new Date(get(newTransfer, 'expectedClosingDate')),
    firstName: get(props.client, 'firstName'),
    lastName: get(props.client, 'lastName'),
    email: get(props.client, 'email'),
    status: 'draft',
    otherIndividuals: JSON.stringify(realtorData),
    transferType: {
      connect: {
        id: creditTransferTypes.filter(f => f.name === 'Client Transfer')[0].id,
      },
    },
    type: 'Client Transfer',
    client: {
      connect: {
        id: get(props, 'client.id'),
      },
    },
    sourceBankAccount: {
      connect: {
        id: get(state, 'data.bankAccount.id'),
      },
    },
    destBankAccount: {
      connect: {
        id: get(destAccount, 'id'),
      },
    },
    tenant: {
      connect: {
        id: get(props, 'tenant.id'),
      },
    },
    // office: {
    //   connect: {
    //     id: get(newTransfer, 'office.value'),
    //   },
    // },
  };
};

const showInfo = amount => {
  if (amount > '5000') {
    return true;
  }
  return false;
};

const getDefaultValues = props => {
  if (get(props, 'transfer.propertyAddress1')) {
    return {
      propertyAddress1: get(props, 'transfer.propertyAddress1'),
      propertyAddress2: get(props, 'transfer.propertyAddress2'),
      propertyState: get(props, 'transfer.propertyState'),
      propertyCity: get(props, 'transfer.propertyCity'),
      propertyZipcode: get(props, 'transfer.propertyZipcode'),
    };
  } else {
    if (
      get(props, 'transfer.purpose') === 'Earnest Money Deposit for Purchase'
    ) {
      return {
        propertyAddress1: get(props, 'client.address1'),
        propertyAddress2: get(props, 'client.address2'),
        propertyState: get(props, 'client.state'),
        propertyCity: get(props, 'client.city'),
        propertyZipcode: get(props, 'client.zipcode'),
      };
    } else {
      return {
        propertyAddress1: get(props, 'client.propertyAddress1'),
        propertyAddress2: get(props, 'client.propertyAddress2'),
        propertyState: get(props, 'client.propertyState'),
        propertyCity: get(props, 'client.propertyCity'),
        propertyZipcode: get(props, 'client.propertyZipcode'),
      };
    }
  }
};

/**
 * The primary component...ideally this should be the only component in this file.
 * @param {*} props
 */
const TransferDetails = props => {

  const expectedClosingDate = get(props, 'transfer.expectedClosingDate')
    ? formatDayFromUTC(get(props, 'transfer.expectedClosingDate'))
    : undefined;

  const { transferFeeConfigData } = props;

  let { realtors } = useAllRealtorsFromTenant(props.tenant.id);

  const { realtor } = getRealtorStore();
  // console.log(realtor)
  if (realtor && realtor.id.length === 24) {
    // Override realtors object as there is only 1 realtor
    // since coming from the realtor landing page
    realtors = [];
    realtors.push(realtor);
  }

  const { register, handleSubmit, errors, setValue, control, watch, getValues, } = useForm({
    defaultValues: {
      ...props.transfer,
      notes: extractNotesFromJson(props?.transfer?.notes),
      expectedClosingDate: expectedClosingDate,
      ...getDefaultValues(props),
    },
  });

  const valuesD = getValues()

  // PAYAPP-1193
  //Changed to 149999.99 by D.K No ticket assigned just a requestred Hotfix.
  const MAXIMUM_AMOUNT = 149999.99;
  const [maxAmountValidate, setMaxAmountValidate] = useState(false);
  const {
    register: regDocument,
    control: controlDocument,
    watch: watchDocument,
  } = useForm();
  const { state, action } = useStateMachine(updateAction);
  const [emdVisible, setFieldsEMDVisible] = useState(false);
  const [showREAInfoFill, setShowREAInfoFill] = useState(true);
  const [showAmountInfo, onShowAmountInfo] = useState(
    showInfo(watch('amount'))
  );
  const [realtorID, setRealtorID] = useState();
  const [realtorData, setRealtorData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
  });
  const { createTransfer } = useCreateTransfer();
  const { saveTransfer } = useSaveTransfer();
  const { updateClient } = useUpdateClient();
  const { destAccount } = useEscrowAccountFromTenant(
    get(props, 'tenant.id', '')
  );
  const { institutionLogo } = useInstitutionLogo(
    get(destAccount, 'institutionCode', '')
  );
  const transferId = get(props, 'transfer.id');
  const [error, onSetError] = useState({ active: false, messageError: '' });
  const {
    data: creditTransferTypes,
    loading: loadingCreditTransferTypes,
  } = useAllCreditTransferTypes();
  const [purposes, setPurposes] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  useEffect(() => {
    if (realtors && props && props.transfer) {
      const realtor = realtors.filter(obj => {
        return (
          obj.firstName === props.transfer.realtor?.firstName &&
          obj.lastName === props.transfer.realtor?.lastName
        );
      });
      setRealtorID(realtor[0]?.id);
    }

  }, [realtors, props]);

  useEffect(() => {
    if (creditTransferTypes?.length) {
      const purposes = JSON.parse(
        creditTransferTypes.filter(f => f.name === 'Client Transfer')[0].purpose
      ).purposes;
      setPurposes(purposes);
    }
    if (props?.tenant?.routingType === 'agent') {
      setShowREAInfoFill(false);
    }
  }, [creditTransferTypes]);

  const onSubmit = (newTransfer, saveAsDraft = false) => {
    delete newTransfer.amountCompare
    if (Number(watch('amount')) <= MAXIMUM_AMOUNT) {
      setMaxAmountValidate(false);
      const tenantId = get(props, 'tenant.id');
      const destAccountId = get(destAccount, 'id');
      const bankAccountId = get(state, 'data.bankAccount.id');

      if (newTransfer?.notes) {
        newTransfer.notes = JSON.stringify([{ note: newTransfer?.notes }]);
      }

      if (get(props, 'tenant.routingType') === 'office') {
        newTransfer = {
          ...newTransfer,
          office: {
            connect: {
              id: get(newTransfer, 'office.value'),
            },
          },
        };
      }
      if (get(props, 'tenant.routingType') === 'agent') {
        if (realtor && realtor.id.length === 24) {
          newTransfer = {
            ...newTransfer,
            realtor: {
              connect: {
                id: realtor.id,
              },
            },
          };
        } else {
          newTransfer = {
            ...newTransfer,
            realtor: {
              connect: {
                id: realtorID,
              },
            },
          };
        }
      } else if (realtor && realtor.id.length === 24) {
        newTransfer = {
          ...newTransfer,
          realtor: {
            connect: {
              id: realtor.id,
            },
          },
        };
      }

      if (isEmpty(tenantId))
        onSetError({
          active: true,
          messageError: 'Tenant not found, please check url',
        });
      if (isEmpty(destAccountId))
        onSetError({
          active: true,
          messageError: 'Destination account not found, please contact support',
        });
      if (isEmpty(bankAccountId))
        onSetError({
          active: true,
          messageError: 'Bank account not found, please select bank account',
        });
      if (
        !isEmpty(tenantId) &&
        !isEmpty(destAccountId) &&
        !isEmpty(bankAccountId)
      ) {
        const newTransferComplete = createDraftTransfer({
          props,
          destAccount,
          state,
          newTransfer,
          creditTransferTypes,
          transferFeeConfigData,
          realtorData,
        });

        if (transferId) {
          saveTransfer({
            variables: {
              id: props.transfer.id,
              transfer: newTransferComplete,
            },
          })
            .then(() => {
              const newUserAddress = {
                propertyAddress1: newTransfer.propertyAddress1,
                propertyAddress2: newTransfer.propertyAddress2,
                propertyState: newTransfer.propertyState,
                propertyCity: newTransfer.propertyCity,
                propertyZipcode: newTransfer.propertyZipcode,
              };

              updateClient({
                variables: {
                  id: get(props, 'client.id'),
                  client: newUserAddress,
                },
              });
            })
            .then(() => props.refetchTransfer())
            .then(() => {
              action({ transfer: newTransferComplete });
              if (saveAsDraft) {
                onClickGoToTransferHistory(props.history);
              } else {
                props.history.push(
                  `/create-account/step-4?go_to_transfer=${transferId}`
                );
              }
            });
        } else {
          createTransfer({ variables: { transfer: newTransferComplete } })
            .then(response => {
              const transferId = get(response, 'data.createTransfer.id');
              action({ transfer: newTransferComplete });
              if (!saveAsDraft) {
                props.history.push(
                  `/create-account/step-4?go_to_transfer=${transferId}`
                );
              } else {
                onClickGoToTransferHistory(props.history);
              }
            })
            .then(() => {
              const newUserAddress = {
                propertyAddress1: newTransfer.propertyAddress1,
                propertyAddress2: newTransfer.propertyAddress2,
                propertyState: newTransfer.propertyState,
                propertyCity: newTransfer.propertyCity,
                propertyZipcode: newTransfer.propertyZipcode,
              };

              updateClient({
                variables: {
                  id: get(props, 'client.id'),
                  client: newUserAddress,
                },
              });
            });
        }
      }
    } else {
      setMaxAmountValidate(true);
    }
  };

  return (
    <BoxContainer withDoubleBorder withLogButton>

      <Title />

      <form onSubmit={handleSubmit(e => onSubmit(e, false))}>
        <InfoTransfer />

        <FieldsInfoTransfer
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          purposes={purposes}
          emdVisible={emdVisible}
          setFieldsEMDVisible={setFieldsEMDVisible}
          realtorData={realtorData}
          setRealtorData={setRealtorData}
          client={get(props, 'client')}
          showREAInfoFill={showREAInfoFill}
        />
        <InfoPropertyAddress />
        <FieldsInfoPropertyAddress
          register={register}
          errors={errors}
          setValue={setValue}
          transfer={props.transfer}
          control={control}
        />
        <InfoAmount />
        <FieldInfoAmount
          errors={errors}
          control={control}
          showAmountInfo={showAmountInfo}
          onShowAmountInfo={onShowAmountInfo}
          watch={watch}
          watchDocument={watchDocument}
          regDocument={regDocument}
          controlDocument={controlDocument}
          maxAmountValidate={maxAmountValidate}
          setMaxAmountValidate={setMaxAmountValidate}
          MAXIMUM_AMOUNT={MAXIMUM_AMOUNT}
          bankBalance={get(state, 'data.bankAccount.balance')}
          transferFeeConfigData={transferFeeConfigData}
          getValues={getValues}
        />
        <DestinationAccount
          title="Destination Account:"
          logoURL={HeaderLogo}
          bankName={get(destAccount, 'bankName')}
          accountLastFourDigits={get(destAccount, 'accountLastFourDigits')}
          description={get(destAccount, 'bankDisplayName')}
          destAccountLogo={get(institutionLogo, 'logoURL')}
          tenantName={get(props, 'tenant.name')}
          address1={get(props, 'tenant.address1')}
          apartmentSuiteNumber={get(props, 'tenant.address2')}
          city={get(props, 'tenant.city')}
          state={get(props, 'tenant.state')}
          zipcode={get(props, 'tenant.zipcode')}
        />
        {get(props, 'tenant.routingType') === 'office' && (
          <FieldOfficeSelect
            control={control}
            errors={errors}
            tenantId={get(props, 'tenant.id')}
            setValue={setValue}
            labelInValue={false}
          />
        )}
        {get(props, 'tenant.routingType') === 'agent' && !realtor && (
          <RealtorSelect
            control={control}
            errors={errors}
            tenantId={get(props, 'tenant.id')}
            setValue={setValue}
            labelInValue={false}
            realtors={realtors}
            setRealtorID={setRealtorID}
            transfer={props}
          />
        )}
        <div className="create-account__footer-buttons create-account__mobile-transfer-footer-buttons">
          <PaymintsButton
            className="mr-4 create-account__transfer-action-button mobile__transfer-action-button"
            onClick={() => onClickBackButton(props.history)}
            noShadow
            type={BUTTON_TYPE.LIGHT_BLUE}
          >
            {'< Back'}
          </PaymintsButton>
          <PaymintsButton
            className="mr-4 create-account__transfer-action-button mobile__transfer-action-button"
            htmlType="submit"
            noShadow
            type={BUTTON_TYPE.INVERSE_BLUE}
            disabled={loadingCreditTransferTypes}
          >
            Review
          </PaymintsButton>
          {/* <PaymintsButton
            className="create-account__save-come-back-button create-account__transfer-action-button"
            onClick={handleSubmit(e => onSubmit(e, true))}
            noShadow
            type={BUTTON_TYPE.LINK}
          >
            {'Save & Come Back Later'}
          </PaymintsButton> */}
          {error.active && (
            <ErrorMessageInput>{error.messageError}</ErrorMessageInput>
          )}
        </div>

      </form>
    </BoxContainer>
  );
};

export default withRouter(
  connect(state => ({}), {
    onSaveTransferDoc: saveTransferDoc,
  })(TransferDetails)
);
