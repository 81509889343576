import React from 'react'
import FormatAddress from '../FormatAddress/FormatAddress'
import './destinationAccount.css'

export default function DestinationAccount({
  title = 'Destination Account:',
  bankName,
  accountLastFourDigits,
  description,
  destAccountLogo,
  tenantName,
  address1,
  apartmentSuiteNumber,
  name,
  city,
  state,
  zipcode,
  office,
  logoURL,
  children,
  type,
}) {
  return (
    <div className='destination-account__destination-account'>
      <div className='destination-account__destination-account-title'>
        {title}
      </div>

      <div className='destination-account__destination-account-container'>
        {/*
        <div className="destination-account__destination-account-icons">
          {type === "from" && (
            <div className="destination-account__destination-account-icon">
              <img
                className="destination-account__destination-account-icon-logo"
                src={destAccountLogo}
                alt=""
              />
            </div>
          )}

          <div className="destination-account__destination-account-icon">
            <img
              className="destination-account__destination-account-icon-logo"
              src={logoURL}
              alt=""
            />
          </div>
        </div>*/}

        {type === 'from' ? (
          <div className='destination-account__destination-account-info'>
            {bankName}
            <br />
            Account X{accountLastFourDigits}
          </div>
        ) : (
          <div className='destination-account__destination-account-info'>
            <p>{tenantName}</p>
            <FormatAddress
              streetNumberName={address1}
              apartmentSuiteNumber={apartmentSuiteNumber}
              city={city}
              state={state}
              zipcode={zipcode}
            />
            {office}
          </div>
        )}
      </div>
      {children}
    </div>
  )
}
