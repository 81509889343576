import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

const GET_INSTITUTION_LOGO_QUERY = gql`
  query allInstitutionLogos($institutionCode: String) {
    allInstitutionLogos(where: { institutionCode: $institutionCode }) {
      id
      logoURL
    }
  }
`;

export function useInstitutionLogo(institutionID) {
  const id =
    isNil(institutionID) || isEmpty(institutionID) ? null : institutionID;
  const { loading, error, data, refetch } = useQuery(
    GET_INSTITUTION_LOGO_QUERY,
    { variables: { institutionCode: id } }
  );
  return {
    loading,
    error,
    refetch,
    institutionLogo: data ? data.allInstitutionLogos[0] : undefined,
  };
}

const GET_INSTITUTION_LOGO_OR_QUERY = gql`
  query allInstitutionLogos($institution: [InstitutionLogoWhereInput]) {
    allInstitutionLogos(where: { OR: $institution }) {
      id
      logoURL
      institutionCode
    }
  }
`;

export function useInstitutionLogoOR(institutionID) {
  const institution =
    isNil(institutionID) || isEmpty(institutionID)
      ? [{ institutionCode: null }]
      : institutionID;
  const { loading, error, data, refetch } = useQuery(
    GET_INSTITUTION_LOGO_OR_QUERY,
    {
      variables: { institution: institution },
    }
  );
  return {
    loading,
    error,
    refetch,
    institutionsLogo: data ? data.allInstitutionLogos : [],
  };
}
