import React, { useState } from 'react';
import BankAccounts from '../BankAccounts/BankAccounts';

const BankAccountsHistory = props => {
  const [loadingService, onSetLoadingService] = useState(false);
  return (
    <BankAccounts
      withDoubleBorder
      withLogButton
      widest
      loadingService={loadingService}
      onSetLoadingService={onSetLoadingService}
      client
      tenant
      {...props}
    />
  );
};

export default BankAccountsHistory;
