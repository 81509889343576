export const PLAID_CREATE_CUSTOMER_FETCH = "PLAID_CREATE_CUSTOMER_FETCH";
export const PLAID_CREATE_CUSTOMER_SUCCESS = "PLAID_CREATE_CUSTOMER_SUCCESS";
export const PLAID_CREATE_CUSTOMER_ERROR = "PLAID_CREATE_CUSTOMER_ERROR";
export const GET_BANK_ACCOUNTS_FETCH = "GET_BANK_ACCOUNTS_FETCH";
export const GET_BANK_ACCOUNTS_SUCCESS = "GET_BANK_ACCOUNTS_SUCCESS";
export const GET_BANK_ACCOUNTS_ERROR = "GET_BANK_ACCOUNTS_ERROR";
export const DELETE_BANK_ACCOUNT_FETCH = "DELETE_BANK_ACCOUNT_FETCH";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_ERROR = "DELETE_BANK_ACCOUNT_ERROR";
export const UPDATE_AUTH0_FETCH = "UPDATE_AUTH0_FETCH";
export const UPDATE_AUTH0_SUCCESS = "UPDATE_AUTH0_SUCCESS";
