import React from 'react';
import MaskedInput from 'react-text-mask'

const phoneMask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d?/];
const codeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export default ({ ...props }) => {
    const handleChange = (e) => props.onChange(`${e.target.value}`.replace(/[^\d]/g, ""));
    return (
        <MaskedInput
            mask={phoneMask}
            {...props}
            onChange={handleChange}
        />
    );
};
export const VerificationCode = ({ ...props }) => {
    const handleChange = (e) => props.onChange(`${e.target.value}`.replace(/[^\d]/g, ""));
    return (
        <MaskedInput
            mask={codeMask}
            {...props}
            onChange={handleChange}
        />
    );
};