import React from 'react';

export default function FieldRadioButton({
  name,
  action,
  handleChange,
  checked,
  ...props
}) {
  return (
    <input
      className="bank-accounts__checkbox"
      type="radio"
      name="selection"
      {...props}
      onChange={e => handleChange(e)}
      checked={checked ? 'checked' : null}
    />
  );
}
