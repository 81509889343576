import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const CREATE_USER_QUERY = gql`
  mutation createClient($client: ClientCreateInput!) {
    createClient(data: $client) {
      id
      email
      mobilePhone
    }
  }
`;

export function useCreateClient() {
  const [createClient, { loading, error, data }] = useMutation(
    CREATE_USER_QUERY
  );

  return {
    createClient,
    loading,
    error,
    clientPhone: data ? data.createClient : undefined,
  };
}

const UPDATE_USER_QUERY = gql`
  mutation updateClient($id: ID!, $client: ClientUpdateInput!) {
    updateClient(id: $id, data: $client) {
      id
      email
    }
  }
`;

export function useUpdateClient() {
  const [updateClient, { loading, error, data }] = useMutation(
    UPDATE_USER_QUERY
  );
  return {
    updateClient,
    loading,
    error,
    client: data ? data.Client : undefined,
  };
}

const GET_CUSTOMER_QUERY = gql`
  query allClients($where: ClientWhereInput!) {
    allClients(where: $where) {
      id
      email
      firstName
      middleName
      lastName
      mobilePhone
      address1
      address2
      city
      state
      zipcode
      propertyAddress1
      propertyAddress2
      propertyCity
      propertyState
      propertyZipcode
      active
      updatedAt
      createdAt
      ssn
      tenant {
        id
        name
        tenantGroup {
          id
          groupName
          logoURL
          groupTagline
          bannerColor
        }
      }
    }
  }
`;

export function useClient(emailID) {
  const email = isNil(emailID) || isEmpty(emailID) ? null : emailID;
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_QUERY, {
    variables: { where: { email: email } },
  });
  return {
    loadingSearch: loading,
    error,
    refetch,
    client: data ? data.allClients[0] : undefined,
  };
}

export function useClientByPhone(mobileNumber) {
  const phone =
    isNil(mobileNumber) || isEmpty(mobileNumber) ? '' : mobileNumber;
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_QUERY, {
    variables: { where: { mobilePhone: phone } },
  });
  return {
    loadingSearch: loading,
    error,
    refetch,
    client: data ? data.allClients[0] : undefined,
  };
}

const GET_ALL_CLIENT_DOCUMENT_QUERY = gql`
  query allClientDocuments($where: ClientDocumentWhereInput!) {
    allClientDocuments(where: $where) {
      id
      type
      description
      filePath
      fileURL
      status
      active
      transfer {
        id
      }
    }
  }
`;

export function useAllClientDocuments(clientID) {
  const id = isNil(clientID) || isEmpty(clientID) ? null : clientID;
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_CLIENT_DOCUMENT_QUERY,
    {
      variables: { where: { client: { id } } },
    }
  );
  return {
    loadingClientDoc: loading,
    errorClientDoc: error,
    refetchClientDoc: refetch,
    clientDocuments: data ? data.allClientDocuments : [],
  };
}
