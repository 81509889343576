import * as actionType from "./AssetActionTypes";

const initialState = {
    uploadDocument: {
        document: {},
        isFetchedUploadDocument: false,
        isFetchingUploadDocument: false,
        errorUploadDocument: false
    },
};

const assetReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionType.UPLOAD_DOCUMENT_FETCH:
            return {
                ...state,
                uploadDocument: {
                    isFetchingUploadDocument: true,
                    isFetchedUploadDocument: false,
                    errorUploadDocument: false
                }
            };
        case actionType.UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadDocument: {
                    isFetchingUploadDocument: false,
                    isFetchedUploadDocument: true,
                    errorUploadDocument: false,
                    document: payload.response
                }
            };
        case actionType.UPLOAD_DOCUMENT_ERROR:
            return {
                ...state,
                uploadDocument: {
                    isFetchingUploadDocument: false,
                    isFetchedUploadDocument: false,
                    errorUploadDocument: true,
                    document: payload.response
                }
            };
        default:
            return state;
    }
};

export default assetReducer;
