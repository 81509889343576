/*
 * MobileHistory.jsx
 * Description: Transaction history listing for mobile
 */
import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import format from 'dateformat';
import {
  PaymintsNumberFormat,
  PaymintsButton,
  PaymintsHarveyBall,
  BUTTON_TYPE,
  BALL_TYPE,
} from 'paymints-address-autocomplete-field';

import downloadPdfPromise from '../../../utils/service/service_pdf-download';
import { shouldHideDocuments } from '../../../utils/validations';
import {
  asyncWrapper,
  formatFromDisplay,
  formatToDisplay,
} from '../../../utils/util';

import './MobileHistory.css';

/**
 * @function
 * PDF opener
 * @param {*} url
 */
const openPdf = async pdfUrl => {
  const [err, data] = await asyncWrapper(downloadPdfPromise(pdfUrl));

  if (err) {
    return;
  }

  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

/**
 * @TODO move to isolated component and basically refactor
 * @param {*} param0
 */
const DocumentSection = ({ transfer, clientDocuments, hidden }) => {
  const id = get(transfer, 'id');

  const documents = filter(
    clientDocuments,
    doc => get(doc, 'transfer.id') === id
  );

  const receipt = get(find(documents, ['type', 'receipt']), 'fileURL');

  const escrowAgreement = get(
    find(documents, ['type', 'escrow agreement']),
    'fileURL'
  );

  if (documents.length === 0) {
    return null;
  }

  return (
    <div hidden={hidden}>
      <div className="document-section">
        {escrowAgreement || receipt ? (
          <div className="mobileSec bgSec clearfix">
            <p className="document-header">DOCUMENTS</p>
            <div className="contentSec_details f-left">
              {escrowAgreement ? (
                <p>
                  <i className="far fa-file-pdf transfer-history__pdf-icon" />
                  <a
                    href={escrowAgreement}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="paymints__link"
                  >
                    View Escrow Agreement
                  </a>
                </p>
              ) : null}
              {receipt ? (
                <p onClick={() => openPdf(receipt)}>
                  <i className="far fa-file-pdf transfer-history__pdf-icon" />
                  <a
                    href="#"
                    rel="noopener noreferrer"
                    className="paymints__link"
                  >
                    View Receipt
                  </a>
                </p>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

/**
 * @TODO refactor to separate component...no internal component definitions
 * Don't understand why it's called UpdateUI when it's a mobile transfer card block
 * @param {*} param0
 */
const UpdateUI = ({ transfer, getStatusType, clientDocuments, tenant }) => {
  const id = get(transfer, 'id');
  const status = get(transfer, 'status');
  const dateFormat = 'mm/dd/yyyy hh:MM TT';
  const updatedAt = format(get(transfer, 'updatedAt'), dateFormat);
  const createdAt = format(get(transfer, 'createdAt'), dateFormat);
  const propertyAddress1 = get(transfer, 'propertyAddress1');
  const propertyAddress2 = get(transfer, 'propertyAddress2');
  const propertyCity = get(transfer, 'propertyCity');
  const propertyState = get(transfer, 'propertyState');
  const propertyZipcode = get(transfer, 'propertyZipcode');
  const amount = get(transfer, 'amount');
  const disbursement = get(transfer, 'disbursement');

  return (
    <>
      {disbursement && status === 'draft' ? null : (
        <>
          {!disbursement ? (
            <div className="transfer-card">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '6px',
                }}
              >
                <PaymintsHarveyBall type={getStatusType(status)} />
                <div
                  className="transfer-status"
                  style={{ padding: '0', margin: '4px 5px 0 0 ' }}
                >
                  {status}
                </div>
              </div>

              {/* <p className="transfer-status">{status}</p> */}
              <div className="flex-container">
                <div className="transfer-details">
                  {/* Date/Time */}
                  <span className="for">
                    <b>Date/Time</b>
                  </span>
                  <p className="date-time">{createdAt}</p>
                  {/* FROM */}
                  <span className="for">
                    <b>From</b>
                  </span>
                  <p
                    className="transfer-source-bank"
                    dangerouslySetInnerHTML={{
                      __html: formatFromDisplay(transfer, 'text'),
                    }}
                  />
                  <p className="date-time">Submitted {createdAt}</p>

                  <span className="for">
                    <b>Amount</b>
                  </span>
                  <p className="transfer-amount">
                    {!disbursement ? (
                      <>
                        (-
                        <PaymintsNumberFormat
                          className="text-dark"
                          number={amount}
                          decimals={2}
                          format="currency"
                        />
                        )
                      </>
                    ) : (
                      <PaymintsNumberFormat
                        className="text-success"
                        number={amount}
                        decimals={2}
                        format="currency"
                      />
                    )}
                  </p>

                  {/* FEE */}
                  <div
                    style={{
                      marginTop: '5px',
                      display: transfer?.fee ? 'block' : 'none',
                    }}
                  >
                    <span className="for">
                      <b>Fee</b>
                    </span>
                    <p className="transfer-amount">
                      (-
                      <PaymintsNumberFormat
                        className="text-dark"
                        number={transfer?.fee}
                        decimals={2}
                        format="currency"
                      />
                      )
                    </p>
                  </div>

                  {/* TO */}
                  <div className="transfer-parties">
                    <p>
                      <span className="for">To</span>
                      <br />
                      {formatToDisplay(transfer, 'text')}
                    </p>
                    <p>
                      <span className="for">Property Address</span>
                      <br />
                      {propertyAddress1}
                      {propertyAddress2 ? (
                        <>
                          <br />
                          {propertyAddress2}
                        </>
                      ) : (
                        ''
                      )}
                      <br />
                      {propertyCity}, {propertyState} {propertyZipcode}
                    </p>
                    <p>
                      {status === 'sent' && transfer?.disbursement ? (
                        <PaymintsButton
                          htmlType="submit"
                          className="mr-4 mt-3 create-account__transfer-action-button"
                          noShadow
                          type={BUTTON_TYPE.INVERSE_BLUE}
                        >
                          <Link
                            id="sent"
                            to={`/create-account/step-select-account?go_to_transfer=${id}?disbursement=select-bank`}
                          >
                            Accept Payment
                          </Link>
                        </PaymintsButton>
                      ) : null}
                    </p>
                  </div>
                </div>
              </div>
              <DocumentSection
                hidden={shouldHideDocuments(status)}
                transfer={transfer}
                clientDocuments={clientDocuments}
              />
            </div>
          ) : (
            <div className="transfer-card">
              <div style={{ display: 'flex', alignItems: 'end' }}>
                <PaymintsHarveyBall type={getStatusType(status)} />
                <div className="transfer-status">{status}</div>
              </div>
              <div className="flex-container">
                <div className="transfer-details">
                  <div className="transfer-parties">
                    {/* FROM */}
                    <p>
                      <span className="for">From</span>
                      <br />
                      {formatFromDisplay(transfer, 'text')}
                    </p>
                    <p className="date-time">Submitted {updatedAt}</p>
                    {/* TO */}
                    <div>
                      <span className="for">To</span>
                      <p
                        className="transfer-source-bank"
                        dangerouslySetInnerHTML={{
                          __html: formatToDisplay(transfer, 'text'),
                        }}
                      />
                      <p className="transfer-amount">
                        {!disbursement ? '-' : null}
                        <PaymintsNumberFormat
                          className={`${
                            disbursement ? 'text-success' : 'text-dark'
                          }`}
                          number={amount}
                          decimal={2}
                          format="currency"
                        />
                      </p>
                      <span className="for">Property Address</span>
                      <br />
                      {propertyAddress1}
                      {propertyAddress2 ? (
                        <>
                          <br />
                          {propertyAddress2}
                        </>
                      ) : (
                        ''
                      )}
                      <br />
                      {propertyCity}, {propertyState} {propertyZipcode}
                    </div>
                    <p>
                      {status === 'sent' && transfer?.disbursement ? (
                        <PaymintsButton
                          htmlType="submit"
                          className="mr-4 mt-3 create-account__transfer-action-button"
                          noShadow
                          type={BUTTON_TYPE.INVERSE_BLUE}
                        >
                          <Link
                            id="sent"
                            to={`/create-account/step-select-account?go_to_transfer=${id}?disbursement=select-bank`}
                          >
                            Accept Payment
                          </Link>
                        </PaymintsButton>
                      ) : null}
                    </p>
                  </div>
                </div>
              </div>
              <DocumentSection
                hidden={shouldHideDocuments(status)}
                transfer={transfer}
                clientDocuments={clientDocuments}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default function MobileHistory({
  transfers,
  institutionsLogo,
  clientDocuments,
  tenant,
}) {
  /**
   * @function
   * @param {*} option
   * @returns
   */
  const getStatusType = option => {
    switch (option.toLowerCase().trim()) {
      case 'draft':
        return BALL_TYPE.INACTIVE;
      case 'pending':
        return BALL_TYPE.PENDING;
      case 'submitted':
        return BALL_TYPE.ACTIVE;
      case 'sent':
        return BALL_TYPE.SENT;
      case 'viewed':
        return BALL_TYPE.VIEWED;
      case 'completed':
        return BALL_TYPE.COMPLETED;
      case 'cancelled':
        return BALL_TYPE.CANCELLED;
      case 'error':
        return BALL_TYPE.ERROR;
      default:
        return BALL_TYPE.INACTIVE;
    }
  };

  const transfersData = transfers || [];
  const transferCards = (transfers || []).map(transfer => (
    <React.Fragment key={transfer.id}>
      <UpdateUI
        key={get(transfer, 'id')}
        transfer={transfer}
        institutionsLogo={institutionsLogo}
        getStatusType={getStatusType}
        clientDocuments={clientDocuments}
        tenant={tenant}
      />
    </React.Fragment>
  ));
  return (
    <div className="mobile-history d-block d-lg-none d-xl-none">
      {transfersData.length ? (
        transferCards
      ) : (
        <p className="transfer-history__no-data">
          You have no pending or completed transfers, select the “+” button to
          start a transfer.
        </p>
      )}
    </div>
  );
}
