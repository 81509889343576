import * as APIUtil from '../../utils/service/service_api';
import * as actionType from './TransferActionType';
import get from 'lodash/get';

export const startDwollaFetch = () => ({
  type: actionType.START_DWOLLA_FETCH,
});

export const startDwollaSuccess = response => ({
  type: actionType.START_DWOLLA_SUCCESS,
  payload: { response },
});

export const startDwollaError = response => ({
  type: actionType.START_DWOLLA_ERROR,
  payload: { response },
});

export const startDwolla = (data, config) => dispatch => {
  dispatch(startDwollaFetch());
  return APIUtil.startDwolla(data, config)
    .then(response => {
      dispatch(startDwollaSuccess(response));
      return response;
    })
    .catch(error => {
      const errorElement = get(error, 'response.data');
      dispatch(startDwollaError(errorElement));
      const message =
        get(errorElement, 'error.body.message') ||
        get(errorElement, 'message') ||
        get(errorElement, 'error_message') ||
        'Something went wrong... please try again in a few minutes';
      return { error: true, message: message };
    });
};

export const saveTransferDocFetch = () => ({
  type: actionType.SAVE_TRANSFER_DOC_FETCH,
});

export const saveTransferDocSuccess = response => ({
  type: actionType.SAVE_TRANSFER_DOC_SUCCESS,
  payload: { response },
});

export const saveTransferDocError = response => ({
  type: actionType.SAVE_TRANSFER_DOC_ERROR,
  payload: { response },
});

export const saveTransferDoc = (transferId, data) => dispatch => {
  dispatch(saveTransferDocFetch());
  return APIUtil.saveTransferDoc(transferId, data)
    .then(response => {
      dispatch(saveTransferDocSuccess(response));
      return response;
    })
    .catch(error => {
      const errorElement = get(error, 'response.data');
      const message =
        get(errorElement, 'error.body.message') ||
        get(errorElement, 'err.message') ||
        get(errorElement, 'err') ||
        get(errorElement, 'message') ||
        get(errorElement, 'error_message') ||
        'Something went wrong... please try again in a few minutes';
      dispatch(saveTransferDocError(errorElement));
      return { error: true, message: message };
    });
};

export const sendEmailReceiptFetch = () => ({
  type: actionType.SEND_EMAIL_RECEIPT_FETCH,
});

export const sendEmailReceiptSuccess = response => ({
  type: actionType.SEND_EMAIL_RECEIPT_SUCCESS,
  payload: { response },
});

export const sendEmailReceiptError = error => ({
  type: actionType.SEND_EMAIL_RECEIPT_ERROR,
  payload: { error },
});

export const sendEmailReceipt = data => dispatch => {
  dispatch(sendEmailReceiptFetch());
  return APIUtil.sendEmailReceipt(data)
    .then(response => {
      console.log('hello', response);
      dispatch(sendEmailReceiptSuccess(response));
      return { success: true, ...response };
    })
    .catch(error => {
      const errorElement = get(error, 'response.data');
      dispatch(sendEmailReceiptError(error));
      const message =
        get(errorElement, 'error.body.message') ||
        get(errorElement, 'message') ||
        get(errorElement, 'error_message') ||
        'Something went wrong... please try again in a few minutes';
      console.log(message);
      return { error: true, message: message };
    });
};
