import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const GET_OFFICES_SORTED_QUERY = gql`
  query allOfficesSorted($id: ID!) {
    allOffices(
      where: { tenant: { id: $id }, active: true }
      sortBy: title_DESC
    ) {
      id
      title
      city
      state
    }
  }
`;

export default function useAllActiveOfficesSorted(tenantID) {
  const id = isNil(tenantID) || isEmpty(tenantID) ? null : tenantID;
  const { loading, error, data, refetch } = useQuery(GET_OFFICES_SORTED_QUERY, {
    variables: {
      id: id,
    },
  });

  // the filter is a hack because name_not_i excludes BOTH "Vendor Disbursement" and null (no bueno)
  return {
    loadingOffices: loading,
    error,
    refetchOffices: refetch,
    activeOffices: data ? data.allOffices : [],
  };
}
