import React, { Component } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./custom-tooltip.css";

export default class CustomTooltip extends Component {
  render = () => {
    const tooltipContent = (
      <Tooltip className={`paymints-tooltip--${this.props.type}`}>
        {this.props.popoverText}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltipContent}>
        {this.props.content}
      </OverlayTrigger>
    );
  };
}

CustomTooltip.propTypes = {
  popoverText: PropTypes.any,
  content: PropTypes.any,
  type: PropTypes.oneOf(["default", "blue"]),
};
