import React, { useCallback, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import './plaid-button.css';

export default function PlaidAddBankAccountButton({
  product,
  onSuccess,
  onExit,
  disabled,
  isOpen,
  isAddBank,
  token,
  config,
  ...props
}) {
  const onPlaidSuccess = useCallback(
    (token, metadata) => {
      if (typeof onSuccess !== 'function') return;
      onSuccess({ token, metadata });
    },
    [onSuccess]
  );

  config = config
    ? config
    : {
        clientName: 'Paymints.io',
        client_id: process.env.REACT_APP_PLAID_CLIENT_ID,
        env: process.env.REACT_APP_PLAID_ENVIRONMENT || 'sandbox',
        product: product || ['auth'],
        auth: {
          flow_type: 'FLEXIBLE_AUTH',
        },
        selectAccount: true,
        onSuccess: onPlaidSuccess,
        onExit,
        token: token,
      };

  const { error, open, ready } = usePlaidLink({ ...config });
  useEffect(() => {
    if (isOpen) {
      open();
    }
  }, [isOpen, open]);

  return (
    <>
      <PaymintsButton
        disabled={disabled || error || !ready}
        onClick={open}
        noShadow
        type={BUTTON_TYPE.INVERSE_BLUE}
        {...props}
      >
        {props.children}
      </PaymintsButton>
    </>
  );
}
