import get from 'lodash/get';
import find from 'lodash/find';

const SelectedBankAccount = ({
  plaidResponse,
  history,
  client,
  tenant,
  action,
  setValue,
  onGetPlaidCreateClient,
  onGetPlaidStartMicrodepoits,
  onGetBankAccounts,
  nextStep,
  onSetError,
  onSetErrorClient,
  onSetLoadingService,
  handleClose,
  verified,
  manual,
  accountRequest,
  navigateAccounts,
}) => {
  const bankAccounts = plaidResponse;
  const getAction = get(action);

  const clientID = get(client, 'id');
  const tenantID = get(tenant, 'id');
  let data = {};
  if (verified || manual) {
    data = {
      id: clientID,
      ...accountRequest,
    };
  } else {
    data = {
      id: clientID,
      plaidAccountId: get(bankAccounts, 'metadata.account.id'),
      publicToken: get(plaidResponse, 'token'),
    };
  }
  let status = bankAccounts.metadata?.account?.verification_status;
  let fetchServer = !status
    ? onGetPlaidCreateClient
    : onGetPlaidStartMicrodepoits;
  return fetchServer(data, tenantID).then(responsePlaid => {
    if (!get(responsePlaid, 'error')) {
      if (!manual) {
        onSetErrorClient({ active: false, messageError: '' });
      }
      setTimeout(() => {
        onGetBankAccounts(clientID)
          .then(response => {
            if (!get(response, 'error')) {
              const bankAccounts = get(response, 'accounts');
              const bankAccountSelectedID = get(responsePlaid, 'bankAccountId');
              const bankAccountSelected = find(bankAccounts, [
                'id',
                bankAccountSelectedID,
              ]);
              if (bankAccountSelected && action) {
                action({
                  bankAccount: {
                    id: get(bankAccountSelected, 'id'),
                    balance: get(bankAccountSelected, 'balance'),
                  },
                });
                setValue('selection', get(bankAccountSelected, 'id'));
              }
              if (!manual) {
                onSetError({ active: false, messageError: '' });
              }
            } else {
              if (!manual) {
                onSetError({
                  active: true,
                  messageError: get(response, 'message'),
                });
              }
            }
          })
          .then(() => {
            onSetLoadingService(false);
          })
          .then(() => {
            const { status } = responsePlaid;
            if (!manual) {
              handleClose();
            }
            if (status === 'verify' && !manual) {
              history.push(`/create-account/error`, {
                ...responsePlaid,
                ...{ plaidResponse },
              });
            }
            if (navigateAccounts && manual) {
              history.push(`/bank-accounts`);
            }
          });
      }, 8000);
    } else {
      onSetLoadingService(false);
      if (!manual) {
        handleClose();
        onSetErrorClient({
          active: true,
          messageError: get(responsePlaid, 'message'),
        });
      }
    }
  });
};

export default SelectedBankAccount;
