import React, { useState } from 'react'
import FileUpload from './FileUpload';
import { validateFile } from '../../utils/validations';

export default function InputFile({ value, onChange, selected }) {
  const [invalidFormat, setInvalidFormat] = useState(false);
  const onDropFiles = files => {
    if (validateFile(files[0].type)) {
      setInvalidFormat(false);
      onChange(files[0])
    } else {
      setInvalidFormat(true);
    }
  }
  return <FileUpload value={value} selected={selected} onDropFiles={onDropFiles} invalidFormat={invalidFormat} />
}