import request from '../../utils/request';

export default function usePlaidTokenForMicroDepositsStart() {
  const PlaidTokenForMicroDepositsStart = () => {
    return request.post(`/account/plaid/link-token`, {
      user: {
        client_user_id: '123456789',
      },
      options: {
        products: ['auth'],
        clientName: 'paymints.io',
      },
    });
  };
  return {
    PlaidTokenForMicroDepositsStart,
  };
}
