import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const CREATE_TRANSACTION_QUERY = gql`
  mutation CreateTransfer($transfer: TransferCreateInput!) {
    createTransfer(data: $transfer) {
      id
    }
  }
`;

export function useCreateTransfer() {
  const [createTransfer, { loading, error, data }] = useMutation(
    CREATE_TRANSACTION_QUERY
  );
  return {
    createTransfer,
    loading,
    error,
    tenant: data ? data.createTransfer : undefined,
  };
}

const UPDATE_TRANSACTION_QUERY = gql`
  mutation UpdateTransfer($id: ID!, $transfer: TransferUpdateInput!) {
    updateTransfer(id: $id, data: $transfer) {
      id
    }
  }
`;

export function useSaveTransfer() {
  const [saveTransfer, { loading, error, data }] = useMutation(
    UPDATE_TRANSACTION_QUERY
  );
  return {
    saveTransfer,
    loading,
    error,
    transfer: data ? data.Transfer : undefined,
  };
}

const GET_TRANSACTION_QUERY = gql`
  query Transfer($id: ID!) {
    Transfer(where: { id: $id }) {
      id
      firstName
      lastName
      email
      mobilePhone
      propertyAddress1
      propertyAddress2
      propertyCity
      propertyState
      propertyZipcode
      purpose
      status
      amount
      fee
      expectedClosingDate
      notes
      sellerName
      transferType {
        id
        name
      }
      otherIndividuals
      destBankAccount {
        id
      }
      sourceBankAccount {
        id
        bankName
        description
        accountNumber
        accountLastFourDigits
        bankDisplayName
        active
        client {
          id
        }
        updatedAt
        createdAt
      }
      office {
        id
        title
        city
        state
      }
      realtor {
        id
        firstName
        lastName
        id
        email
      }
    }
  }
`;

export function useTransfer(id) {
  const { loading, error, refetch, data } = useQuery(GET_TRANSACTION_QUERY, {
    variables: { id },
  });
  return {
    loadingSearchTransfer: loading,
    error,
    refetchTransfer: refetch,
    transfer: data ? data.Transfer : undefined,
  };
}

const GET_TRANSACTIONS_QUERY = gql`
  query allTransfers($where: TransferWhereInput!) {
    allTransfers(where: $where) {
      id
      firstName
      middleName
      lastName
      otherIndividuals
      email
      mobilePhone
      propertyAddress1
      propertyAddress2
      propertyCity
      propertyState
      propertyZipcode
      amount
      purpose
      expectedClosingDate
      sellerName
      notes
      emailMessage
      status
      active
      disbursement
      sourceBankAccount {
        id
        bankName
        description
        accountNumber
        accountLastFourDigits
        bankDisplayName
        active
        client {
          id
        }
        institutionCode
        updatedAt
        createdAt
      }
      destBankAccount {
        id
        bankName
        description
        accountNumber
        institutionCode
      }
      office {
        id
        title
      }
      realtor {
        firstName
        lastName
        id
        email
      }
      updatedAt
      createdAt
    }
  }
`;

export function useAllTransfers(transferID) {
  const id = isNil(transferID) || isEmpty(transferID) ? null : transferID;
  const { loading, error, data, refetch } = useQuery(GET_TRANSACTIONS_QUERY, {
    variables: { where: { client: { id } } },
  });
  return {
    loadingSearchTransfer: loading,
    error,
    refetchTransfers: refetch,
    transfers: data ? data.allTransfers : undefined,
  };
}
