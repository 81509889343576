import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { PaymintsButton, BUTTON_TYPE } from 'paymints-address-autocomplete-field';
import './contentModal.css';

const small = (props) => (
    props.small ? ' content-modal--small' : ''
)

const progress = (props) => (
    props.progress ? ' content-modal--progress' : ''
)

const ContentModal = (props) => (
    <Modal
        animation
        autoFocus
        backdrop
        enforceFocus
        show={props.isVisible}
        onExit={props.onClose}
        centered
        onHide={props.hide ? props.onClose : null}
        className={`content-modal${small(props)}${progress(props)} ${props.className}`}
    >
        <Modal.Header closeButton={!props.hideCloseButton}>
            <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="content-modal__body">
            <div className="content-modal__content-data">
                {props.content}
            </div>
            <Modal.Footer>
                {!props.withoutButtons ? 
                    props.isCancelButton ?
                        (
                            <PaymintsButton type={BUTTON_TYPE.LIGHT_BLUE} className="content-modal__button" onClick={props.onSecondaryButton}>
                                {props.secondaryButtonText}
                            </PaymintsButton>
                        ) :
                        <PaymintsButton type={BUTTON_TYPE.INVERSE_BLUE} className="content-modal__button" onClick={props.onPrimaryButton ? props.onPrimaryButton : props.onClose}>
                            {props.primaryButtonText}
                        </PaymintsButton>
                    : null
                }
            </Modal.Footer>
        </Modal.Body>
    </Modal>
);

ContentModal.propTypes = {
    content: PropTypes.any,
    isVisible: PropTypes.bool,
    onSecondaryButton: PropTypes.func,
    secondaryButtonText: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
    isCancelButton: PropTypes.bool,
    progress: PropTypes.bool,
    title: PropTypes.string,
    primaryButtonText: PropTypes.string,
    onPrimaryButton: PropTypes.func,
    onClose: PropTypes.func,
    withoutButtons: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    hide: PropTypes.bool,
    centered: PropTypes.bool,
};

ContentModal.defaultProps = {
    secondaryButtonText: 'Close',
    small: false,
    progress: false,
    className: '',
    title: '',
    primaryButtonText: 'Close',
    isCancelButton: false,
    withoutButtons: false,
    hideCloseButton: false,
    hide: false,
};

export default ContentModal;
