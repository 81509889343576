import { startOfToday, differenceInYears } from 'date-fns';

// PAYAPP-900 ("The receipt should only be generated on completion of the transfer")
export const shouldHideDocuments = status => {
  return ['completed'].indexOf(status.toLowerCase()) < 0;
};

export const validAge = value => {
  if (value) {
    const DOB = new Date(value);
    const today = startOfToday();
    return differenceInYears(today, DOB) >= 18;
  }
};

export const REGEXP_ONLY_4_CHAR = /^.{4}$/;

export const validSSN = ssn => {
  if (ssn) {
    const ssnWithoutDash = ssn.replace(/-/g, '');
    return REGEXP_ONLY_4_CHAR.test(ssnWithoutDash);
  }
};
export const validateAccountinfo = (client, account) => {
  return client === account;
};

export const validAmount = (value, balance, fee) => {
  return value <= balance - fee;
};

export const validateFile = name =>
  name.toLowerCase() === 'image/png' ||
  name.toLowerCase() === 'image/jpeg' ||
  name.toLowerCase() === 'image/jpg';
