/*
 * src/components/TransferHistory/OutboundTransfer.jsx
 * Description: This is a client disbursement
 */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import format from 'dateformat';
import {
  PaymintsNumberFormat,
  PaymintsHarveyBall,
  BALL_TYPE,
} from 'paymints-address-autocomplete-field';

import FormatAddress from '../FormatAddress/FormatAddress';
import { formatFromDisplay, formatToDisplay } from '../../utils/util';

import TransferDocuments from './TransferDocuments';
import TransferEditTooltip from './TransferEditTooltip';

import './refactor.css';

/**
 * A client -> tenant transfer
 */
const OutboundTransfer = ({ transfer, clientDocuments }) => {
  const dateFormat1 = 'mm/dd/yyyy';
  const dateFormat2 = 'hh:MM TT';

  /**
   * Determine if a transfer has valid documentation
   * @returns
   */
  const transferHasDocumentation = () => {
    if (clientDocuments && clientDocuments.length > 0) {
      return (
        clientDocuments.filter(f => f?.transfer?.id === transfer.id).length > 0
      );
    }
    return false;
  };

  /**
   * @function
   * @param {*} option
   * @returns
   */
  const getStatusType = option => {
    switch (option.toLowerCase().trim()) {
      case 'draft':
        return BALL_TYPE.INACTIVE;
      case 'pending':
        return BALL_TYPE.PENDING;
      case 'submitted':
        return BALL_TYPE.ACTIVE;
      case 'sent':
        return BALL_TYPE.SENT;
      case 'viewed':
        return BALL_TYPE.VIEWED;
      case 'completed':
        return BALL_TYPE.COMPLETED;
      case 'cancelled':
        return BALL_TYPE.CANCELLED;
      case 'error':
        return BALL_TYPE.ERROR;
      default:
        return BALL_TYPE.INACTIVE;
    }
  };

  return (
    <div className="transferWrapper">
      <div className="topWrapper">
        <Row>
          <Col className="columnHeading">Date/Time</Col>
          <Col className="columnHeading">From</Col>
          <Col className="columnHeading">To</Col>
          <Col className="columnHeading">Amount</Col>
          <Col className="columnHeading">Fee</Col>
          <Col className="columnHeading">Status</Col>
        </Row>
        <Row>
          <Col className="columnValue">
            <div
              dangerouslySetInnerHTML={{
                __html: `${format(
                  transfer?.createdAt || '',
                  dateFormat1
                )}<br/>${format(transfer?.createdAt || '', dateFormat2)}`,
              }}
            />
          </Col>
          <Col className="columnValue">
            <div
              dangerouslySetInnerHTML={{
                __html: formatFromDisplay(transfer),
              }}
            />
          </Col>
          <Col className="columnValue">
            <div
              dangerouslySetInnerHTML={{
                __html: formatToDisplay(transfer),
              }}
            />
          </Col>
          <Col className="columnValue">
            (-
            <PaymintsNumberFormat
              className="text-dark"
              number={transfer?.amount}
              decimals={2}
              format="currency"
            />
            )
          </Col>
          <Col className="columnValue">
            <div style={{ display: transfer?.fee ? 'block' : 'none' }}>
              (-
              <PaymintsNumberFormat
                className="text-dark"
                number={transfer?.fee}
                decimals={2}
                format="currency"
              />
              )
            </div>
            <div style={{ display: transfer?.fee ? 'none' : 'block' }}>--</div>
          </Col>
          <Col className="columnValue initCaps">
            <PaymintsHarveyBall type={getStatusType(transfer?.status)}>
              {transfer?.status === 'Requested' ||
              transfer?.status === 'viewed' ? (
                <TransferEditTooltip
                  transferId={transfer?.id}
                  status={transfer?.status}
                />
              ) : (
                transfer?.status || ''
              )}
            </PaymintsHarveyBall>
          </Col>
        </Row>
      </div>

      {/* related property / actions / documents */}
      <div className="bottomWrapper">
        <Row>
          <Col xs={6} className="columnHeading">
            Property Address Related To The Transfer
          </Col>
          <Col
            xs={6}
            className="columnHeading"
            style={{
              display: transfer?.status === 'Requested' ? 'block' : 'none',
            }}
          >
            Actions
          </Col>
          <Col
            xs={6}
            className="columnHeading"
            style={{ display: transferHasDocumentation() ? 'block' : 'none' }}
          >
            Documents
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormatAddress
              streetNumberName={transfer?.propertyAddress1 || ''}
              apartmentSuiteNumber={transfer?.propertyAddress2 || ''}
              city={transfer?.propertyCity || ''}
              state={transfer?.propertyState || ''}
              zipcode={transfer?.propertyZipcode || ''}
            />
          </Col>
          <Col
            xs={6}
            style={{
              display: transfer?.status === 'sent' ? 'block' : 'none',
            }}
          >
            <div>
              <Link
                id="sent"
                to={`/create-account/step-select-account?go_to_transfer=${transfer?.id}?disbursement=select-bank`}
              >
                <button className="accept-button">ACCEPT PAYMENT</button>
              </Link>
            </div>
          </Col>

          <Col
            xs={6}
            style={{
              display: transfer?.status === 'Requested' ? 'block' : 'none',
            }}
          >
            <div>
              <Link
                id="sent"
                to={`/create-account/step-select-account?go_to_transfer=${transfer?.id}`}
              >
                <button
                  className="accept-button"
                  style={{ alignItems: 'center' }}
                >
                  SEND FUNDS
                </button>
              </Link>
              <div
                style={{ color: 'gray', fontSize: '13px', padding: '5px 0px' }}
              >{`Requested by ${transfer?.agent?.firstName} ${transfer?.agent?.lastName} (${transfer?.agent?.email})`}</div>
            </div>
          </Col>
          <Col
            xs={6}
            style={{ display: transferHasDocumentation() ? 'block' : 'none' }}
          >
            <TransferDocuments
              documentData={clientDocuments}
              transferId={transfer?.id}
              status={transfer?.status}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={6}
            className="columnHeading"
            style={{
              display: transfer?.realtor ? 'block' : 'none',
            }}
          >
            Real Estate Agent
          </Col>
        </Row>
        <Row>
          <div
            style={{
              display: transfer?.realtor ? 'block' : 'none',
              padding: '5px 15px',
            }}
          >{`${transfer?.realtor?.firstName} ${transfer?.realtor?.lastName} (${transfer?.realtor?.email})`}</div>
        </Row>
      </div>
    </div>
  );
};

export default OutboundTransfer;
