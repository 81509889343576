import axios from 'axios';
import storeService from '../hooks/useStore';

const { getAuthToken } = storeService();
const authToken = getAuthToken('authToken') || process.env.REACT_APP_AUTH_TOKEN;

const request = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // https://api.paymints.dev
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-Token',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
  },
});

const domainRequest = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_URL,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-Token',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
  },
});

const graphqlAsyncRequest = query => {
  return axios({
    url: process.env.REACT_APP_GRAPH_QL_URL,
    method: 'POST',
    data: {
      query,
    },
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-Token',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export default request;
export { domainRequest, graphqlAsyncRequest };
