import * as actionType from "./TransferActionType";

const initialState = {
    startDwolla: {
        isFetchedStartDwolla: false,
        isFetchingStartDwolla: false,
        errorStartDwolla: false
    },
    transferDoc: {
        isFetchingTransferDoc: false,
        isFetchedTransferDoc: false,
        errorTransferDoc: false,
    },
    sendEmailReceipt: {
        isFetchingSendEmailReceipt: false,
        isFetchedSendEmailReceipt: false,
        errorSendEmailReceipt: false,
    }
};

const transferReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionType.START_DWOLLA_FETCH:
            return {
                ...state,
                startDwolla: {
                    isFetchingStartDwolla: true,
                    isFetchedStartDwolla: false,
                    errorStartDwolla: false
                }
            };
        case actionType.START_DWOLLA_SUCCESS:
            return {
                ...state,
                startDwolla: {
                    isFetchingStartDwolla: false,
                    isFetchedStartDwolla: true,
                    errorStartDwolla: false,
                }
            };
        case actionType.START_DWOLLA_ERROR:
            return {
                ...state,
                startDwolla: {
                    isFetchingStartDwolla: false,
                    isFetchedStartDwolla: false,
                    errorStartDwolla: true,
                }
            }
        case actionType.SAVE_TRANSFER_DOC_FETCH:
            return {
                ...state,
                transferDoc: {
                    isFetchingTransferDoc: true,
                    isFetchedTransferDoc: false,
                    errorTransferDoc: false
                }
            };
        case actionType.SAVE_TRANSFER_DOC_SUCCESS:
            return {
                ...state,
                transferDoc: {
                    isFetchingTransferDoc: false,
                    isFetchedTransferDoc: true,
                    errorTransferDoc: false,
                }
            };
        case actionType.SAVE_TRANSFER_DOC_ERROR:
            return {
                ...state,
                transferDoc: {
                    isFetchingTransferDoc: false,
                    isFetchedTransferDoc: false,
                    errorTransferDoc: true,
                }
            };
        case actionType.SEND_EMAIL_RECEIPT_FETCH:
            return {
                ...state,
                sendEmailReceipt: {
                    isFetchingSendEmailReceipt: true,
                    isFetchedSendEmailReceipt: false,
                    errorSendEmailReceipt: false,
                }
            };
        case actionType.SEND_EMAIL_RECEIPT_SUCCESS:
            return {
                ...state,
                sendEmailReceipt: {
                    isFetchingSendEmailReceipt: false,
                    isFetchedSendEmailReceipt: true,
                    errorSendEmailReceipt: false,
                }
            };
        case actionType.SEND_EMAIL_RECEIPT_ERROR:
            return {
                ...state,
                sendEmailReceipt: {
                    isFetchingSendEmailReceipt: false,
                    isFetchedSendEmailReceipt: false,
                    errorSendEmailReceipt: true,
                }
            };
        default:
            return state;
    }
};

export default transferReducer;
