import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const GET_ALL_TENANTS_QUERY = gql`
  query allTenants($where: TenantWhereInput!) {
    allTenants(where: $where) {
      id
      name
      description
      identifier
      type
      address1
      address2
      city
      state
      zipcode
      supportEmail
      pocPhone
      routingType
      tenantGroup {
        id
        groupName
        logoURL
        groupTagline
        bannerColor
      }
    }
  }
`;

export function useAllTenants(tenantID) {
  const id = isNil(tenantID) || isEmpty(tenantID) ? null : tenantID;
  const { loading, error, data, refetch } = useQuery(GET_ALL_TENANTS_QUERY, {
    variables: { where: { identifier: id } },
  });

  return {
    loading,
    error,
    refetch,
    tenant: data ? data.allTenants[0] : undefined,
  };
}

const GET_BRANDINGS_FROM_TENANT_QUERY = gql`
  query AllBrandings($tenantId: ID!, $active: Boolean) {
    allBrandings(where: { tenant: { id: $tenantId }, active: $active }) {
      id
      description
      logoURL
      faviconImgURL
      backgroundImageURL
      header
      footer
      registerText
      registerButtonText
      fontFamily
      fontSize
      fontColor
      fontWeight
      backgroundColor
      linkColor
      buttonColor
      accentColor
      customCSS
      tenant {
        id
      }
    }
  }
`;
export function useAllBrandingsFromTenant(tenantId, active) {
  const { loading, error, refetch, data } = useQuery(
    GET_BRANDINGS_FROM_TENANT_QUERY,
    {
      variables: { tenantId, active },
    }
  );

  return {
    loadingBranding: loading,
    error,
    refetchBrandings: refetch,
    branding: data ? data.allBrandings[0] : undefined,
  };
}

const GET_DEFAULT_BRANDING_FROM_TENANT_QUERY = gql`
  query AllBrandings {
    allBrandings(where: { default: true }) {
      id
      description
      logoURL
      backgroundImageURL
      header
      footer
      registerText
      registerButtonText
      fontFamily
      fontSize
      fontColor
      fontWeight
      backgroundColor
      linkColor
      buttonColor
      accentColor
      customCSS
      tenant {
        id
      }
    }
  }
`;
export function useDefaultBrandingFromTenant() {
  const { loading, error, refetch, data } = useQuery(
    GET_DEFAULT_BRANDING_FROM_TENANT_QUERY
  );

  return {
    loadingDefaultBranding: loading,
    error,
    refetchDefaultBranding: refetch,
    defaultBranding: data ? data.allBrandings[0] : undefined,
  };
}

const GET_ESCROW_ACCOUNTS_FROM_TENANT_QUERY = gql`
  query ActiveEscrowAccounts($tenantId: ID) {
    allBankAccounts(
      where: { tenant: { id: $tenantId }, ownerType: "tenant", active: true }
    ) {
      id
      bankName
      bankDisplayName
      accountNumber
      accountLastFourDigits
      institutionCode
      balance
      balanceDateTime
      active
    }
  }
`;

export default function useEscrowAccountFromTenant(tenantId) {
  const { loading, error, refetch, data } = useQuery(
    GET_ESCROW_ACCOUNTS_FROM_TENANT_QUERY,
    {
      variables: { tenantId },
    }
  );
  return {
    loading,
    error,
    refetch,
    destAccount: data ? data.allBankAccounts[0] : undefined,
  };
}
