import React, { useState } from 'react';
import BoxContainer from '../BoxContainer/BoxContainer';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { useCreateClient } from '../../utils/service/service_user_api';
import { setItem, removeItem } from '../../utils/util';
import { withRouter, Link } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../CreateAccountWizard/updateAction';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  PaymintsButton,
  BUTTON_TYPE,
} from 'paymints-address-autocomplete-field';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import { updateAuth0User } from '../../reducers/account/AccountActions';
import PhoneField from '../PhoneField/PhoneField';
import Warning from '../../styles/assets/warning.png';
import Phone from '../../styles/assets/phone.png';
import linkIcon from '../../styles/assets/external-link-icon.svg';
import * as APIUtil from '../../utils/service/service_api';

import '../CreateAccountWizard/createAccount.css';


const Title = () => <div className="create-account__line-container"></div>;

const InfoCreateAccount = ({ introductoryText }) => {
  const organization = 'Paymints';
  return (
    <div className="create-account__line-container">
      <div className="create-account__title">
        {introductoryText
          ? introductoryText
          : `To create an account and transfer money to ${organization}, please confirm the following details. All fields are required.`}
      </div>
    </div>
  );
};

const FieldsPersonalInformation = ({
  client,
  register,
  errors,
  setValue,
  watch,
  control,
}) => {
  return (
    <BoxContainer withBorder internalBox>
      <div className="create-account__register-name">
        <div className="form-group create-account__name">
          <label>First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="create-account__input form-control"
            placeholder=" "
            autoComplete="no"
            ref={register({ required: true, maxLength: 50 })}
          />
          {errors.firstName && errors.firstName.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
          {errors.firstName && errors.firstName.type === 'maxLength' && (
            <ErrorMessageInput>Max length exceeded</ErrorMessageInput>
          )}
        </div>
        <div className="form-group create-account__name-middle">
          <label>Middle Name</label>
          <input
            type="text"
            id="middleName"
            name="middleName"
            className="create-account__input form-control"
            placeholder=" "
            autoComplete="no"
            ref={register}
          />
        </div>
        <div className="form-group create-account__name">
          <label>Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="create-account__input form-control"
            placeholder=" "
            autoComplete="no"
            ref={register({ required: true, maxLength: 50 })}
          />
          {errors.lastName && errors.lastName.type === 'required' && (
            <ErrorMessageInput>This is required</ErrorMessageInput>
          )}
          {errors.lastName && errors.lastName.type === 'maxLength' && (
            <ErrorMessageInput>Max length exceeded</ErrorMessageInput>
          )}
        </div>
      </div>
      <div className="create-account__warning-conatiner">
        <img src={Warning} className="create-account__warning-icon" alt="" />
        <div className="create-account__warning-text">
          Must match your driver's license or government-issued ID.
        </div>
      </div>
    </BoxContainer>
  );
};

const PersonalInformation = ({
  client,
  watch,
  register,
  errors,
  setValue,
  control,
}) => (
  <div className="create-account__information-container">
    <div className="create-account__title-information">
      Personal Information
    </div>
    <FieldsPersonalInformation
      client={client}
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
    />
  </div>
);

const FieldsContactInformation = ({ errors, control }) => (
  <BoxContainer withBorder internalBox>
    <div className="form-group">
      <div className="form-group create-account__name create-account__fullwidth">
        <div className="create-account__phone-section">
          <div className="create-account__phone-label">Mobile Phone</div>
          <div className="create-account__phone-text">
            We will send a security code to your mobile phone for account
            verification.
          </div>
        </div>
        <Controller
          as={PhoneField}
          control={control}
          name="phone_number"
          placeholder=" "
          className="create-account__input form-control create-account__width"
          rules={{ required: true }}
        />
        {errors.phone_number && errors.phone_number.type === 'required' && (
          <ErrorMessageInput>This is required</ErrorMessageInput>
        )}
      </div>
      <div className="create-account__warning-conatiner">
        <img src={Phone} className="create-account__warning-icon" alt="" />
        <div className="create-account__warning-text">
          Standard text messaging and provider rates may apply.
        </div>
      </div>
    </div>
  </BoxContainer>
);

const ContactInformation = ({ client, errors, control }) => {
  const email = get(client, 'email');
  return email ? null : (
    <div className="create-account__information-container">
      <div className="create-account__title-information">
        Contact Information
      </div>
      <FieldsContactInformation errors={errors} control={control} />
    </div>
  );
};

const FooterInformation = ({ setDisabled }) => (
  <div className="create-account__information-container-footer-register">
    <span>
      <input
        type="checkbox"
        className="form-group"
        onChange={e => setDisabled(!e.target.checked)}
      />{' '}
    </span>{' '}
    {'By clicking Create Account, you agree to paymints.io '}
    <a
      href="https://www.paymints.io/terms-of-use/"
      rel="noopener noreferrer"
      className="paymints__link"
    >
      Terms of Service{' '}
      <img src={linkIcon} className="create-account__link-icon" alt="" />{' '}
    </a>
    {' and '}
    <a
      href="https://www.paymints.io/privacy-policy/"
      rel="noopener noreferrer"
      className="paymints__link nowrap"
    >
      Privacy Policy{' '}
      <img src={linkIcon} className="create-account__link-icon" alt="" />
    </a>
    {' as well as our partner'} Dwolla's
    <a
      href="https://www.dwolla.com/legal/dwolla-account-terms-of-service/"
      rel="noopener noreferrer"
      className="paymints__link"
    >
      {' '}
      Terms of Service{' '}
      <img src={linkIcon} className="create-account__link-icon" alt="" />
    </a>{' '}
    and{' '}
    <a
      href="https://www.dwolla.com/legal/privacy/"
      rel="noopener noreferrer"
      className="paymints__link"
    >
      Privacy Policy.
      <img src={linkIcon} className="create-account__link-icon" alt="" />
    </a>
  </div>
);

const nextStep = history => {
  history.push(`/create-account/step-2${get(history, 'location.search')}`);
};

const FirstStep = props => {
  const { beforeContinue, onUpdateAuth0User, refetchNewClient } = props;
  const { register, handleSubmit, errors, watch, setValue, control } = useForm({
    defaultValues: props.client,
  });
  const { createClient } = useCreateClient();
  const { action } = useStateMachine(updateAction);
  const [error, onSetError] = useState({
    active: false,
    type: '',
    messageError: '',
  });
  const [disabled, setDisabled] = useState(true);
  const onSubmit = newUser => {
    removeItem('login_user');
    onSetError({ active: false, type: '', messageError: '' });
    const email = get(props.user, 'email')
      ? get(props.user, 'email')
      : `${newUser.phone_number}@paymints.io`;
    const tenantId = get(props, 'tenant.id');
    if (isEmpty(tenantId))
      onSetError({
        active: true,
        messageError: 'Tenant not found, please check url',
      });
    if (email && !isEmpty(tenantId)) {
      const { phone_number } = newUser;
      // Search for all clients with that email
      refetchNewClient({ where: { mobilePhone: '+1' + phone_number } }).then(
        response => {
          const client = get(response, 'data.allClients')[0];
          delete newUser.phone_number;
          if (!client) {
            let clientData = {};
            // Create a new client
            createClient({
              variables: {
                client: {
                  ...newUser,
                  email: '',
                  mobilePhone: '+1' + phone_number,
                  tenant: {
                    connect: {
                      id: tenantId,
                    },
                  },
                },
              },
            })
              .then(res => {
                clientData = res.data.createClient;
                beforeContinue();
              })
              .then(() => {
                // Create Auth0 client
                const userEmail = get(props.user, 'email');
                if (!userEmail) {
                  const auth0User = {
                    email: email,
                    phone_number: `+1${phone_number}`,
                    name: `${newUser.firstName} ${newUser.lastName}`,
                    connection: 'sms',
                  };
                  const auth0Payload = {
                    create_user: auth0User,
                    role: 'paymints_admin',
                    new_user_role: 'paymints_client',
                  };

                  onUpdateAuth0User(tenantId, auth0Payload)
                    .then(response => {
                      if (response === 'error') {
                        onSetError({
                          active: true,
                          messageError:
                            'Account exists for phone number.  Please login in.',
                        });
                      } else {
                        getCode(tenantId, clientData.id, clientData);
                      }
                    })
                    .then(() => {
                      action(newUser);
                    });
                } else {
                  action(newUser);
                  nextStep(props.history);
                }
              });
          } else {
            onSetError({ active: true, type: 'sms' });
            setItem('login_user', 'existing_user');
          }
        }
      );
    }
  };
  const getCode = (tenantId, clientid, client) => {
    APIUtil.getSMSCode(tenantId, clientid).then(
      () => {
        props.history.push('/create-account/account-verification', { client });
      },
      error => {
        console.log(error);
      }
    );
  };

  return (
    <BoxContainer withDoubleBorder withLogButton brandLogo>
      <Title />
      <InfoCreateAccount introductoryText={props.introductoryText} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation
          client={props.client}
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
          control={control}
        />
        <ContactInformation
          client={props.client}
          errors={errors}
          control={control}
        />
        <FooterInformation setDisabled={setDisabled} />
        <div className="create-account__footer-buttons center">
          <PaymintsButton
            htmlType="submit"
            className="create-account__button"
            type={BUTTON_TYPE.INVERSE_BLUE}
            disabled={disabled}
          >
            <div className="create-account__button-text">
              {props.registerButtonText
                ? props.registerButtonText === 'Register!'
                  ? 'Create Account'
                  : props.registerButtonText
                : 'Create Account'}
            </div>
          </PaymintsButton>
          {error.active && (
            <ErrorMessageInput>{error.messageError}</ErrorMessageInput>
          )}
          {error.active && error.type === 'sms' && (
            <ErrorMessageInput>
              Account exists for phone number. Please{' '}
              <Link to="/login">login</Link>
            </ErrorMessageInput>
          )}
          <div>
            {' '}
            Already registered? Click to{' '}
            <span
              className="create-account__paymints-link"
              onClick={() => props.history.push('/login')}
            >
              log in
            </span>{' '}
          </div>
        </div>
      </form>
    </BoxContainer>
  );
};

export default withRouter(
  connect(state => ({}), {
    onUpdateAuth0User: updateAuth0User,
  })(FirstStep)
);
