/**
 * @deprecated
 */
export const filterList = [
  {
    messageType: "transfer_request",
    value: "Transfer Request",
    isChecked: false,
  },
  {
    messageType: "transfer_receipt",
    value: "Transfer Receipt",
    isChecked: false,
  },
  { messageType: "transfer_fail", value: "Transfer Fail", isChecked: false },
  {
    messageType: "transfer_initiated",
    value: "Transfer Initiated",
    isChecked: false,
  },
];
export const messageKey = {
  transfer_request: "Transfer Request",
  transfer_receipt: "Transfer Receipt",
  transfer_fail: "Transfer Fail",
  transfer_initiated: "Transfer Initiated",
};

export const request = [
  { messageType: "transfer_request" },
  { messageType: "transfer_receipt" },
  { messageType: "transfer_fail" },
  { messageType: "transfer_initiated" },
];
