import axios from 'axios';
import APIDomain from '../apiSettings';

axios.defaults.baseURL = APIDomain;

export const plaidCreateClient = data =>
  axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: '/account/auth/create-client',
    data: data,
  }).then(response => {
    return response.data;
  });

export const plaidStartMicrodepoits = (data, tenantID) => {
  data.tenantId = tenantID;
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: '/account/microdeposit/start',
    data: data,
  }).then(response => {
    window.location.reload();
    return response.data;
  });
};

export const startDwolla = (data, config) => {
  let url = '/transfer/start';

  if (config?.type === 'disbursement') {
    url = '/transfer/disbursement/start';
  }

  /**
   * slight change in data payload
   * was:
   * {
   *  destBankAccountId: data.destBankAccountId,
   *  sourceBankAccountId: data.sourceBankAccountId,
   *  transferId: data.transferId,
   * }
   * now "sourceBankAccountId" needs to be just "bankAccountId" <= this is NOT correct... bankAccountId === client/vendor bank account
   * please read updated transfer service documentation here: https://github.com/lemonbrew/paymints-transfer-service
   * let me know if you have any questsions - doug
   */
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url,
    data: {
      bankAccountId: data.sourceBankAccountId,
      // bankAccountId: data.destBankAccountId,
      transferId: data.transferId,
    },
  });
};

export const getBankAccounts = clientId =>
  axios({
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: `/account/client-accounts/${clientId}`,
  }).then(response => {
    return response.data;
  });

export const disconnectBankAccount = clientId =>
  axios({
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: `/account`,
    data: clientId,
  }).then(response => {
    console.log(response.data);
  });
// .then(response => response.data)

export const uploadDocument = form =>
  axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: `/assets/private/`,
    data: form,
  }).then(response => response.data);

export const saveTransferDoc = (transferId, data) =>
  axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: `/account/document/${transferId}`,
    data: data,
  }).then(response => response.data);

/**
 *
 * @param {*} data {TRANSFER_GUID: string, emailRecipients: Array<String>, transfer.transferType.name: string}
 */

export const sendEmailReceipt = async data => {
  let type = data.transfer.transferType.name;
  let route;

  try {
    if (type === 'Client Disbursement') {
      route = `/message/email/disbursement-initiated-client`;
    } else if (type === 'Client Transfer') {
      route = `/message/email/transfer-initiated`;
    }
    let response = await axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      url: route,
      data: {
        transfer_GUID: data.TRANSFER_GUID,
        emailRecipient: data.additionalEmailRecipient,
        additionalEmailRecipient: true,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAuth0User = (tenantID, data) =>
  axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: `/users/${tenantID}`,
    data: data,
  })
    .then(response => response.data)
    .catch(() => ({ error: true }));

export const getSMSCode = (tenant_GUID, client_GUID) =>
  axios({
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: `/users/resend/${tenant_GUID}/${client_GUID}`,
  }).then(response => response.data);

export const validateSMSCode = (tenant_GUID, client_GUID, code) =>
  axios({
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    },
    url: `/users/${tenant_GUID}/${client_GUID}/${code}`,
  })
    .then(response => response.data)
    .catch(() => {
      return { error: true };
    });

export const saveSensitiveData = async (client_GUID, tenant_GUID, data) => {
  try {
    axios({
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      url: `/security/customer/${client_GUID}/${tenant_GUID}`,
      data: data,
    });
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

export const getSensitiveData = async (client_GUID, tenant_GUID) => {
  let response;
  try {
    response = await axios({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
      url: `/security/customer/${client_GUID}/${tenant_GUID}`,
    });
  } catch (error) {
    console.log(error);
  }

  return response?.data;
};
