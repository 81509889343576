import * as actionType from "./AccountActionTypes";

const initialState = {
    createClient: {
        client: {},
        isFetchedCreateClient: false,
        isFetchingCreateClient: false,
        errorCreateClient: false
    },
    bankAccounts: {
        bankAccounts: [],
        isFetchedBankAccounts: false,
        isFetchingBankAccounts: false,
        errorBankAccounts: false
    },
    deleteBankAccount: {
        isFetchedDeleteBankAccount: false,
        isFetchingDeleteBankAccount: false,
        errorDeleteBankAccount: false
    },
    updateAuth0: {
        updateAuth0: {},
        isFetchedUpdateAuth0: false,
        isFetchingUpdateAuth0: false,
        errorUpdateAuth0: false
    },
};

const accountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionType.PLAID_CREATE_CUSTOMER_FETCH:
            return {
                ...state,
                createClient: {
                    isFetchingCreateClient: true,
                    isFetchedCreateClient: false,
                    errorCreateClient: false
                }
            };
        case actionType.PLAID_CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                createClient: {
                    isFetchingCreateClient: false,
                    isFetchedCreateClient: true,
                    errorCreateClient: false,
                    client: payload.response,
                }
            };
        case actionType.PLAID_CREATE_CUSTOMER_ERROR:
            return {
                ...state,
                createClient: {
                    isFetchingCreateClient: false,
                    isFetchedCreateClient: false,
                    errorCreateClient: true,
                    client: payload.response,
                }
            };
        case actionType.GET_BANK_ACCOUNTS_FETCH:
            return {
                ...state,
                bankAccounts: {
                    isFetchingBankAccounts: true,
                    isFetchedBankAccounts: false,
                    errorBankAccounts: false
                }
            };
        case actionType.GET_BANK_ACCOUNTS_SUCCESS:
            return {
                ...state,
                bankAccounts: {
                    isFetchingBankAccounts: false,
                    isFetchedBankAccounts: true,
                    errorBankAccounts: false,
                    bankAccounts: payload.response,
                }
            };
        case actionType.GET_BANK_ACCOUNTS_ERROR:
            return {
                ...state,
                bankAccounts: {
                    isFetchingBankAccounts: false,
                    isFetchedBankAccounts: false,
                    errorBankAccounts: true,
                    bankAccounts: payload.response,
                }
            };
        case actionType.DELETE_BANK_ACCOUNT_FETCH:
            return {
                ...state,
                deleteBankAccount: {
                    isFetchingDeleteBankAccount: true,
                    isFetchedDeleteBankAccount: false,
                    errorDeleteBankAccount: false
                }
            };
        case actionType.DELETE_BANK_ACCOUNT_SUCCESS:
            return {
                ...state,
                deleteBankAccount: {
                    isFetchingDeleteBankAccount: false,
                    isFetchedDeleteBankAccount: true,
                    errorDeleteBankAccount: false,
                }
            };
        case actionType.DELETE_BANK_ACCOUNT_ERROR:
            return {
                ...state,
                deleteBankAccount: {
                    isFetchingDeleteBankAccount: false,
                    isFetchedDeleteBankAccount: false,
                    errorDeleteBankAccount: true,
                }
            };
        case actionType.UPDATE_AUTH0_FETCH:
            return {
                ...state,
                updateAuth0: {
                    updateAuth0: {},
                    isFetchedUpdateAuth0: false,
                    isFetchingUpdateAuth0: true,
                    errorUpdateAuth0: false
                },
            };
        case actionType.UPDATE_AUTH0_SUCCESS:
            return {
                ...state,
                updateAuth0: {
                    updateAuth0: payload.response,
                    isFetchedUpdateAuth0: true,
                    isFetchingUpdateAuth0: false,
                    errorUpdateAuth0: false
                },
            };
        default:
            return state;
    }
};

export default accountReducer;
