import { AES, enc } from "crypto-js";

export const encrypt = (value) => {
  let encrypted = AES.encrypt(
    value,
    process.env.REACT_APP_GLOBAL_ENCRYPT_KEY
  ).toString();

  return encrypted;
};

export const decrypt = (value) => {
  let decrypted = AES.decrypt(
    value,
    process.env.REACT_APP_GLOBAL_ENCRYPT_KEY
  ).toString(enc.Utf8);

  return decrypted;
};
