export default function phoneFormatter(mask, unformattedNumber) {
  const number = unformattedNumber?.replace(/-/g, '').replace('+1', '')
  let formattedNumber = ''

  for (let iMask = 0, iNumber = -1; iMask < mask?.length && iNumber < number?.length; iMask += 1) {
    formattedNumber +=
      mask.charAt(iMask) === 'X' ? number.charAt((iNumber += 1)) : mask.charAt(iMask)
  }

  return formattedNumber
}
