import React, { useEffect, useState } from 'react';
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import CreateAccount from '../CreateAccountWizard/CreateAccountWizard';
import CreateAccountForPartner from '../CreateAccountWizardForPartner/CreateAccountWizard';
import TransferHistory from '../TransferHistory/TransferHistory';
import BankAccountsHistory from '../BankAccountsHistory/BankAccountsHistory';
import Account from '../Account/Account';
import MenuNavBar from '../MenuNavBar/MenuNavBar';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useAuth0 } from '../../react-auth0-spa';
import Login from '../Login/Login';
import ErrorMessageInput from '../ErrorMessajeInput/ErrorMessageInput';
import { clearStore } from '../../utils/util';
import { getSensitiveData } from '../../utils/service/service_api';
import { useSensitiveData } from '../../contexts/sensitiveDataContext';
import MessageCenter from '../MessageCenter/MessageCenter';
import {
  getTargetedHeaderBranding,
  getTargetedFooterBranding,
} from '../../utils/tenantGroupBranding';

const HomePage = ({
  hasTransfer,
  user,
  loading,
  loadingSearch,
  loadingSearchTransfer,
  loadingBranding,
  brandLogo,
  brandName,
  introductoryText,
  registerButtonText,
  tenant,
  loadingTenant,
  hasInitiatedTransfer,
  saveTransfer,
  history,
  client,
  branding,
  defaultBranding,
  loadingDefaultBranding,
}) => {
  let content;
  const loadingServices =
    loading ||
    loadingSearch ||
    loadingSearchTransfer ||
    loadingBranding ||
    loadingTenant;
  const { logout } = useAuth0();
  const [error, onSetError] = useState({ active: false, messageError: '' });
  const { sensitiveData, setSensitiveData } = useSensitiveData();
  const location = useLocation();

  useEffect(
    () => {
      /**
       * https://lemonbrew.atlassian.net/browse/PAYAPP-981
       * here is where we decide between tenant group branding, tenant branding or default branding
       * Removing Tenant Group
      
      if (!loadingBranding && !loadingDefaultBranding && tenant?.tenantGroup) {
        document.getElementById('header').innerHTML = getTargetedHeaderBranding(
          tenant,
          branding,
          defaultBranding
        );
        document.getElementById('footer').innerHTML = getTargetedFooterBranding(
          tenant,
          branding,
          defaultBranding
        );
      }
      */
      if (!loadingBranding && !loadingDefaultBranding) {
        const header = document.getElementById('header');
        const footer = document.getElementById('footer');
        header.innerHTML = branding
          ? get(branding, 'header')
          : get(defaultBranding, 'header');
        footer.innerHTML = branding
          ? get(branding, 'footer')
          : get(defaultBranding, 'footer');
      }

      if ((!user && location.pathname !== '/login' && !location.pathname.includes('/partner'))) {
        history.push('/create-account');
        return;
      }

      const clientId = get(client, 'id');
      if (user && !clientId && !loadingSearch) {
        if (isEmpty(clientId)) {
          onSetError({
            active: true,
            messageError: 'Client not found, please login',
          });
          setTimeout(() => {
            logout();
            clearStore();
          }, 2000);
        }
      }

      const clientSsn = get(sensitiveData, 'SSN');
      const clientDob = get(sensitiveData, 'DOB');
      const transferId = get(hasInitiatedTransfer, 'id');

      if ((!clientSsn || !clientDob) && setSensitiveData) {
        getSensitiveData(clientId, get(tenant, 'id'))
          .then(res => {
            if (res?.data) {
              const { SSN, DOB } = res.data;
              setSensitiveData({
                SSN,
                DOB,
              });
            } else {
            }
          })
          .catch(error => console.log(error));
      }

      if (hasInitiatedTransfer) {
        saveTransfer({
          variables: {
            id: transferId,
            transfer: {
              status: 'viewed',
            },
          },
        });

        if (clientSsn && clientDob) {
          history.push(
            `/create-account/step-select-account?go_to_transfer=${transferId}`
          );
        } else {
          history.push(`/create-account/step-2?go_to_transfer=${transferId}`);
        }
      }
    },
    /* eslint-disable */ [
      client,
      hasInitiatedTransfer,
      sensitiveData,
      history,
      saveTransfer,
      user,
      branding,
      defaultBranding,
      loadingBranding,
      loadingDefaultBranding,
      logout,
      loadingSearch,
    ]
  );

  if (loadingServices) {
    content = <SpinnerLoader />;
  }

  if (error.active) {
    content = <ErrorMessageInput>{error.messageError}</ErrorMessageInput>;
  }

  const mobile = window.screen.width < 1200;
  const isHide = !user;
  const hideLogo = false;

  const isNewUser = !user?.email.length > 0;

  return (
    <MenuNavBar
      brandLogo={brandLogo}
      brandName={brandName}
      hide={isHide}
      mobile={mobile}
    >
      <div className="App">
        <div className="App-container">
          {hideLogo ? <NavBar logo={brandLogo} /> : ''}
          <div className="App__container">
            {loadingServices || error.active ? (
              content
            ) : (
              <Switch>
                <Route exact path="/account">
                  <Account />
                </Route>
                <Route exact path="/transfer-history">
                  <TransferHistory tenant={tenant} />
                </Route>
                <Route exact path="/message-center">
                  <MessageCenter />
                </Route>
                <Route exact path="/bank-accounts">
                  <BankAccountsHistory client={client} tenant={tenant} />
                </Route>
                <Route path="/create-account">
                  <CreateAccount
                    introductoryText={introductoryText}
                    registerButtonText={registerButtonText}
                    tenant={tenant}
                    brandLogo={brandLogo}
                  />
                </Route>
                <Route exact path="/login">
                  <Login tenant={tenant} />
                </Route>
                <Route path="/partner/">
                <CreateAccountForPartner
                    introductoryText={introductoryText}
                    registerButtonText={registerButtonText}
                    tenant={tenant}
                    brandLogo={brandLogo}
                  />
                </Route>
                <Route exact path="/">
                  {user ? (
                    isNewUser ? (
                      <Redirect to="/account" />
                    ) : (
                      <Redirect to="/transfer-history" />
                    )
                  ) : (
                    <Redirect to="/create-account" />
                  )}
                </Route>
              </Switch>
            )}
          </div>
        </div>
      </div>
    </MenuNavBar>
  );
};

export default withRouter(HomePage);
