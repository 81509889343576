import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { getSensitiveData } from '../utils/service/service_api';

const SensitiveDataContext = createContext();

export const SensitiveDataProvider = ({ clientID, tenantID, children }) => {
  const [sensitiveData, setSensitiveData] = useState();
  const [loading, setLoading] = useState(false);
  const res = getSensitiveData(clientID, tenantID);
  const getData = useCallback(
    async () => {
      setLoading(true);
      try {
        const res = await getSensitiveData(clientID, tenantID);
        if (res) {
          setSensitiveData(res.data);
        }
        setLoading(false);
      } catch (error) {
        getData();
        setLoading(false);
      }
    },
    /* eslint-disable */ [clientID, tenantID]
  );

  const refetch = () => getData();

  useEffect(() => {
    if (clientID && tenantID) getData();
  }, [clientID, tenantID]);

  return (
    <SensitiveDataContext.Provider
      value={{ sensitiveData, getData, loading, refetch }}
    >
      {children}
    </SensitiveDataContext.Provider>
  );
};

export const useSensitiveData = () => {
  const context = useContext(SensitiveDataContext);

  return context;
};
