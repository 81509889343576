import React from 'react';
import get from 'lodash/get';
import FormatAddress from '../../FormatAddress/FormatAddress';
import { PaymintsNumberFormat } from 'paymints-address-autocomplete-field';

function TransferSuccess({
  transfer,
  bankAccount,
  destAccount,
  bankAccountLogo,
  escrowAccountLogo,
  disbursement,
  tenant,
}) {
  const tenantName = get(tenant, 'name');
  const address1 = get(tenant, 'address1');
  const address2 = get(tenant, 'address2');
  const city = get(tenant, 'city');
  const state = get(tenant, 'state');
  const zipcode = get(tenant, 'zipcode');
  const office = get(transfer, 'office.title');
  return (
    <div className="p-4 rounded bg-white mb-2 border">
      <div className="mb-4">
        <h6 className="create-account__item-title">Subject Property:</h6>
        <FormatAddress
          streetNumberName={get(transfer, 'propertyAddress1')}
          apartmentSuiteNumber={get(transfer, 'propertyAddress2')}
          city={get(transfer, 'propertyCity')}
          state={get(transfer, 'propertyState')}
          zipcode={get(transfer, 'propertyZipcode')}
        />
      </div>

      <div className="mb-4">
        <h6 className="create-account__item-title">Transfer Amount:</h6>
        <div className="create-account__item-amount">
          <PaymintsNumberFormat
            number={(+transfer?.amount || 0) + (+transfer?.fee || 0)}
            decimals={2}
            format="currency"
          />
        </div>
      </div>

      <div className="mb-4">
        <h6 className="create-account__item-title"> Transfer From Account:</h6>
        <div className="create-account__bank-information">
          <div className="create-account__bank-information-logo">
            {disbursement ? null : (
              <img
                className="create-account__bank-information-icon-logo"
                src={bankAccountLogo}
                alt=""
              />
            )}
          </div>
          <div className="create-account__bank-information-account">
            {disbursement ? (
              <div className="create-account__bank-information-account">
                {tenantName}
                <br />
                {address1}
                {address2 !== null ? (
                  <>
                    <br />
                    {address2}
                  </>
                ) : null}
                <br />
                {city}, {state} {zipcode}
                <br />
                {office}
              </div>
            ) : (
              get(bankAccount, 'bankName')
            )}
            <br />
            {disbursement
              ? null
              : `X${get(bankAccount, 'accountLastFourDigits')}`}
          </div>
        </div>
      </div>

      <div className="mb-4">
        <h6 className="create-account__item-title"> Transfer To Account:</h6>
        <div className="create-account__bank-information">
          <div className="create-account__bank-information-logo">
            {disbursement ? (
              <img
                className="create-account__bank-information-icon-logo"
                src={bankAccountLogo}
                alt=""
              />
            ) : null}
          </div>
          <div className="create-account__bank-information-account">
            {disbursement ? (
              `${get(bankAccount, 'bankName')}`
            ) : (
              <div className="create-account__bank-information-account">
                {tenantName}
                <br />
                {address1}
                {address2 !== null ? (
                  <>
                    <br />
                    {address2}
                  </>
                ) : null}
                <br />
                {city}, {state} {zipcode}
                <br />
                {office}
              </div>
            )}
            <br />
            {disbursement
              ? `X${get(bankAccount, 'accountLastFourDigits')}`
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function MobileTransferSuccess({
  transfer,
  bankAccount,
  destAccount,
  bankAccountLogo,
  escrowAccountLogo,
  tenant,
  disbursement,
}) {
  return (
    <div className="d-block d-lg-none d-xl-none">
      <TransferSuccess
        key={get(transfer, 'id')}
        transfer={transfer}
        bankAccount={bankAccount}
        destAccount={destAccount}
        bankAccountLogo={bankAccountLogo}
        escrowAccountLogo={escrowAccountLogo}
        tenant={tenant}
        disbursement={disbursement}
      />
    </div>
  );
}
//Commented out due to deployment issue
// import React from 'react';
// import get from 'lodash/get';
// import FormatAddress from '../../FormatAddress/FormatAddress';
// import { PaymintsNumberFormat } from 'paymints-address-autocomplete-field';

// function TransferSuccess({
//   transfer,
//   bankAccount,
//   bankEscrowAccount,
//   bankAccountLogo,
//   escrowAccountLogo,
// }) {
//   return (
//     <div className="p-4 rounded bg-white mb-2 border">
//       <div className="mb-4">
//         <h6 className="create-account__item-title">Subject Property:</h6>
//         <FormatAddress
//           streetNumberName={get(transfer, 'propertyAddress1')}
//           apartmentSuiteNumber={get(transfer, 'propertyAddress2')}
//           city={get(transfer, 'propertyCity')}
//           state={get(transfer, 'propertyState')}
//           zipcode={get(transfer, 'propertyZipcode')}
//         />
//       </div>

//       <div className="mb-4">
//         <h6 className="create-account__item-title">Transfer Amount:</h6>
//         <div className="create-account__item-amount">
//           <PaymintsNumberFormat
//             number={(+transfer?.amount || 0) + (+transfer?.fee || 0)}
//             decimals={2}
//             format="currency"
//           />
//         </div>
//       </div>

//       <div className="mb-4">
//         <h6 className="create-account__item-title"> Transfer From Account:</h6>
//         <div className="create-account__bank-information">
//           <div className="create-account__bank-information-logo">
//             <img
//               className="create-account__bank-information-icon-logo"
//               src={bankAccountLogo}
//               alt=""
//             />
//           </div>
//           <div className="create-account__bank-information-account">
//             {!disbursement
//               ? get(bankAccount, 'bankName')
//               : get(destBankAccount, 'bankName')}
//             <br />
//             {!disbursement
//               ? get(bankAccount, 'accountLastFourDigits')
//               : get(destBankAccount, 'accountLastFourDigits')}
//           </div>
//         </div>
//       </div>

//       <div className="mb-4">
//         <h6 className="create-account__item-title"> Transfer To Account:</h6>
//         <div className="create-account__bank-information">
//           <div className="create-account__bank-information-logo">
//             <img
//               className="create-account__bank-information-icon-logo"
//               src={escrowAccountLogo}
//               alt=""
//             />
//           </div>
//           <div className="create-account__bank-information-account">
//             {!disbursement
//               ? get(destBankAccount, 'bankName')
//               : get(bankAccount, 'bankName')}
//             <br />
//             {!disbursement
//               ? get(destBankAccount, 'accountLastFourDigits')
//               : get(bankAccount, 'accountLastFourDigits')}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default function MobileTransferSuccess({
//   transfer,
//   bankAccount,
//   bankEscrowAccount,
//   bankAccountLogo,
//   escrowAccountLogo,
// }) {
//   return (
//     <div className="d-block d-lg-none d-xl-none">
//       <TransferSuccess
//         key={get(transfer, 'id')}
//         transfer={transfer}
//         bankAccount={bankAccount}
//         bankEscrowAccount={bankEscrowAccount}
//         bankAccountLogo={bankAccountLogo}
//         escrowAccountLogo={escrowAccountLogo}
//       />
//     </div>
//   );
// }
