/*
 * src/components/TransferHistory/InboundTransfer.jsx
 * Description: This is a client disbursement
 */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import format from 'dateformat';
import {
  PaymintsNumberFormat,
  PaymintsHarveyBall,
  BALL_TYPE,
} from 'paymints-address-autocomplete-field';

import FormatAddress from '../FormatAddress/FormatAddress';
import { formatFromDisplay, formatToDisplay } from '../../utils/util';

import TransferDocuments from './TransferDocuments';

import './refactor.css';

/**
 * A disbursement
 */
const InboundTransfer = ({ transfer, clientDocuments }) => {
  const dateFormat1 = 'mm/dd/yyyy';
  const dateFormat2 = 'hh:MM TT';

  /**
   * Determine if a transfer has valid documentation
   * @returns
   */
  const transferHasDocumentation = () => {
    if (clientDocuments && clientDocuments.length > 0) {
      return (
        clientDocuments.filter(f => f?.transfer?.id === transfer.id).length > 0
      );
    }
    return false;
  };

  /**
   * @function
   * @param {*} option
   * @returns
   */
  const getStatusType = option => {
    switch (option.toLowerCase().trim()) {
      case 'draft':
        return BALL_TYPE.INACTIVE;
      case 'pending':
        return BALL_TYPE.PENDING;
      case 'submitted':
        return BALL_TYPE.ACTIVE;
      case 'sent':
        return BALL_TYPE.SENT;
      case 'viewed':
        return BALL_TYPE.VIEWED;
      case 'completed':
        return BALL_TYPE.COMPLETED;
      case 'cancelled':
        return BALL_TYPE.CANCELLED;
      case 'error':
        return BALL_TYPE.ERROR;
      default:
        return BALL_TYPE.INACTIVE;
    }
  };

  return (
    <div className="transferWrapper">
      <div className="topWrapper">
        <Row>
          <Col className="columnHeading">Date/Time</Col>
          <Col className="columnHeading">From</Col>
          <Col className="columnHeading">To</Col>
          <Col className="columnHeading">Amount</Col>
          <Col />
          <Col className="columnHeading">Status</Col>
        </Row>
        <Row>
          <Col className="columnValue">
            <div
              dangerouslySetInnerHTML={{
                __html: `${format(
                  transfer?.createdAt || '',
                  dateFormat1
                )}<br/>${format(transfer?.createdAt || '', dateFormat2)}`,
              }}
            />
          </Col>
          <Col className="columnValue">
            <div
              dangerouslySetInnerHTML={{
                __html: formatFromDisplay(transfer),
              }}
            />
          </Col>
          <Col className="columnValue">
            <div
              dangerouslySetInnerHTML={{
                __html: formatToDisplay(transfer),
              }}
            />
          </Col>
          <Col className="columnValue">
            <PaymintsNumberFormat
              className="text-success"
              number={transfer?.amount}
              decimals={2}
              format="currency"
            />
          </Col>
          <Col />
          <Col className="columnValue initCaps">
            <PaymintsHarveyBall type={getStatusType(transfer?.status)}>
              {transfer?.status}
            </PaymintsHarveyBall>
          </Col>
        </Row>
      </div>

      {/* related property / actions / documents */}
      <div className="bottomWrapper">
        <Row>
          <Col className="columnHeading">
            Property Address Related To The Transfer
          </Col>
          <Col
            className="columnHeading"
            style={{ display: transfer?.status === 'sent' ? 'block' : 'none' }}
          >
            Actions
          </Col>
          <Col
            className="columnHeading"
            style={{ display: transferHasDocumentation() ? 'block' : 'none' }}
          >
            Documents
          </Col>
        </Row>
        <Row>
          <Col>
            <FormatAddress
              streetNumberName={transfer?.propertyAddress1 || ''}
              apartmentSuiteNumber={transfer?.propertyAddress2 || ''}
              city={transfer?.propertyCity || ''}
              state={transfer?.propertyState || ''}
              zipcode={transfer?.propertyZipcode || ''}
            />
          </Col>
          <Col
            style={{ display: transfer?.status === 'sent' ? 'block' : 'none' }}
          >
            <div>
              <Link
                id="sent"
                to={`/create-account/step-select-account?go_to_transfer=${transfer?.id}?disbursement=select-bank`}
              >
                <button className="accept-button">ACCEPT PAYMENT</button>
              </Link>
            </div>
          </Col>
          <Col
            style={{ display: transferHasDocumentation() ? 'block' : 'none' }}
          >
            <TransferDocuments
              documentData={clientDocuments}
              transferId={transfer?.id}
              status={transfer?.status}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InboundTransfer;
